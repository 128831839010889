import React from 'react';
import {
    Datagrid,
    ListController,
    Pagination,
    TextField,
    withDataProvider,
} from 'react-admin';
import {
    createStyles,
    withStyles,
    WithStyles
} from '@material-ui/core/styles';
import PlaylistCreateButton from './PlaylistCreateButton';
import SearchPanel from '../searchPanel/SearchPanel';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { ROLES } from '../../utils/config';
import { Button } from '@material-ui/core';
import { Restore } from '@material-ui/icons';


const RestoreButton = withDataProvider((props) => {
    const {dataProvider} = props;
    const handleRestore = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>, id: string) => {
        e.stopPropagation();
        dataProvider(
            'RESTORE_PLAYLIST',
            'playlist',
            {id: id},
            {
                onSuccess: {
                    notification: {
                        body: 'resources.playlist.notification.playlist_restored'
                    },
                    refresh: true
                }
            }
        )
            .then(response => {
            })
            .catch(error => console.error(error));
    };
    return props.record.deletedAt ?
        <Button
            onClick={(e) => handleRestore(e, props.record.id)}
            color="default"
        >
            <Restore/>
      Restore
        </Button>
        :
        null;
});


const styles = () =>
    createStyles({
    });


interface Props extends WithStyles<typeof styles> {
  permissions;
}

export default withStyles(styles)(withDataProvider((props: Props) => {
    const dispatch = useDispatch();
    const {
        permissions,
    } = props;
    return (
        <>
            <ListController {...props} resource={'playlist'} perPage={400}>
                {controllerProps => {
                    return (
                        <>
                            <SearchPanel
                                initialOpen={true}
                                setFilters={controllerProps.setFilters}
                                filterValues={controllerProps.filterValues}
                                type="playlist"
                            />
                            {[ROLES.admin.id.toString(), ROLES.videoAdmin.id.toString(), ROLES.superEditor.id.toString()].includes(permissions) ?
                                <PlaylistCreateButton /> : null
                            }
                            <Datagrid
                                {...controllerProps}
                                rowClick={(id: string) => dispatch(push(`/playlist/${id}`))}
                            >
                                <TextField
                                    source="title"
                                />
                                <TextField
                                    source="description"
                                />
                                <TextField
                                    source="importID"
                                />
                                <RestoreButton/>
                            </Datagrid>
                            <Pagination
                                {...controllerProps}
                                rowsPerPageOptions={[12, 24, 48, 200, 400]}
                            />
                        </>
                    );
                }}
            </ListController>
        </>
    );
}));

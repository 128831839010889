import React from 'react';
import { Labeled } from 'react-admin';
import AsyncSelect from 'react-select/async';

const SelectProgram = (props) => {
    const loadOptions = (input, f) =>
        props.dataProvider('SEARCH', 'epg-program', input)
            .then((response) => f(response.data.map((x) => Object.assign({label: x.title}, x))));

    return <Labeled label="Program *" fullWidth>
        <AsyncSelect
            menuPortalTarget={document.body}
            cacheOptions
            defaultValue={props.defaultValue}
            loadOptions={loadOptions}
            onChange={props.onChange}
        />
    </Labeled>;
};

export default SelectProgram;

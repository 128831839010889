import React, { useState } from 'react';
import diff from 'object-diff';
import { AUTOSAVE_DELAY } from './config';
import { FormSpy } from 'react-final-form';

let timeout;

// delete `difference.tags` if the tagIDs are the same
// object-diff checks by reference, that is not very useful here
const deleteIrrelevantTags = (() => {
    const resources = {
        playlist: true,
    };

    const simplify = (tags) =>
        (tags || [])
            .map((tag) => tag.id)
            .sort()
            .join(',');

    return (difference, resource, past, present) => {
        if (
            !resources[resource]
      || !difference.tags
      || (past.tags || []).length !== (present.tags || []).length
        ) {
            return;
        }

        if (simplify(past.tags) === simplify(present.tags)) {
            delete difference.tags;
        }
    };
})();

const AutoSave = ({
    dirty,
    resource,
    save,
    values,
}) => {
    const [editFormValues, setEditFormValues] = useState(values);

    clearTimeout(timeout);

    timeout = setTimeout(() => {
        if (dirty) {
            const difference = diff(editFormValues, values);

            deleteIrrelevantTags(difference, resource, editFormValues, values);

            if (
                Object.keys(difference).length
        // removing an item can lead to an empty diff, double check:
        || Object.keys(editFormValues).length !== Object.keys(values).length
            ) {
                setEditFormValues(values);
                save(values, difference);
            }
        }
    }, AUTOSAVE_DELAY);

    return null;
};

export default props => (
    <FormSpy {...props} subscription={{ values: true, dirty: true }} component={AutoSave} />
);

import React, { useState } from 'react';
import {
    Edit,
    SimpleForm,
    Toolbar,
    SaveButton,
    TextInput,
    useTranslate,
    SelectInput
} from 'react-admin';
import {
    Grid,
    Card,
    CardContent,
    CardHeader,
    Divider,
    makeStyles,
    FormControl,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Typography
} from '@material-ui/core';
import { BackButton } from '../buttons/BackButton';
import { userLanguages } from './languages';

const useStyles = makeStyles(theme => ({
    itemSelect: {
        marginTop: theme.spacing(2)
    },
    formControl: {
        margin: theme.spacing(3),
    },
}));

interface Props {
    history;
}

export default (props: Props) => {
    const [updateFirstName, setUpdateFirstName] = useState(true);
    const [updateLastName, setUpdateLastName] = useState(true);
    const [updateLanguage, setUpdateLanguage] = useState(true);

    const translate = useTranslate();
    const classes = useStyles();

    const validate = values => {
        const errors: any = {};
        if(updateLanguage) {
            if(!values.language) {
                errors.language = [translate('resources.users.form.validate.language')];
            }
        }
        return errors;
    };

    const userID = localStorage.getItem('userID');
    return (
        <Grid container spacing={3} >
            <Grid item xs={6}>
                <Card className={classes.itemSelect}>
                    <CardHeader
                        title={<Typography variant="h6" >{translate('resources.users.profile.fields.title')}</Typography>}
                        subheader={translate('resources.users.profile.fields.subtitle')}
                    />
                    <Divider />
                    <CardContent>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox value="Firstname" checked={updateFirstName} onChange={() => setUpdateFirstName(!updateFirstName)} />}
                                    label="Firstname"
                                />
                                <FormControlLabel
                                    control={<Checkbox value="Lastname" checked={updateLastName} onChange={() => setUpdateLastName(!updateLastName)} />}
                                    label="Lastname"
                                />
                            </FormGroup>
                        </FormControl>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox value="Language" checked={updateLanguage} onChange={() => setUpdateLanguage(!updateLanguage)} />}
                                    label="Language"
                                />
                            </FormGroup>
                        </FormControl>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={6}>
                <Edit undoable={false} {...props} id={userID} basePath="/users" resource="users" title={'Profile update'}>
                    <SimpleForm
                        toolbar={
                            <Toolbar {...props}>
                                <BackButton
                                    history={props.history}
                                    label={translate('ra.action.back')}
                                />
                                <SaveButton {...props} redirect={false} />
                            </Toolbar>
                        }
                        validate={validate}
                    >
                        {updateFirstName && <TextInput source="firstname" fullWidth />}
                        {updateLastName && <TextInput source="lastname" fullWidth />}
                        <TextInput source="email" fullWidth disabled />
                        {updateLanguage && <SelectInput source="language" optionText="name" choices={userLanguages} fullWidth />}

                    </SimpleForm>
                </Edit>
            </Grid>
        </Grid>
    );
};

import {
    BulkActionsToolbar,
    ListController,
    Pagination,
    translate
} from 'react-admin';
import AssetsGrid from './AssetsGrid';
import React from 'react';
import { Button, CircularProgress, Grid } from '@material-ui/core';
import SearchPanel from '../searchPanel/SearchPanel';

const AddToGalleryButton = translate(
    ({ addAssetsToGallery, galleryId, translate, selectedIds }) => {
        return (
            <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                    addAssetsToGallery(galleryId, selectedIds);
                }}
            >
                {translate('resources.asset.action.add_to_gallery')}
            </Button>
        );
    }
);

const AddAssetToComponent = ({addAssetToComponent, componentId, selectedIds, componentName}) => {
    return (
        <Button
            variant="outlined"
            color="primary"
            onClick={() => {
                addAssetToComponent(componentId, selectedIds);
            }}>
      Add to {componentName}
        </Button>
    );
};

const AddToVideoButton = translate(
    ({ addAssetsToVideo, translate, selectedIds }) => {
        return (
            <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                    addAssetsToVideo(selectedIds);
                }}
            >
                {translate('resources.asset.action.add_to_video')}
            </Button>
        );
    }
);

const AddToEpgButton = translate(
    ({ addAssetsToEpg, translate, selectedIds }) => {
        return (
            <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                    addAssetsToEpg(selectedIds);
                }}
            >
                {translate('resources.epg.action.add_to')}
            </Button>
        );
    }
);

const AssetsList = props => {
    return (
        <ListController {...props} resource={'asset'} perPage={12}>
            {controllerProps => {
                return (
                    <>
                        <SearchPanel
                            initialOpen={true}
                            setFilters={controllerProps.setFilters}
                            filterValues={{...controllerProps.filterValues, type: 'image'}}
                            type="asset"
                        />
                        {props.hasEdit === false && props.addAssetsToGallery && (
                            <div style={{ position: 'relative' }}>
                                <BulkActionsToolbar {...controllerProps}>
                                    <AddToGalleryButton
                                        galleryId={props.id}
                                        addAssetsToGallery={props.addAssetsToGallery}
                                        onSelect={controllerProps.onSelect}
                                        selectedIds={controllerProps.selectedIds}
                                    />
                                </BulkActionsToolbar>
                            </div>
                        )}
                        {props.hasEdit === false && props.addAssetToComponent && (
                            <div style={{position: 'relative'}}>
                                <BulkActionsToolbar {...controllerProps}>
                                    <AddAssetToComponent
                                        componentId={props.id}
                                        addAssetToComponent={props.addAssetToComponent}
                                        selectedIds={controllerProps.selectedIds}
                                        componentName = {props.targetComponentName}
                                    />
                                </BulkActionsToolbar>
                            </div>
                        )}
                        {props.hasEdit === false && props.addAssetsToVideo && (
                            <div style={{ position: 'relative' }}>
                                <BulkActionsToolbar {...controllerProps}>
                                    <AddToVideoButton
                                        addAssetsToVideo={props.addAssetsToVideo}
                                        onSelect={controllerProps.onSelect}
                                        selectedIds={controllerProps.selectedIds}
                                    />
                                </BulkActionsToolbar>
                            </div>
                        )}
                        {props.hasEdit === false && props.addAssetsToEpg && (
                            <div style={{ position: 'relative' }}>
                                <BulkActionsToolbar {...controllerProps}>
                                    <AddToEpgButton
                                        addAssetsToEpg={props.addAssetsToEpg}
                                        onSelect={controllerProps.onSelect}
                                        selectedIds={controllerProps.selectedIds}
                                    />
                                </BulkActionsToolbar>
                            </div>
                        )}
                        {controllerProps.isLoading ? (
                            <Grid container spacing={8}>
                                <Grid
                                    item
                                    xs={12}
                                    style={{ textAlign: 'center', marginTop: 70 }}
                                >
                                    <CircularProgress />
                                </Grid>
                            </Grid>
                        ) : (
                            <>
                                <AssetsGrid
                                    {...controllerProps}
                                    permissions={props.permissions}
                                    hasEdit={props.hasEdit !== false}
                                    handleAddTransformationToEventstream={props.handleAddTransformationToEventstream}
                                />
                                <Pagination
                                    {...controllerProps}
                                    rowsPerPageOptions={[12, 24, 48, 96]}
                                />
                            </>
                        )}
                    </>
                );
            }}
        </ListController>
    );
};

export default AssetsList;

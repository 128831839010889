import React from 'react';
import {
    translate,
} from 'react-admin';
import { push } from 'connected-react-router';
import { Button } from '@material-ui/core';

const ToEventStream = translate((props) =>
    <Button
        color="primary"
        onClick={() => props.dispatch(push(`/eventstream/${props.record.id}`))}
    >
        {props.translate('resources.epg.action.go_to_eventstream')}
    </Button>
);

export default ToEventStream;

import React from 'react';
import {
    DeleteButton,
    Edit,
    SaveButton,
    SelectInput,
    SimpleForm,
    TextInput,
    Toolbar,
    translate,
    withDataProvider,
} from 'react-admin';

import { userPermissions } from './permissions';
import { userLanguages } from './languages';
import { BackButton } from '../buttons/BackButton';
import { ROLES } from '../../utils/config';

interface Props {
    dataProvider;
    history;
    id: string;
    permissions;
    translate: CallableFunction;
}

const Title = props => {
    const record = props.record;
    return <span>{record ? record.firstName || record.lastName || record.email || '' : ''}</span>;
};

const Removal = (props) => {
    if (props.record.deletable === false || ![ROLES.admin.id.toString()].includes(props.permissions)) {
        return null;
    }
    return <DeleteButton undoable={false} {...props} />;
};

export default withDataProvider(translate((props: Props) => {
    const {
        translate,
    } = props;

    return (
        <Edit undoable={false} {...props} title={<Title />}>
            <SimpleForm
                toolbar={
                    <Toolbar {...props}>
                        <BackButton
                            history={props.history}
                            label={translate('ra.action.back')}
                        />
                        <SaveButton {...props} />
                        <Removal {...props} />
                    </Toolbar>
                }
            >
                <TextInput source="firstname" fullWidth />
                <TextInput source="lastname" fullWidth />
                <TextInput source="email" fullWidth disabled />
                <SelectInput source="language" optionText="name" choices={userLanguages} fullWidth />
                <SelectInput source="permission" optionText="name" choices={userPermissions} fullWidth />
            </SimpleForm>
        </Edit>
    );
}));

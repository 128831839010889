import React, {useState} from 'react';
import {
    Confirm,
    DELETE,
    translate,
    withDataProvider,
} from 'react-admin';
import {
    Button,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import CheckBox from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import { VideoTransformationInterface } from '../../types';
import PlayIcon from '@material-ui/icons/PlayCircleOutline';
import DownloadIcon from '@material-ui/icons/GetApp';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

interface Props {
  dataProvider;
  onChange;
  setPreviewVideo: (param: VideoTransformationInterface) => void;
  transformations: VideoTransformationInterface[];
  translate;
}

/**
 * Newly transcoded files have paths like this {path}/{number}-{quality}.{format}.
 * Give me the number, or if it is an old video file, the complete name!
 * This helps the VJ to know the last transcoded transformations.
 */
const getNumberOrName = (transformation) => {
    if (!transformation || !transformation.streamingURL) {
        return null;
    }

    const name = transformation.streamingURL.split('/').pop();
    if (!name) {
        return null;
    }

    const number = Number(name.split('-').shift());

    return number || name;
};

const DeleteTransformation = ({
    dataProvider,
    transformation,
    translate,
    update,
    withoutTransformation,
}) => {
    const [open, setOpen] = useState(false);

    return <>
        <DeleteForeverIcon
            onClick={() => setOpen(true)}
        />
        <Confirm
            content={translate('mam.delete_transformation', {transformation: transformation.streamingURL})}
            isOpen={open}
            onClose={() => setOpen(false)}
            onConfirm={() => dataProvider(DELETE, 'video_transformation', transformation)
                .then(() => update(withoutTransformation(transformation)))
                .catch(console.error.bind(console))}
            title={translate('Delete?')}
        />
    </>;
};

export default translate(withDataProvider(({
    dataProvider,
    onChange,
    setPreviewVideo,
    transformations,
    translate,
}: Props) => {

    if (!transformations || !transformations.length) {
        return null;
    }

    const update = (t) => onChange(Array.from(t));

    const withoutTransformation = (transformation) =>
        transformations.filter((t) => t.id !== transformation.id);

    return (
        <Table style={{ maxWidth: 200 }}>
            <TableHead>
                <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell>Size</TableCell>
                    <TableCell>Dimension</TableCell>
                    <TableCell>Duration</TableCell>
                    <TableCell>Default</TableCell>
                    <TableCell>Play</TableCell>
                    <TableCell>Download</TableCell>
                    <TableCell>Delete</TableCell>
                    <TableCell>No.</TableCell>
                    <TableCell>Added</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {transformations.sort((a, b) => (b.createdAt || b.updatedAt) - (a.createdAt || a.updatedAt)).map((transformation: VideoTransformationInterface) => {
                    const defaultTransformation = transformation.defaultTransformation
                        ? <CheckBox onClick={() => {
                            if (transformations.filter((t: VideoTransformationInterface) => t.defaultTransformation).length === 1) {
                                return;
                            }

                            transformation.defaultTransformation = false;
                            update(transformations);
                        }} />
                        : <CheckBoxOutlineBlank onClick={() => {
                            transformations.forEach((t) => {
                                if (
                                    t.type === transformation.type
                  && t.width === transformation.width
                  && t.height === transformation.height
                                ) {
                                    t.defaultTransformation = false;
                                }
                                transformation.defaultTransformation = true;
                            });
                            update(transformations);
                        }} />;

                    return <TableRow key={transformation.id}>
                        <TableCell>{transformation.type}</TableCell>
                        <TableCell>
                            {transformation.size && ((transformation.size / 1024 / 1024).toFixed(2) + 'MB')}
                        </TableCell>
                        <TableCell>
                            {transformation.width && `${transformation.width}x${transformation.height}`}
                        </TableCell>
                        <TableCell>{transformation.duration}</TableCell>
                        <TableCell>{defaultTransformation}</TableCell>
                        <TableCell>
                            {' '}
                            <IconButton onClick={() => setPreviewVideo(transformation)}>
                                <PlayIcon />
                            </IconButton>
                        </TableCell>
                        <TableCell>
                            <Button
                                href={transformation.streamingURL}
                                download={transformation.id}
                            >
                                <DownloadIcon />
                            </Button>
                        </TableCell>
                        <TableCell>
                            <DeleteTransformation
                                dataProvider={dataProvider}
                                transformation={transformation}
                                translate={translate}
                                update={update}
                                withoutTransformation={withoutTransformation}
                            />
                        </TableCell>
                        <TableCell>{getNumberOrName(transformation)}</TableCell>
                        <TableCell>{new Date(transformation.createdAt || transformation.updatedAt).toLocaleString()}</TableCell>
                    </TableRow>;
                })}
            </TableBody>
        </Table>
    );
}));

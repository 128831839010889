import {AuthProvider} from 'ra-core';
import keycloak from './keycloak';
import getUser from './getUser';

const authProvider: AuthProvider = {
    checkAuth: params => {
        return localStorage.getItem('token')
            ? Promise.resolve()
            : Promise.reject();
    },

    checkError: error => {
        if (error.message === 'Unauthorized') {
            localStorage.removeItem('token');
            localStorage.removeItem('permission');
            localStorage.removeItem('userID');
            return Promise.reject({message: '401 Unauthorized. Please log in'});
        }
        return Promise.resolve();
    },

    getPermissions: async () => {
        if (!localStorage.getItem('token')) {
            throw new Error('No token found')
        }

        if (localStorage.getItem('permission')) {
            const role = localStorage.getItem('permission');
            return Promise.resolve(role);
        }

        const {id, permission} = await getUser();

        localStorage.setItem('permission', permission);
        localStorage.setItem('userID', id);

        return Promise.resolve(String(permission));
    },

    login: () => {
        const a = document.createElement('a');
        a.href = window.location.href;
        a.pathname = '/admin';
        a.hash = '';
        a.search = '';
        const redirectUri = a.href;

        if (localStorage.getItem('token')) {
            return Promise.resolve();
        }

        return keycloak.login({redirectUri})
    },

    logout: () => {
        localStorage.removeItem('permission');
        localStorage.removeItem('token');
        localStorage.removeItem('userID');

        if (keycloak.authenticated) {
            return keycloak.logout();
        }
        return Promise.resolve();
    },
};

export default authProvider;

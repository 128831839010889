import React from 'react';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import LandscapeIcon from '@material-ui/icons/Landscape';
import PortraitIcon from '@material-ui/icons/Portrait';
import SquareIcon from '@material-ui/icons/CropSquare';
import PanoramaIcon from '@material-ui/icons/Panorama';
import SearchParamsType from './types/SearchParamsType';
import { translate } from 'react-admin';

export const SearchPanelOrientationElement = translate((props: {
  searchParams: SearchParamsType;
  onChange: (event: React.MouseEvent, value: string[]) => void;
  translate: CallableFunction
}) => {
    return (
        <ToggleButtonGroup
            value={props.searchParams.orientation}
            onChange={props.onChange}
            exclusive={true}
        >
            <ToggleButton value="portrait" title={props.translate('mam.search.portrait')}>
                <PortraitIcon />
            </ToggleButton>
            <ToggleButton value="landscape" title={props.translate('mam.search.landscape')}>
                <LandscapeIcon />
            </ToggleButton>
            <ToggleButton value="square" title={props.translate('mam.search.square')}>
                <SquareIcon />
            </ToggleButton>
            <ToggleButton value="panorama" title={props.translate('mam.search.panorama')}>
                <PanoramaIcon />
            </ToggleButton>
        </ToggleButtonGroup>
    );
});

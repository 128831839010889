import { httpRequestObject, OptionsInterface } from './httpRequestObjects';
import {
    CREATE,
    DELETE,
    GET_LIST,
    GET_ONE,
    UPDATE,
} from 'react-admin';
import isValidDate from './isValidDate';
import { remove } from '../components/epg/eventstream/RemovedEventstreams';

export const convertDataToTotoChannelRequest = (
    type,
    params,
    apiUrl,
    options: OptionsInterface
): httpRequestObject => {

    const { page = 1, perPage = 10 } = params.pagination || {};
    let url;
    const resource = 'epg/channel';

    options.method = 'GET';
    switch (type) {
    case CREATE: {
        url = `${apiUrl}/${resource}`;
        options.method = 'POST';
        options.headers.append('Content-Type', 'application/json');
        options.body = JSON.stringify(Object.assign({
            isHighlight: false,
            tagId: params.data.tags && params.data.tags[0] && params.data.tags[0].id,
            transformationId: null,
        }, params.data));
        break;
    }

    case DELETE: {
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'DELETE';
        break;
    }

    case GET_LIST: {
        url = encodeURI(`${apiUrl}/${resource}?page=${page}&per=${perPage}`);
        break;
    }

    case GET_ONE: {
        url = `${apiUrl}/${resource}/${params.id}`;
        break;
    }

    case 'SEARCH': {
        url = encodeURI(`${apiUrl}/${resource}/search/?text=${params}`);
        options.method = 'POST';
        break;
    }

    case UPDATE: {
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'PUT';
        options.headers.append('Content-Type', 'application/json');
        options.body = JSON.stringify(Object.assign({isHighlight: false, transformationId: null}, params.data));
        break;
    }
    }

    return { url, options };
};

export const convertDataToTotoCategoryRequest = (
    type,
    params,
    apiUrl,
    options: OptionsInterface
): httpRequestObject => {
    const { page = 1, perPage = 10 } = params.pagination || {};
    let url;
    const resource = 'epg/category';

    options.method = 'GET';
    switch (type) {
    case GET_LIST: {
        url = encodeURI(`${apiUrl}/${resource}?page=${page}&per=${perPage}`);
        break;
    }

    case GET_ONE: {
        url = `${apiUrl}/${resource}/${params.id}`;
        break;
    }

    case UPDATE: {
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'PUT';
        options.headers.append('Content-Type', 'application/json');
        options.body = JSON.stringify(params.data);
        break;
    }
    }

    return { url, options };
};

export const convertDataToTotoProgramRequest = (
    type,
    params,
    apiUrl,
    options: OptionsInterface
): httpRequestObject => {
    const { page = 1, perPage = 10 } = params.pagination || {};
    let url;
    const resource = 'epg/program';

    options.method = 'GET';
    switch (type) {
    case CREATE: {
        url = `${apiUrl}/${resource}`;
        options.method = 'POST';
        options.headers.append('Content-Type', 'application/json');
        options.body = JSON.stringify(Object.assign({
            isHighlight: false,
            tagId: params.data.tags && params.data.tags[0] && params.data.tags[0].id,
            transformationId: null,
        }, params.data));
        break;
    }

    case DELETE: {
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'DELETE';
        break;
    }

    case GET_LIST: {
        url = encodeURI(`${apiUrl}/${resource}?page=${page}&per=${perPage}`);
        break;
    }

    case GET_ONE: {
        url = `${apiUrl}/${resource}/${params.id}`;
        break;
    }

    case 'SEARCH': {
        url = encodeURI(`${apiUrl}/${resource}/search/?text=${params}`);
        options.method = 'POST';
        break;
    }

    case UPDATE: {
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'PUT';
        options.headers.append('Content-Type', 'application/json');
        options.body = JSON.stringify(Object.assign({isHighlight: false, transformationId: null}, params.data));
        break;
    }
    }

    return { url, options };
};

export const convertDataToTotoEpisodeRequest = (
    type,
    params,
    apiUrl,
    options: OptionsInterface
): httpRequestObject => {
    const { page = 1, perPage = 10 } = params.pagination || {};
    let url;
    const resource = 'epg/episode';

    options.method = 'GET';
    switch (type) {
    case CREATE: {
        url = `${apiUrl}/${resource}`;
        options.method = 'POST';
        options.headers.append('Content-Type', 'application/json');
        options.body = JSON.stringify(Object.assign({
            tagId: params.data.tags && params.data.tags[0] && params.data.tags[0].id,
        }, params.data));
        break;
    }

    case DELETE: {
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'DELETE';
        break;
    }

    case GET_LIST: {
        const {filter = {}} = params;

        const subtitle = filter && filter.subtitle ? '&subtitle=' + filter.subtitle : '';

        const subcategory = filter && filter.subcategory ? '&subcategory=' + filter.subcategory : '';

        const isHighlightValue = filter && filter.isHighlight ? filter.isHighlight : 'all';

        const isHighlight = String(isHighlightValue) !== 'all' ? `&is-highlight=${isHighlightValue}` : '';

        const id = filter && filter.id ? '&id=' + filter.id : '';

        if (filter && (filter.subtitle || filter.subcategory || filter.id || filter.isHighlight)) {
            url = encodeURI(`${apiUrl}/${resource}?page=${page}&per=${perPage}${subtitle}${subcategory}${id}${isHighlight}`);
        } else {
            url = encodeURI(`${apiUrl}/${resource}?page=${page}&per=${perPage}`);
        }
        break;
    }

    case GET_ONE: {
        url = `${apiUrl}/${resource}/${params.id}`;
        break;
    }

    case UPDATE: {
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'PUT';
        options.headers.append('Content-Type', 'application/json');
        options.body = JSON.stringify(params.data);
        break;
    }
    }

    return { url, options };
};

export const convertDataToTotoBroadcastRequest = (
    type,
    params,
    apiUrl,
    options: OptionsInterface
): httpRequestObject => {
    let url;
    const resource = 'epg/broadcast';

    if (params.data && params.data.startTime && Number(params.data.startTime)) {
        params.data.startTime = new Date(Number(params.data.startTime)).toISOString();
    }

    if (params.data && params.data.endTime && Number(params.data.endTime)) {
        params.data.endTime = new Date(Number(params.data.endTime)).toISOString();
    }

    options.method = 'GET';
    switch (type) {
    case CREATE: {
        url = `${apiUrl}/${resource}`;
        options.method = 'POST';
        options.headers.append('Content-Type', 'application/json');
        options.body = JSON.stringify(params.data);
        break;
    }

    case DELETE: {
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'DELETE';
        break;
    }

    case GET_LIST: {
        options.method = 'GET';
        if (params.filter.id) {
            url = `${apiUrl}/${resource}/${params.filter.id}`;
        } else if (params.filter.episodeID) {
            url = `${apiUrl}/${resource}?episodeID=${params.filter.episodeID}`;
        } else if(params.filter.episodeName){
            url = `${apiUrl}/${resource}?episodeName=${params.filter.episodeName}`;
        } else {
            const fromDate = params.filter.startTime
                ? new Date(params.filter.startTime)
                : new Date(new Date().toISOString().split('T').shift() + 'T00:00:00.000+01:00'); // start of today

            const toDate = params.filter.endTime
                ? new Date(params.filter.endTime)
                : new Date(new Date().toISOString().split('T').shift() + 'T23:59:59.000+01:00'); // end of today

            if (!params.filter.endTime) {
                // set Date 6 weeks into the future
                toDate.setDate(toDate.getDate() + (6*7));
            }

            url = `${apiUrl}/${resource}?fromDate=${fromDate.toISOString()}&toDate=${toDate.toISOString()}&isEventstream=true&showSport1ExtraSource=false`;
        }
        break;
    }

    case GET_ONE: {
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'GET';
        break;
    }

    case UPDATE: {
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'PUT';
        options.headers.append('Content-Type', 'application/json');
        options.body = JSON.stringify(params.data);
        break;
    }
    }

    return { url, options };
};

export const convertDataToTotoEventStreamRequest = (
    type,
    params,
    apiUrl,
    options: OptionsInterface
): httpRequestObject => {
    let url;
    const resource = 'epg/eventstream';

    switch (type) {
    case CREATE: {
        options.method = 'POST';
        url = `${apiUrl}/${resource}`;
        options.headers.append('Content-Type', 'application/json');

        if (!params.data.broadcast.channelId) {
            params.data.broadcast.channelId = params.data.channel && params.data.channel.id;
        }

        if (!params.data.episode.programId) {
            params.data.episode.programId = params.data.program && params.data.program.id;
        }

        if (!params.data.broadcast.episodeId) {
            params.data.broadcast.episodeId = params.data.episode.id;
        }

        if (params.data.tags) {
            params.data.episode.tagId = params.data.tags[0] && params.data.tags[0].id;
        }

        options.body = JSON.stringify({
            broadcast: params.data.broadcast,
            episode: params.data.episode,
        });
        break;
    }

    case DELETE: {
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'DELETE';
        remove(params.id);
        break;
    }

    case GET_LIST: {
        options.method = 'POST';
        let {text = '', fromDate = '', toDate = ''} = params.filter || {};
        const {page = 1, perPage = 12} = params.pagination;
        text = text.trim();
        fromDate = isValidDate(fromDate) ? new Date(fromDate).toISOString() : '';
        toDate = isValidDate(toDate) ? new Date(toDate).toISOString() : '';
        url = encodeURI(`${apiUrl}/${resource}/all?page=${page}&per=${perPage}&text=${text}&fromDate=${fromDate}&toDate=${toDate}`);
        break;
    }

    case GET_ONE: {
        options.method = 'GET';
        url = `${apiUrl}/${resource}/${params.id}`;
        break;
    }

    case UPDATE: {
        options.method = 'PUT';
        url = `${apiUrl}/${resource}/${params.id}`;
        options.headers.append('Content-Type', 'application/json');

        if (params.data.channel && params.data.channel.id) {
            params.data.broadcast.channelId = params.data.channel.id;
        }

        if (params.data.program && params.data.program.id) {
            params.data.episode.programId = params.data.program.id;
        }

        if (params.data.tags) {
            params.data.episode.tagId = params.data.tags[0] && params.data.tags[0].id;
        }

        options.body = JSON.stringify({
            broadcast: params.data.broadcast,
            episode: params.data.episode,
        });
        break;
    }

    case 'UPDATE_TRANSFORMATION': {
        url = encodeURI(`${apiUrl}/${resource}/transformation/${params.id}/transformation/${params.transformationID}`);
        options.method = 'PATCH';
        break;
    }
    }

    return { url, options };
};

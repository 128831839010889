import {
    convertDataToAssetMetasRequest,
    convertDataToAssetRequest,
    convertDataToGalleryRequest,
    convertDataToPlaylistRequest,
    convertDataToTagRequest,
    OptionsInterface,
} from './utils/httpRequestObjects';
import convertDataToGettyRequest from './utils/convertDataToGettyRequest';
import convertDataToImagoRequest from './utils/convertDataToImagoRequest';
import { convertDataToStatisticsRequest } from './utils/convertDataToStatisticsRequest';
import {
    convertDataToOttStreamRequest,
    convertDataToOttIndexRequest,
    convertDataToOttAppStreamsRequest,
} from './utils/convertDataToOttRequest';
import {
    ConvertCreateTransformationResponseToObject,
    ConvertGetTransformationListResponseToTransformationObject,
    ConvertVideo,
} from './utils/httpResponseObject';
import { convertDataToTransformationRequest } from './utils/convertDataToTransformationRequest';
import convertDataToVideoRequest from './utils/convertDataToVideoRequest';
import convertDataToVideoTransformationRequest from './utils/convertDataToVideoTransformationRequest';
import videoTranscodingStatusDataProvider from './utils/videoTranscodingStatusDataProvider';
import convertDataToEpisodeRequest from './utils/convertDataToEpisodeRequest';
import convertDataToUserRequest from './utils/convertDataToUserRequest';
import convertDataToAuditLogRequest from './utils/convertDataToAuditLogRequest';
import convertDataToReportRequest from './utils/convertDataToReportRequest';
import { ConvertAssetTransformationsToPrimitives } from './utils/converAssetTransformations';
import { isRemoved, remove } from './components/epg/eventstream/RemovedEventstreams';

import {
    convertDataToTotoBroadcastRequest,
    convertDataToTotoCategoryRequest,
    convertDataToTotoChannelRequest,
    convertDataToTotoEpisodeRequest,
    convertDataToTotoEventStreamRequest,
    convertDataToTotoProgramRequest,
} from './utils/convertDataToTotoRequest';

export default (apiUrl) => {
    return (type, resource, params) => {
        let options: OptionsInterface = {
            method: 'GET',
            headers: new Headers({
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }),
            body: undefined
        };

        let httpRequestObj;

        switch (resource) {
        case 'asset_metas': {
            httpRequestObj = convertDataToAssetMetasRequest(
                type,
                params,
                apiUrl,
                options
            );
            break;
        }

        case 'asset': {
            httpRequestObj = convertDataToAssetRequest(
                type,
                params,
                apiUrl,
                options
            );
            break;
        }

        case 'tag': {
            httpRequestObj = convertDataToTagRequest(type, params, apiUrl, options);
            break;
        }

        case 'tags': {
            httpRequestObj = convertDataToTagRequest(type, params, apiUrl, options);
            break;
        }

        case 'gallery': {
            httpRequestObj = convertDataToGalleryRequest(
                type,
                params,
                apiUrl,
                options
            );
            break;
        }

        case 'getty': {
            httpRequestObj = convertDataToGettyRequest(
                type,
                params,
                apiUrl,
                options
            );
            break;
        }

        case 'imago': {
            httpRequestObj = convertDataToImagoRequest(
                type,
                params,
                apiUrl,
                options
            );
            break;
        }

        case 'statistics': {
            httpRequestObj = convertDataToStatisticsRequest(
                type,
                params,
                apiUrl,
                options
            );
            break;
        }

        case 'transformation': {
            httpRequestObj = convertDataToTransformationRequest(
                type,
                params,
                apiUrl,
                options
            );
            break;
        }

        case 'ott-stream': {
            httpRequestObj = convertDataToOttStreamRequest(
                type,
                params,
                apiUrl,
                options
            );
            break;
        }

        case 'ott-index': {
            httpRequestObj = convertDataToOttIndexRequest(
                type,
                params,
                apiUrl,
                options
            );
            break;
        }

        case 'ott-app-streams': {
            httpRequestObj = convertDataToOttAppStreamsRequest(
                type,
                params,
                apiUrl,
                options
            );
            break;
        }

        case 'video': {
            httpRequestObj = convertDataToVideoRequest(
                type,
                params,
                apiUrl,
                options
            );
            break;
        }

        case 'file': {
            httpRequestObj = convertDataToAssetRequest(
                type,
                params,
                apiUrl,
                options
            );
            break;
        }

        case 'video_transcoding_status': {
            return videoTranscodingStatusDataProvider(
                type,
                params,
                apiUrl,
                options
            );
        }

        case 'video_transformation': {
            httpRequestObj = convertDataToVideoTransformationRequest(
                type,
                params,
                apiUrl,
                options
            );
            break;
        }

        case 'episode': {
            httpRequestObj = convertDataToEpisodeRequest(
                type,
                params,
                apiUrl,
                options
            );
            break;
        }

        case 'playlist': {
            httpRequestObj = convertDataToPlaylistRequest(
                type,
                params,
                apiUrl,
                options
            );
            break;
        }

        case 'users': {
            httpRequestObj = convertDataToUserRequest(
                type,
                params,
                apiUrl,
                options
            );
            break;
        }

        case 'epg-channel': {
            httpRequestObj = convertDataToTotoChannelRequest(
                type,
                params,
                apiUrl,
                options
            );
            break;
        }

        case 'epg-category': {
            httpRequestObj = convertDataToTotoCategoryRequest(
                type,
                params,
                apiUrl,
                options
            );
            break;
        }

        case 'epg-program': {
            httpRequestObj = convertDataToTotoProgramRequest(
                type,
                params,
                apiUrl,
                options
            );
            break;
        }

        case 'epg-episode': {
            httpRequestObj = convertDataToTotoEpisodeRequest(
                type,
                params,
                apiUrl,
                options
            );
            break;
        }

        case 'auditlog': {
            httpRequestObj = convertDataToAuditLogRequest(
                type,
                params,
                apiUrl,
                options
            );
            break;
        }

        case 'epg-broadcast': {
            httpRequestObj = convertDataToTotoBroadcastRequest(
                type,
                params,
                apiUrl,
                options
            );
            break;
        }

        case 'eventstream': {
            httpRequestObj = convertDataToTotoEventStreamRequest(
                type,
                params,
                apiUrl,
                options
            );
            break;
        }

        case 'playlist-asset':{
            httpRequestObj = convertDataToPlaylistRequest(
                type,
                params,
                apiUrl,
                options
            );
            break;
        }

        case 'report-imago': {
            httpRequestObj = convertDataToReportRequest(type, params, apiUrl, options);
            break;
        }
        }

        return fetch(httpRequestObj.url, httpRequestObj.options)
            .then(response => {
                const isJson = String(response.headers.get('content-type'))
                    .indexOf('application/json') > -1;

                if (
                    response.status === 401
                ) {
                    throw new Error('Unauthorized');
                }

                /**
                 * Special cases are great #1
                 * Better message for "User exists"
                 */
                if (
                    resource === 'users'
                    && type ===  'CREATE'
                    && response.status === 409
                ) {
                    throw new Error('User existiert bereits!');
                }

                /**
                 * Special cases are great #2
                 * Handle the duplicated image later (by redirecting to existing
                 * image)
                 */
                if (
                    resource === 'asset'
                    && type ===  'CREATE'
                    && response.status === 409
                ) {
                    return isJson
                        ? response.json()
                        : null;
                }

                /**
                 * Special cases are great #3
                 * Handle the duplicated imago image later (by redirecting to
                 * existing image)
                 */
                if (
                    resource === 'imago'
                    && type ===  'UPLOAD'
                    && response.status === 409
                ) {
                    return isJson
                        ? response.json()
                        : null;
                }

                /**
                 * Special cases are great #4
                 * Better message for "Server Error"
                 */
                if (
                    resource === 'users'
                    && type ===  'UPDATE'
                    && response.status === 403
                ) {
                    throw new Error('Keine Berechtigung');
                }

                /**
                 * Special cases are great #5
                 * remember that this eventstream is gone and throw an error
                 */
                if (
                    resource === 'eventstream'
                    && type ===  'GET_ONE'
                    && String(response.status).startsWith('4')
                ) {
                    remove(params.id);
                    throw new Error('Eventstream nicht gefunden');
                }

                /**
                 * Maybe we can change the response 404 in toto later
                 */
                if (response.status === 404 && resource === 'epg-broadcast' && type === 'GET_LIST') {
                    const data = [];
                    const page = {
                        data: {
                            total: 0
                        }
                    };

                    return {data, page};
                }

                if (response.ok) {
                    return isJson
                        ? response.json()
                        : null;
                }

                if (isJson) {
                    return response.json()
                        .then((json) => {
                            throw new Error(
                                (json && json.reason)
                                    ? response.statusText + ' // ' + json.reason
                                    : response.statusText
                            );
                        });
                }

                throw new Error(response.statusText);
            })
            .then((json: any) => {
                if (!json) {
                    return {
                        data: { id: null }
                    };
                }

                switch (resource) {
                case 'getty': {
                    switch (type) {
                    case 'UPLOAD': {
                        return {
                            data: json,
                            total: 1
                        };
                    }
                    default:
                        return {
                            data: json.images,
                            total: json.result_count
                        };
                    }
                }

                case 'asset': {
                    switch (type) {
                    case 'GET_TRANSFORMATION_LIST': {
                        ConvertGetTransformationListResponseToTransformationObject(
                            json
                        );
                        break;
                    }

                    case 'CREATE_TRANSFORMATION': {
                        ConvertCreateTransformationResponseToObject(json);
                        break;
                    }
                    }
                    break;
                }

                case 'playlist': {
                    switch (type) {
                    case 'GET_ONE': {
                        ConvertAssetTransformationsToPrimitives(json);
                        break;
                    }
                    }
                    break;
                }

                case 'video': {
                    switch (type) {
                    case 'GET_ONE': {
                        ConvertVideo(json);
                        // this route has the complete values of a video
                        // tell the frontend:
                        json.GOT_ONE_COMPLETELY = true;
                        break;
                    }
                    }
                    break;
                }

                case 'epg-broadcast': {
                    switch (type) {
                    case 'GET_LIST': {
                        if (json.id) {
                            json = [json];
                        }
                        break;
                    }
                    }
                    break;
                }

                case 'eventstream': {
                    switch (type) {
                    case 'GET_LIST': {
                        // filter the things out that (as far as the frontend knows) are gone
                        if (json && json.data && json.data.length) {
                            json.data = json.data.filter((eventStream) => !isRemoved(eventStream.id));
                        }
                        break;
                    }
                    }
                    break;
                }

                case 'report': {
                    switch (type) {
                    case 'GET_LIST': {
                        if (json.id) {
                            json = [json];
                        }
                        break;
                    }
                    }
                }
                }

                return {
                    data: json.data || json,
                    total: json.page ? json.page.data.total : 10
                };
            });
    };
};

import React from 'react';
import {
    translate,
    withDataProvider,
    CREATE,
} from 'react-admin';
import { useDispatch } from 'react-redux';
import { AddToPhotos } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import { push } from 'connected-react-router';

const VideoCloneButton = (props) => {
    const { translate, dataProvider, record, callbackFunction } = props;
    const dispatch = useDispatch();

    const cloneVideo = () => {
        const clonedVideo = (({tags, tracking, title, vast, countries, availableAt, expiresAt, geoType}) =>
            ({tags, tracking, title, vast, countries, availableAt, expiresAt, geoType}))(record);

        if (clonedVideo.tracking) {
            delete clonedVideo.tracking.id;
        }

        if (clonedVideo.vast) {
            delete clonedVideo.vast.id;
        }

        dataProvider(
            CREATE,
            'video',
            {
                ...clonedVideo,
                publishedAt: Date.now(),
                approved: false,
                complete: false
            },
            {
                onSuccess: {
                    notification: {
                        body: 'resources.video.notification.created',
                        info: 'info',
                    },
                    refresh: true,
                },
                onError: {
                    notification: {
                        body: 'resources.playlist.notification.creation_failed',
                        info: 'warning',
                    },
                    refresh: false,
                },
            })
            .then((response) => {
                dispatch(push(`/video/${response.data.id}`));
                callbackFunction();
            });
    };

    return (
        <Button startIcon={<AddToPhotos />} onClick={cloneVideo} color="primary">{translate('ra.action.clone')}</Button>
    );
};

export default translate(withDataProvider(VideoCloneButton));

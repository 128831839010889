import React, { useEffect, useState } from 'react';
import {
    Edit,
    withDataProvider,
    SimpleForm,
    Toolbar,
    translate,
    TextInput,
    BooleanInput,
    SaveButton
} from 'react-admin';

import { Grid } from '@material-ui/core';
import { BackButton } from '../buttons/BackButton';
import StatsCounter from '../dashboard/StatsCounter';
import { Stats } from '../../types';
import { Image, VideoLibrary, Filter9Plus, PlaylistAdd } from '@material-ui/icons';

interface TagEditProps {
    dataProvider,
    id: string,
    permissions,
    history,
}

const Title = props => {
    const record = props.record;
    return <span>{record ? record.tag : ''}</span>;
};

const TagEdit: React.SFC<TagEditProps> = translate(withDataProvider((props) => {
    const {
        dataProvider,
        id,
        translate
    } = props;

    const initialStats: Stats = {
        count: 0,
        countLast30Days: 0,
    };
    const [statsImages, setStatsImages] = useState<Stats>(initialStats);
    const [statsGalleries, setStatsGalleries] = useState<Stats>(initialStats);
    const [statsVideos, setStatsVideos] = useState<Stats>(initialStats);
    const [statsPlaylists, setStatsPlaylists] = useState<Stats>(initialStats);

    useEffect(() => {
        dataProvider('USAGE', 'tag', { id: id, type: 'images' })
            .then(response => {
                setStatsImages(response.data);
            })
            .catch(error => console.error(error));
    }, [dataProvider, id]);

    useEffect(() => {
        dataProvider('USAGE', 'tag', { id: id, type: 'galleries' })
            .then(response => {
                setStatsGalleries(response.data);
            })
            .catch(error => console.error(error));
    }, [dataProvider, id]);

    useEffect(() => {
        dataProvider('USAGE', 'tag', { id: id, type: 'videos' })
            .then(response => {
                setStatsVideos(response.data);
            })
            .catch(error => console.error(error));
    }, [dataProvider, id]);

    useEffect(() => {
        dataProvider('USAGE', 'tag', { id: id, type: 'playlists' })
            .then(response => {
                setStatsPlaylists(response.data);
            })
            .catch(error => console.error(error));
    }, [dataProvider, id]);

    const imageIcon = () => {
        return (<Image />);
    };

    const galleryIcon = () => {
        return (<Filter9Plus />);
    };

    const videoIcon = () => {
        return (<VideoLibrary />);
    };

    const playlistIcon = () => {
        return (<PlaylistAdd />);
    };

    return (
        <>
            <Edit undoable={false} {...props} title={<Title />}>
                <SimpleForm
                    toolbar={
                        <Toolbar >
                            <BackButton
                                history={props.history}
                                label={translate('ra.action.back')}
                            />
                            <SaveButton {...props} />
                        </Toolbar>
                    }
                >
                    <TextInput source="tag" fullWidth disabled/>
                    <TextInput source="externalID" fullWidth />
                    <TextInput source="source" fullWidth />
                    <BooleanInput source="approved" />
                    <TextInput source="sportID" />
                </SimpleForm>

            </Edit>
            <Grid container item xs={12} spacing={4}>
                <Grid
                    item
                    xs={3}>
                    <StatsCounter mainText={translate('mam.dashboard.images')} mainStat={Number(statsImages.count)} mainImage={imageIcon()} />
                </Grid>
                <Grid
                    item
                    xs={3}>
                    <StatsCounter mainText={translate('mam.dashboard.gallery')} mainStat={Number(statsGalleries.count)} mainImage={galleryIcon()} />
                </Grid>
                <Grid
                    item
                    xs={3}>
                    <StatsCounter mainText={translate('mam.dashboard.videos')} mainStat={Number(statsVideos.count)} mainImage={videoIcon()} />
                </Grid>
                <Grid
                    item
                    xs={3}>
                    <StatsCounter mainText={translate('mam.dashboard.playlists')} mainStat={Number(statsPlaylists.count)} mainImage={playlistIcon()} />
                </Grid>
            </Grid>
        </>
    );
}));

export default TagEdit;

import React from 'react';
import {
    translate,
    withDataProvider,
} from 'react-admin';
import {
    AppBar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fab,
    IconButton,
    Toolbar,
    Tooltip,
    Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AssetsList from '../assets/AssetsList';
import AddIcon from '@material-ui/icons/Add';

export default withDataProvider(translate((props) => {
    const {
        addThumbnailsToVideo,
        dataProvider,
        openDialog,
        setOpenDialog,
        setSelectedIds,
        translate,
    } = props;

    const addAssetsToVideo = (assetIDs) => {
        dataProvider('GET_TRANSFORMATION_LIST', 'asset', { id: assetIDs[0] })
            .then((transformations) => {
                // use the first transformation (if exists), the editor may pick
                // another one later
                addThumbnailsToVideo((transformations.data[0] && transformations.data[0].id) || null);
                setSelectedIds('asset', []);
                setOpenDialog(false);
            })
            .catch(error => console.error(error));
    };

    return <>
        <Dialog fullScreen={true} open={openDialog}>
            <AppBar>
                <Toolbar>
                    <IconButton color="inherit" onClick={() => setOpenDialog(false)} aria-label="Close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" color="inherit">
                        {translate('resources.video.label.add_thumbnails_to_video')}
                    </Typography>
                </Toolbar>
            </AppBar>
            <DialogTitle id="simple-dialog-title">&nbsp;</DialogTitle>
            <DialogContent>
                <AssetsList
                    {...props}
                    addAssetsToVideo={addAssetsToVideo}
                    hasEdit={false}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => setOpenDialog(false)}
                    color="primary"
                >
                    {translate('mam.close')}
                </Button>
            </DialogActions>
        </Dialog>
        <Tooltip title={translate('resources.video.label.add_thumbnails_to_video')}>
            <Fab
                onClick={() => setOpenDialog(true)}
                color="secondary"
            >
                <AddIcon />
            </Fab>
        </Tooltip>
    </>;
}));

import React, { useState } from 'react';
import { CardMedia, Tooltip } from '@material-ui/core';
import { ImagoImagePreview } from './ImagoImagePreview';
import { ImagoImagesInterface } from '../../types';
import { translate } from 'react-admin';

interface ImagoCardMediaWithPreviewProps {
  cardMediaStyle;
  image: ImagoImagesInterface;
  handlePickImage: (imageId: string) => void;
}

export const ImagoCardMediaWithPreview: React.FC<
  ImagoCardMediaWithPreviewProps
> = translate(({ cardMediaStyle, image, handlePickImage, translate }) => {
    const initAnchorEl: any = null;
    const [anchorEl, setAnchorEl] = useState(initAnchorEl);

    return (
        <div>
            <Tooltip
                title={translate('resources.getty.click_to_upload')}
                placement={'top'}
            >
                <CardMedia
                    aria-haspopup="true"
                    onClick={() => {
                        handlePickImage(image.pictureid, image.db);
                    }}
                    onMouseLeave={() => {
                        setAnchorEl(null);
                    }}
                    onMouseEnter={e => {
                        if (e.currentTarget) {
                            setAnchorEl(e.currentTarget);
                        }
                    }}
                    style={cardMediaStyle}
                    image={image.previewURL}
                />
            </Tooltip>
            <ImagoImagePreview image={image} anchorEl={anchorEl} />
        </div>
    );
});

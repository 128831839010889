import React, { useCallback } from 'react';
import { useForm } from 'react-final-form';
import { Button } from '@material-ui/core';
import Update from '@material-ui/icons/Update';


const ForceUpdate = ({
    save
}) => {
    const form = useForm()
    const handleClick = useCallback(() => {
        const { values } = form.getState()
        save(values, !values?.GOT_ONE_COMPLETELY)
    }, [form, save])

    return (
        <Button
            onClick={handleClick}
            color="default"
        >
            <Update />
            Update
        </Button>
    )
};

export default ForceUpdate

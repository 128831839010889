import CreatableSelect from 'react-select/async-creatable';
import React, {Dispatch, useState} from 'react';
import {
    GET_LIST,
    translate,
    withDataProvider,
    useNotify
} from 'react-admin';
import { SelectOptionInterface } from '../types';
import { AnyAction } from 'redux';
import transformOptions from '../utils/transformOptions';
import { ROLES } from '../utils/config';

function mapTagToColor(data: SelectOptionInterface) {
    let color = 'DeepSkyBlue';

    if (data.label.startsWith('s1plus') || data.label.startsWith('s1tv')) {
        return 'LimeGreen';
    }

    switch (data.label[0]) {
    case '@':
        color = 'green';
        break;
    case '/':
        color = 'red';
        break;
    default:
        if (data.approved) {
            color = 'orange';
        }
        break;
    }

    return color;
}

const colourStyles = {
    multiValueRemove: (styles, state: { data: SelectOptionInterface }) => {
        return {
            ...styles,
            borderRadius: 0,
            color: 'white',
            backgroundColor: mapTagToColor(state.data)
        };
    },

    multiValueLabel: (styles, state: { data: SelectOptionInterface }) => {
        return {
            ...styles,
            borderRadius: 0,
            color: 'white',
            backgroundColor: mapTagToColor(state.data)
        };
    },

    option: (styles, state: { data: SelectOptionInterface }) => {
        return { ...styles, color: mapTagToColor(state.data) };
    }
};

interface Props {
  dataProvider: any;
  dispatch: Dispatch<AnyAction>;
  formData;
  maxTags?: number;
  mayCreateNewTags?: boolean;
  onChange: any;
  permissions;
  record?: any;
  recordWins?: boolean;
  restrictNavTags?: boolean;
  selectedTags?: SelectOptionInterface[];
  setSelectedTags: Dispatch<SelectOptionInterface[]>;
  translate;
}

export default withDataProvider(translate(({
    dataProvider,
    maxTags,
    mayCreateNewTags,
    onChange,
    permissions,
    record,
    recordWins,
    restrictNavTags,
    selectedTags,
    setSelectedTags,
    translate,
}: Props): React.ReactElement => {
    // if the selectedTags ain't given, check if the record has them
    if (recordWins && record && Array.isArray(record.tags)) {
        selectedTags = transformOptions.tagsToOptions(record.tags);
    }
    const [inputValue, setInputValue] = useState('');

    const notify = useNotify();

    const handleOnInputChange = (value: string) => {
        setInputValue(value.toLowerCase());
    };

    const handleOnChange = (
        options,
        action: { action: string; option: SelectOptionInterface }
    ) => {
    // check if navigation tag already exists
        if (
            restrictNavTags
      && action.action === 'select-option'
      && selectedTags
      && selectedTags.find((tag: SelectOptionInterface) => tag.label[0] === '/')
      && action.option.label[0] === '/'
        ) {
            notify('mam.navigation_tag_already_exists');
            return;
        }

        if ('number' === typeof maxTags && options && options.length > maxTags) {
            notify('Allowed number of Tags: ' + maxTags);
            return;
        }

        if (record) {
            if (recordWins) {
                record.tags = transformOptions.optionsToTags(options);
            }
            onChange((options || []).map(option => ({ tag: option.label, id: (!isNaN(option.value) ? option.value : null), approved: option.approved, sportID: option.sportID, externalID: option.externalID, source: option.source })));
        }
    };

    const handleLoadOptions = (inputValue: string) => {
        return dataProvider(GET_LIST, 'tag', inputValue).then((response) => {
            let options: any[] = [];
            response.data.forEach(value => {
                options.push({ label: value.tag, value: value.id, approved: value.approved, sportID: value.sportID, externalID: value.externalID, source: value.source });
            });
            return options;
        });
    };

    const handleIsValidCreateValue = (
        inputValue: string,
        selectValue,
        selectOptions
    ) => {
        if (false === mayCreateNewTags) {
            return false;
        }
        let alreadyExistOption = false;
        if (inputValue.startsWith('/') || inputValue.startsWith('#')) {
            if (!([ROLES.admin.id.toString(), ROLES.videoAdmin.id.toString(), ROLES.superEditor.id.toString()].includes(permissions))) {
                return false;
            }
        }
        if (inputValue.includes('_')) {
            return false;
        }
        for (let i = 0; i < selectOptions.length; i++) {
            if (
                selectOptions[i]['label'].toLowerCase() === inputValue.toLowerCase()
            ) {
                alreadyExistOption = true;
                break;
            }
        }

        return !alreadyExistOption && inputValue.length >= 3;
    };

    return (
        <>
            <CreatableSelect
                menuPortalTarget={document.body}
                loadOptions={handleLoadOptions}
                value={selectedTags}
                inputValue={inputValue}
                options={selectedTags}
                onChange={handleOnChange}
                onInputChange={handleOnInputChange}
                isValidNewOption={handleIsValidCreateValue}
                styles={colourStyles}
                placeholder={translate('Search for tags...')}
                noOptionsMessage={() => translate('mam.no_results')}
                formatCreateLabel={inputValue =>
                    translate('mam.create_tag', { value: inputValue })
                }
                isMulti
            />
        </>
    );
}));

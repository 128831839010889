import React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    Toolbar,
    translate,
    SaveButton,
    SelectInput
} from 'react-admin';
import { userLanguages } from './languages';
import { userPermissions } from './permissions';
import { BackButton } from '../buttons/BackButton';

const UsersCreate = props => (

    <Create {...props} >
        <SimpleForm
            toolbar={
                <Toolbar {...props}>
                    <BackButton
                        history={props.history}
                        label={translate('ra.action.back')}
                    />
                    <SaveButton {...props} />
                </Toolbar>
            }
        >  
            <TextInput source="firstname" fullWidth />
            <TextInput source="lastname" fullWidth />
            <TextInput source="email" fullWidth />
            <TextInput source="password" fullWidth />
            <SelectInput source="language" optionText="name" choices={userLanguages} fullWidth />
            <SelectInput source="permission" optionText="name" choices={userPermissions} fullWidth />

        </SimpleForm>
    </Create>

);

export default translate(UsersCreate);
import React, { useState } from 'react';
import { TextInput } from 'react-admin';

interface Props {
  fullWidth?: boolean;
  label: string;
  maxLength: number;
  resettable?: boolean;
  source: string;
}

export const TextInputCharactersLimit: React.FC<Props> = ({
    fullWidth,
    label,
    maxLength,
    resettable,
    source,
}: Props) => {
    const [remainingCharactersLength, setRemainingCharactersLength] = useState(
        maxLength
    );

    const handleOnChange = (event) => {
        const length = (event.target && event.target.value.length) || 0;
        setRemainingCharactersLength(maxLength - length);
    };

    return (
        <TextInput
            fullWidth={fullWidth}
            label={`${label} ${
                remainingCharactersLength < maxLength ? remainingCharactersLength : ''
            }`}
            inputProps={{ maxLength: maxLength }}
            onChange={handleOnChange}
            resettable={resettable}
            source={source}
        />
    );
};

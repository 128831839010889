import { EZ_DATA_RECEIVED } from './eZDataAction';

export default (previousState = null, action) => {
    if (action.type === EZ_DATA_RECEIVED) {
        return Object.assign({}, previousState, {
            ez_data: action.payload
        });
    }
    return previousState;
};

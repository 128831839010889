import React  from 'react';
import {
    BooleanField,
    Datagrid,
    EditButton,
    List,
    TextField,
} from 'react-admin'; // eslint-disable-line import/no-unresolved

const EpgProgramList = props =>
    <List bulkActionButtons={false} {...props} >
        <Datagrid>
            <TextField sortable={false} source="id" />
            <TextField sortable={false} source="title" />
            <TextField sortable={false} source="categoryId" />
            <TextField sortable={false} source="transformationId"/>
            <TextField sortable={false} source="source" />
            <BooleanField sortable={false} source="isHighlight" />
            <EditButton />
        </Datagrid>
    </List>;

export default EpgProgramList;

import {
    CREATE,
    GET_ONE,
} from 'react-admin';

const createTranscodingStatus = (params, apiUrl, options) => {
    options.method = 'POST';
    return fetch(`${apiUrl}/video/${params.id}/encode/${params.format}`, options)
        .then((response) => response.json());
};

const getTranscodingStatus = (params, apiUrl, options) => {
    options.method = 'POST';
    return fetch(`${apiUrl}/video/${params.id}/transcode/status`, options)
        .then((response) => response.json())
        .then((response) => {
            return response;
        });
};

export default (type, params, apiUrl, options) => {
    if (GET_ONE === type) {
        return getTranscodingStatus(params, apiUrl, options);
    }

    if (CREATE === type) {
        return createTranscodingStatus(params, apiUrl, options);
    }
};

import { ReduxState } from 'ra-core';

export type ThemeName = 'light' | 'dark';

export interface AppState extends ReduxState {
  theme: ThemeName;
}

export interface EpisodeInterface {
  id: number;
  title: string;
}

export interface GettyImagesInterface {
  asset_family: string;
  caption: string;
  collection_code: string;
  collection_id: number;
  collection_name: string;
  display_sizes: [{ name: string; uri: string; is_watermarked: boolean }];
  id: string;
  license_model: string;
  max_dimensions: { height: number; width: number };
  title: string;
}

export class Contrast {
  initialValue = 0;
  value = 0;

  constructor(value: number) {
      this.value = value;
  }

  toString() {
      return this.value !== this.initialValue ? `contrast(${this.value})` : '';
  }
}

export class Quality {
  initialValue = 100;
  value = 100;

  constructor(value: number) {
      this.value = value;
  }

  toString() {
      return this.value !== this.initialValue ? `quality(${this.value})` : '';
  }
}

export class Brightness {
  initialValue = 0;
  value = 0;

  constructor(value: number) {
      this.value = value;
  }

  toString() {
      return this.value !== this.initialValue ? `brightness(${this.value})` : '';
  }
}

export class Saturation {
  initialValue = 1;
  value = 1;

  constructor(value: number) {
      this.value = value;
  }

  toString() {
      return this.value !== this.initialValue ? `saturation(${this.value.toPrecision(2)})` : '';
  }
}

export class Noise {
  initialValue = 0;
  value = 0;

  constructor(value: number) {
      this.value = value;
  }

  toString() {
      return this.value !== this.initialValue ? `noise(${this.value})` : '';
  }
}

export class GrayScale {
  value = false;
  constructor(value: boolean) {
      this.value = value;
  }

  toString() {
      return this.value ? 'grayscale()' : '';
  }
}

export class RoundCorner {
  initialValue = 0;
  radius = 0;
  constructor(value: number) {
      this.radius = value;
  }

  toString() {
      return this.radius !== this.initialValue ? `round_corner(${this.radius},0,0,0)` : '';
  }
}

export class RedEye {
  value = false;
  constructor(value: boolean) {
      this.value = value;
  }

  toString() {
      return this.value ? 'red-eye()' : '';
  }
}

export class Focus {
  value: { active: boolean, leftPercentage: number, topPercentage: number } = { active: false, leftPercentage: 50, topPercentage: 50 };

  constructor(value: { active: boolean, leftPercentage: number, topPercentage: number }) {
      this.value = value;
  }

  toString() {
      return (this.value.active) ? `focus(${this.value.leftPercentage},${this.value.topPercentage})` : '';
  }
}

export interface SelectOptionInterface {
  label: string;
  value: number;
  __isNew__?: boolean;
  approved?: boolean;
  sportID?: String;
  externalID?: string;
}

export interface TagInterface {
  externalID?: string;
  id: number;
  source?: string;
  tag: string;
  sportID?: String;
  appoved?: boolean;
}

export interface TransformationInterface {
  assetID: string;
  createdAt: number;
  hash: string;
  id: string;
  parameters: object;
  updatedAt: number;
}

export interface VideoTransformationInterface {
  createdAt: number;
  defaultTransformation: boolean;
  duration: number;
  height: number;
  id: number;
  importID: string;
  size: number;
  storageID: string;
  streamingURL: string;
  type: string;
  updatedAt: number;
  videoID: string;
  width: number;
}

export interface GalleryAssetInterface {
  copyright: string;
  createdAt: number;
  description: string;
  externalId: number;
  hash: string;
  height: number;
  id: string;
  size: number;
  storageID: string;
  type: string;
  updatedAt: number;
  width: number;
  title: string;
  caption: string;
  position: number;
  transformationID?: string;
  isTeaser: boolean;
}

export interface AssetInterface {
  id: string;
  hash: string;
  updatedAt: number;
  externalId: number;
  description: string;
  width: number;
  type: string;
  title: string;
  size: number;
  height: number;
  storageID: string;
  createdAt: number;
  copyright: string;
}

export interface GalleryInterface {
  id: string;
  title: string;
  updatedAt: number;
  createdAt: number;
}

export interface VideoInterface {
  id: string;
  hash: string;
  updatedAt: number;
  externalId: number;
  description: string;
  width: number;
  type: string;
  title: string;
  size: number;
  height: number;
  storageID: string;
  createdAt: number;
  copyright: string;
  transformationID?: string;
}

export interface ImagoImagesInterface {
  source: string;
  height: number;
  db: string;
  width: number;
  archivaldate: string;
  pictureid: number;
  caption: string;
  datecreated: string;
  previewURL: string;
}
//Dashboard
export interface Stats {
  count: number;
  countLast30Days: number;
}

export interface TagCount {
  total: number;
  id: number;
  tag: string;
  source: string;
}

export interface Competition {
  id: string
  currentSeasonId?: string
  isActive?: boolean
  name: string
  sportId: string
  topSort?: number
  nameDe?: string
}

export interface Season {
  id: string
  name: string
}

export interface Team {
  teamId: string
  name: string
  nameDe?: string
  country?: string
}

export interface Match {
  id: string
  awayScore: number
  awayTeam: Team[]
  homeTeam: Team[]
  homeScore: number
  matchDay: number
  scheduledStartTime: Date
}

export interface InterfaceFilters {
  Contrast: Contrast;
  Quality: Quality;
  Brightness: Brightness;
  Saturation: Saturation;
  Noise: Noise;
  Fill: string;
  Extract_Focal: boolean;
  Grayscale: GrayScale;
  Round_Corner: RoundCorner;
  ShowGrid: boolean;
  Smart: boolean;
  RedEye: boolean;
  Focus: Focus
}

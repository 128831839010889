import React from 'react';
import {
    BooleanInput,
    Create,
    DateTimeInput,
    NumberInput,
    SimpleForm,
} from 'react-admin';

export const EpgBroadcastCreate = (props) =>
    <Create {...props} >
        <SimpleForm>
            <NumberInput source="channelId" required fullWidth />

            <NumberInput source="episodeId" required fullWidth />

            <DateTimeInput
                fullWidth
                parse={(d) => new Date(d)}
                resettable
                source="startTime"
                required
            />

            <DateTimeInput
                fullWidth
                parse={(d) => new Date(d)}
                resettable
                source="endTime"
                required
            />

            <BooleanInput source="isActive" required defaultValue={false} />
            <BooleanInput source="isEventStream" required defaultValue={false} />
            <BooleanInput source="isHighlight" required defaultValue={false} />
        </SimpleForm>
    </Create>;

export default EpgBroadcastCreate;

export const sports = [
    {id: 'opta:1', displayName: 'Soccer', name: 'soccer', displayNameDe: 'Fussball'},
    // {id: 'sr:sport:5', displayName: 'Tennis', name: 'tennis', displayNameDe: 'Tennis'},
    // {id: 'sr:sport:6', displayName: 'Handball', name: 'handball', displayNameDe: 'Handball'},
    // {id: 'sr:sport:12', displayName: 'Rugby', name: 'rugby', displayNameDe: 'Rugby'},
    // {id: 'sr:sport:16', displayName: 'American Football', name: 'american_football', displayNameDe: 'American Football'},
    // {id: 'sr:sport:2', displayName: 'Basketball', name: 'basketball', displayNameDe: 'Basketball'},
    // {id: 'sr:sport:22', displayName: 'Darts', name: 'darts', displayNameDe: 'Darts'},
    // {id: 'sr:sport:23', displayName: 'Volleyball', name: 'volleyball', displayNameDe: 'Volleyball'},
    // {id: 'sr:sport:24', displayName: 'Field hockey', name: 'field_hockey', displayNameDe: 'Feldhockey'},
    // {id: 'sr:sport:3', displayName: 'Baseball', name: 'baseball', displayNameDe: 'Baseball'},
    // {id: 'sr:sport:4', displayName: 'Ice Hockey', name: 'ice_hockey', displayNameDe: 'Ice Hockey'},
];

import React, { useEffect, useState } from 'react';
import {
    Button,
    Collapse,
    FormControl,
    Grid,
    Input,
    Paper
} from '@material-ui/core';
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import ArrowUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';
import SearchParamsType from './types/SearchParamsType';
import { SearchPanelOrientationElement } from './SearchPanelOrientationElement';
import { SearchPanelDeleteElement } from './SearchPanelDeleteElement';
import { TagMultiSelectElement } from './TagMultiSelectElement';
import { translate } from 'react-admin';
import { Tooltip } from '@material-ui/core';

const styles = theme => ({
    root: {
        marginTop: 15,
        marginBottom: 8,
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 8,
        paddingRight: 8
    },
    formControl: {
        margin: 0
    },
    searchField: {
        margin: 0,
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        padding: 2,
        paddingLeft: 5,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
        },
        '&:hover': {},
        '&:after': {
            transform: 'none',
            border: 'none'
        }
    },
    idField: {
        margin: `${theme.spacing.unit * 2}px 0 ${theme.spacing.unit}px 0`,
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        padding: 2,
        paddingLeft: 5,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
        },
        '&:hover': {},
        '&:after': {
            transform: 'none',
            border: 'none'
        }
    },
    toggleContainer: {
        padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: `${theme.spacing.unit}px 0`,
    },
    selectTime: {
        padding: `${theme.spacing.unit}px 0`,
        margin: `${theme.spacing.unit}px 0`,
    },
    selectTimeDivider: {
        padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: `${theme.spacing.unit}px 0`,
        background: theme.palette.background.default
    },
    button: {
        margin: 0,
        marginTop: 16,
        marginBottom: 8
    },
    deleteContainer: {
        flexBasis: 0
    },
});

type Props = {
  classes?: any;
  filterValues: any;
  initialOpen: boolean;
  onChange?: Function;
  setFilters: any;
  translate?: any;
  type: string;
};

export default translate(withStyles(styles)(({
    classes,
    filterValues,
    initialOpen,
    onChange,
    setFilters,
    translate,
    type,
}: Props) => {
    const [open, setOpen] = useState(initialOpen);

    const initialSearchParams: SearchParamsType = {
        deleted: filterValues.deleted || false,
        id: filterValues.id || '',
        keyword: filterValues.text || '',
        orientation: filterValues.orientation || ['any'],
        tags: filterValues.tags || [],
        timeWhenValue: filterValues.timeWhenValue || {
            label: translate('Any'),
            value: 'any'
        },
        type: filterValues.type || '',
    };

    const [searchParams, setSearchParams] = useState(initialSearchParams);

    const handleCollapse = () => {
        setOpen(!open);
    };

    const handleChange = (element, value) => {
        let searchParamsCloned = Object.assign({}, searchParams);
        searchParamsCloned[element] = value;
        setSearchParams(searchParamsCloned);
        if (onChange) {
            onChange();
        }
    };

    const resetFilters = () => {
        const resetSearchParams: SearchParamsType = {
            deleted: false,
            id: '',
            keyword: '',
            orientation: ['any'],
            tags: [],
            timeWhenValue: { label: translate('Any'), value: 'any' },
            type: '',
        };
        setSearchParams(resetSearchParams);
        if (onChange) {
            onChange();
        }
    };

    useEffect(() => {
        setFilters({
            deleted: searchParams.deleted,
            id: searchParams.id,
            orientation: searchParams.orientation,
            tags: searchParams.tags,
            text: searchParams.keyword,
            timeWhenValue: searchParams.timeWhenValue,
            type: searchParams.type,
        });
    // eslint-disable-next-line
  }, [searchParams]);

    return (
        <Paper square={true} id="SearchPanelNew" className={classes.root} elevation={2}>
            <Grid container spacing={1}>
                <Grid item xs={5}>
                    <FormControl fullWidth={true} classes={classes.formControl}>
                        <Input
                            value={searchParams.keyword}
                            onChange={(event) => handleChange('keyword', event.target.value)} id="text"
                            className={classes.searchField}
                            placeholder={translate('Search keyword...')}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs id="tag-multiselect">
                    <TagMultiSelectElement
                        menuPortalTarget={document.getElementById('tag-multiselect')}
                        onChange={(value) => handleChange('tags', value)}
                        defaultValues={searchParams.tags}
                    />
                </Grid>
                <Grid item xs={1} className={classes.deleteContainer}>
                    <SearchPanelDeleteElement
                        searchParams={searchParams}
                        onChange={(event, value) => handleChange('deleted', value)}
                    />
                </Grid>
                <Grid item xs={1} style={{ textAlign: 'right' }}>
                    <Button onClick={handleCollapse}>
                        {open ? <ArrowUpIcon /> : <ArrowDownIcon />}
                    </Button>
                </Grid>
            </Grid>

            <Collapse in={open}>
                <Grid container spacing={1}>
                    <Grid item xs={5}>
                        <FormControl fullWidth={true} classes={classes.formControl}>
                            <Input
                                value={searchParams.id}
                                onChange={(event) => handleChange('id', event.target.value)}
                                id="text"
                                className={classes.idField}
                                placeholder={translate('ID')}
                            />
                        </FormControl>
                    </Grid>
                    {type === 'asset' && (
                        <Grid item>
                            <div className={classes.toggleContainer}>
                                <SearchPanelOrientationElement
                                    searchParams={searchParams}
                                    onChange={(event, value) => handleChange('orientation', value)}
                                />
                            </div>
                        </Grid>
                    )}
                    <Grid item xs id="selectTimeUploaded">
                        <Grid container>
                            <Grid item xs={5}>
                                <div className={classes.selectTime}>
                                    <Select
                                        value={searchParams.timeWhenValue}
                                        id=""
                                        menuPortalTarget={document.getElementById( 'selectTimeUploaded')}
                                        options={[
                                            { label: translate('Any'), value: 'any' },
                                            { label: translate('Today'), value: 'today' },
                                            { label: translate('Yesterday'), value: 'yesterday' },
                                            { label: translate('Last 30 days'), value: 'last30days' },
                                            { label: translate('Last Year'), value: 'last365days' }
                                        ]}
                                        onChange={(value) => handleChange('timeWhenValue', value)}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <div className={classes.toggleContainer}>
                                    <Tooltip title={translate('Reset')}>
                                        <IconButton
                                            onClick={resetFilters}
                                            arial-label={translate('Reset')}
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Collapse>
        </Paper>
    );
}));

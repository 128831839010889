import React from 'react';
import { translate } from 'react-admin';

export default translate(({
    record,
    translate,
}) => {
    if (!record.deletedAt) {
        return null;
    }
    return <div style={{
        background: 'red',
        color: 'white',
        padding: '1ch',
        textAlign: 'center',
        textTransform: 'uppercase',
    }}>
        {translate('Deleted')}
    </div>;
});

import { Grid, Typography, Button, Tooltip } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/GetApp';
import React, {useEffect} from 'react';
import { translate } from 'react-admin';
import { DOWNLOAD_URL } from '../../utils/config';

export const FileInfo = translate(props => {
    const { className, record, translate, handleFileType } = props;
    var normalType = '';
    switch (record.type) {
    case 'image/png':
        normalType = '.png';
        break;
    case 'image/svg+xml':
        normalType = '.svg';
        break;
    case 'image/jpeg':
        normalType = '.jpg';
        break;
    case 'image/gif':
        normalType = '.gif';
        break;
    case 'application/pdf':
        normalType = '.pdf';
        break;
    default:
        break;
    }


    useEffect(() => {
        handleFileType(record.type);
    });

    return (
        <Grid className={className} container spacing={1} style={{width: '100%'}} >
            <Grid container item xs={12} >
                <Grid item xs={6} >
                    <Typography variant="body1">
                        {translate('resources.asset.fileInfo.file_type')}:
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    {' '}
                    <Typography variant="body1">{record.type}</Typography>
                </Grid>
            </Grid>

            <Grid container item xs={12} >
                <Grid item xs={6}>
                    <Typography variant="body1">
                        {translate('resources.asset.fileInfo.file_size')}:
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    {' '}
                    <Typography variant="body1">
                        {record.size > 0 &&
              (parseFloat(record.size) / 1024 / 1024).toFixed(2) + 'MB'}
                        {record.size === 0 && 'N/A'}
                    </Typography>
                </Grid>
            </Grid>

            <Grid container item xs={12} >
                <Grid item xs={6}>
                    <Typography variant="body1">
                        {translate('resources.asset.fileInfo.resolution')}:
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    {' '}
                    <Typography variant="body1">
                        {record.height} x {record.width}{' '}
                        <Tooltip title={translate('resources.asset.action.download')}>
                            <Button
                                href={`${DOWNLOAD_URL()}/c/o/${record.id}${normalType}`}
                                download={record.id}
                            >
                                <DownloadIcon />
                            </Button>

                        </Tooltip>
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
});

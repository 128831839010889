import React from 'react';
import {
    FormControl,
    FormControlLabel,
    FormLabel, Grid,
    Input,
    Paper,
    Radio,
    RadioGroup,
} from '@material-ui/core';
import {translate} from 'react-admin';

const styles = {
    root: {
        marginTop: 15,
        marginBottom: 8,
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 8,
        paddingRight: 8
    }
};

export const EpgEpisodeSearch = translate(({translate, setFilters, filterValues}) => {
    return <>
        <Paper style={styles.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormControl fullWidth={true}>
                        <Input
                            value={filterValues.subtitle}
                            onChange={(event) => setFilters({
                                subtitle: event.target.value,
                                subcategory: filterValues.subcategory,
                                id: filterValues.id,
                                isHighlight: filterValues.isHighlight
                            })}
                            id="subtitle"
                            placeholder={'Subtitle'}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth={true}>
                        <Input
                            value={filterValues.subcategory}
                            onChange={(event) => setFilters({
                                subtitle: filterValues.subtitle,
                                subcategory: event.target.value,
                                id: filterValues.id,
                                isHighlight: filterValues.isHighlight
                            })}
                            id="subcategory"
                            placeholder={'Subcategory'}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth={true}>
                        <Input
                            value={filterValues.id}
                            onChange={(event) => setFilters({
                                subtitle: filterValues.subtitle,
                                subcategory: filterValues.subcategory,
                                id: event.target.value,
                                isHighlight: filterValues.isHighlight
                            })}
                            id="episode-id"
                            placeholder={'Episode ID'}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl>
                        <FormLabel id="radio-buttons-group-highlight">is highlight</FormLabel>
                        <RadioGroup
                            defaultValue="all"
                            value={filterValues.isHighlight}
                            row
                            onChange={(event) => setFilters({
                                subtitle: filterValues.subtitle,
                                subcategory: filterValues.subcategory,
                                id: filterValues.id,
                                isHighlight: (event.target as HTMLInputElement).value
                            })}
                        >
                            <FormControlLabel
                                value="all"
                                control={<Radio/>}
                                label={'all'}
                            />
                            <FormControlLabel
                                value="true"
                                control={<Radio/>}
                                label={'true'}
                            />
                            <FormControlLabel
                                value="false"
                                control={<Radio/>}
                                label={'false'}
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
        </Paper>
    </>;
});

import React from 'react';
import {
    BooleanInput,
    DeleteButton,
    Edit,
    FormDataConsumer,
    NumberInput,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
} from 'react-admin';
import {Field} from 'react-final-form';
import TextInputThumbnail from '../TextInputThumbnail';
import {Card, CardContent} from '@material-ui/core';
import S1TVThumbnailContainer from '../../playlists/S1TVThumbnailContainer';

const CustomToolbar = props =>
    <Toolbar {...props}>
        <SaveButton/>
        {props.record.source === 'oz' ? <DeleteButton undoable={false}/> : null}
    </Toolbar>;

export const EpgEpisodeEdit = (props) => {
    return (
        <Edit {...props} >
            <SimpleForm redirect="edit" toolbar={<CustomToolbar />}>
                <NumberInput source="id" disabled fullWidth />

                <FormDataConsumer>
                    {formDataProps =>
                        <Field name="programId">
                            {() => <NumberInput source="programId" required disabled={'oz' !== formDataProps.record.source} fullWidth /> }
                        </Field>
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {formDataProps =>
                        <Field name="season">
                            {() => <NumberInput source="season" required disabled={'oz' !== formDataProps.record.source} fullWidth /> }
                        </Field>
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {formDataProps =>
                        <Field name="number">
                            {() => <NumberInput source="number" required disabled={'oz' !== formDataProps.record.source} fullWidth /> }
                        </Field>
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {formDataProps =>
                        <Field name="serienId">
                            {() => <TextInput source="serienId" disabled={'oz' !== formDataProps.record.source} fullWidth /> }
                        </Field>
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {formDataProps =>
                        <Field name="materialId">
                            {() => <TextInput source="materialId" disabled={'oz' !== formDataProps.record.source} fullWidth /> }
                        </Field>
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {formDataProps =>
                        <Field name="description">
                            {() => <TextInput source="description" disabled={'oz' !== formDataProps.record.source} fullWidth /> }
                        </Field>
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {formDataProps =>
                        <Field name="longDescription">
                            {() => <TextInput source="longDescription" disabled={'oz' !== formDataProps.record.source} fullWidth /> }
                        </Field>
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {formDataProps =>
                        <Field name="duration">
                            {() => <NumberInput source="duration" disabled={'oz' !== formDataProps.record.source} fullWidth /> }
                        </Field>
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {formDataProps =>
                        <Field name="subcategory">
                            {() => <TextInput source="subcategory" disabled={'oz' !== formDataProps.record.source} fullWidth /> }
                        </Field>
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {formDataProps =>
                        <Field name="subtitle">
                            {() => <TextInput source="subtitle" disabled={'oz' !== formDataProps.record.source} fullWidth /> }
                        </Field>
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {formDataProps =>
                        <Field name="fsk">
                            {() => <TextInput source="fsk" disabled={'oz' !== formDataProps.record.source} fullWidth /> }
                        </Field>
                    }
                </FormDataConsumer>

                <TextInput source="source" disabled fullWidth />
                <FormDataConsumer>
                    {formDataProps => <>
                        <Field name="transformationId">
                            {props => (
                                <TextInputThumbnail
                                    fullWidth
                                    onChange={props.input.onChange}
                                    source="transformationId"
                                    {...formDataProps}
                                />
                            )}
                        </Field>
                    </>}
                </FormDataConsumer>
                <NumberInput source="tagId" fullWidth />
                <TextInput source="vodVideoId" fullWidth />
                <BooleanInput source="isHighlight" required />
                <FormDataConsumer>
                    {formDataProps => <>
                        <Field name="assetTransformations">
                            {props => (
                                <>
                                    <Card>
                                        <CardContent>SP1+ navigation asset to EPG-Episode</CardContent>
                                        <S1TVThumbnailContainer
                                            targetComponentName={'Eventstreams'}
                                            thumbnailTitle={'Add navigation asset to episode'}
                                            transformations={props.input.value}
                                            assetTransformations={props.input.value}
                                            onChange={props.input.onChange}
                                            type={'landscape'}
                                            platform={'sport1tv'}
                                            {...formDataProps}
                                        />
                                    </Card>
                                    <Card>
                                        <CardContent>SP1+ cover asset to EPG-Episode</CardContent>
                                        <S1TVThumbnailContainer
                                            targetComponentName={'Eventstreams'}
                                            thumbnailTitle={'Add cover asset to program'}
                                            transformations={props.input.value}
                                            assetTransformations={props.input.value}
                                            onChange={props.input.onChange}
                                            type={'hero'}
                                            platform={'sport1tv'}
                                            {...formDataProps}
                                        />
                                    </Card>
                                </>
                            )}
                        </Field>
                    </>}
                </FormDataConsumer>
            </SimpleForm>
        </Edit>
    );
};


export default EpgEpisodeEdit;

import React from 'react';
import { ImagoImagesInterface } from '../../types';
import { Popover, Typography } from '@material-ui/core';

interface ImagoImagePreviewProps {
  image: ImagoImagesInterface;
  anchorEl;
}

export const ImagoImagePreview: React.FC<ImagoImagePreviewProps> = ({
    anchorEl,
    image
}): React.ReactElement => {
    return (
        <Popover
            style={{ pointerEvents: 'none', maxWidth: 600 }}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
            }}
        >
            <div>
                <img alt="imago preview" src={image.previewURL} />
            </div>
            <Typography variant={'subtitle1'}>{image.caption}</Typography>
        </Popover>
    );
};

import React, { useState } from 'react';
import {TextInput, translate, setListSelectedIds as setSelectedIds} from 'react-admin';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import EpgAddThumbnailDialog from './EpgAddThumbnailDialog';
import { Transformation } from '../transformations/types/Transformation';

interface Props {
  fullWidth?: boolean;
  onChange: Function;
  record?;
  setSelectedIds;
  source: string;
  translate: Function;
  basePath: string;
}

const useStyles = makeStyles(() =>
    createStyles({
        button: {
            position: 'absolute',
            right: 0,
        },
        root: {
            position: 'relative',
        },
    }),
);

const get = (record, path) => {
    if (!path || !record) {
        return undefined;
    }
    if (record.hasOwnProperty(path)) {
        return record[path];
    }
    const slugs = path.split('.');
    const first = slugs.shift();
    if (record.hasOwnProperty(first)) {
        return get(record[first], slugs.join('.'));
    }
    return undefined;
};

const mapStateToProps = (state) => ({
    selectedIds: state.admin.resources.asset.list.selectedIds,
    isLoading: state.admin.loading > 0,
});

export default translate(connect(mapStateToProps, {setSelectedIds})((props: Props) => {
    const classes = useStyles();
    const [openDialog, setOpenDialog] = useState(false);
    const transformationIDFromRecord = get(props.record, props.source);
    const [transformationID, setTransformationID] = useState(transformationIDFromRecord);

    const handleAddTransformationToEventstream = (transformation: Transformation) => {
        handleChange(transformation.id);
        setOpenDialog(false);
    };

    const handleChange = (tID: any) => {
        setTransformationID(tID);
        props.onChange(tID);
    };

    const addThumbnailsToEpg = (tID: any) => {
        handleChange(tID);
        setOpenDialog(false);
    };

    return <div className={classes.root}>
        <TextInput
            id="textInputThumbnail"
            fullWidth={props.fullWidth}
            onChange={(event) => handleChange(event.target.value || '')}
            source={props.source}
            label={props.translate('resources.epg.episode.transformationId')}
            value={
                undefined === transformationID ?
                    (transformationIDFromRecord === undefined ? '' : transformationIDFromRecord)
                    : transformationID}
        />

        <IconButton
            className={classes.button}
            onClick={() => setOpenDialog(true)}
        >
            <SearchIcon />
        </IconButton>

        <EpgAddThumbnailDialog
            addThumbnailsToEpg={addThumbnailsToEpg}
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            handleAddTransformationToEventstream={props.basePath === '/eventstream' ? handleAddTransformationToEventstream : null}
            {...props}
        />
    </div>;
}));

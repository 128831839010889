import React, { useEffect, useState } from 'react';
import {
    FormControl,
    Grid,
    Input,
    Paper,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import { translate } from 'react-admin';
import { Tooltip } from '@material-ui/core';

interface SearchParamsType {
  id: string;
  userID: string;
  changedBy: string;
  resource: string;
  resourceID: string;
  changedOn: any;
}

const styles = theme => ({
    root: {
        marginTop: 15,
        marginBottom: 8,
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 8,
        paddingRight: 8
    },
    formControl: {
        margin: 0
    },
    searchField: {
        margin: 0,
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        padding: 2,
        paddingLeft: 5,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
        },
        '&:hover': {},
        '&:after': {
            transform: 'none',
            border: 'none'
        }
    },
    idField: {
        margin: `${theme.spacing.unit * 2}px 0 ${theme.spacing.unit}px 0`,
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        padding: 2,
        paddingLeft: 5,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
        },
        '&:hover': {},
        '&:after': {
            transform: 'none',
            border: 'none'
        }
    },
    toggleContainer: {
        padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: `${theme.spacing.unit}px 0`,
    },
    selectTime: {
        padding: `${theme.spacing.unit}px 0`,
        margin: `${theme.spacing.unit}px 0`,
    },
    selectTimeDivider: {
        padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: `${theme.spacing.unit}px 0`,
        background: theme.palette.background.default
    },
    button: {
        margin: 0,
        marginTop: 16,
        marginBottom: 8
    },
    deleteContainer: {
        flexBasis: 0
    },
});

type Props = {
  classes?: any;
  filterValues: any;
  initialOpen: boolean;
  onChange?: Function;
  setFilters: any;
  defaultResourceID: string,
  translate?: any;
  type: string;
};

export default translate(withStyles(styles)(({
    classes,
    filterValues,
    initialOpen,
    onChange,
    setFilters,
    translate,
    type,
}: Props) => {
    const initialSearchParams: SearchParamsType = {
        id: filterValues.id || '',
        userID: filterValues.userID || '',
        changedBy: filterValues.changedBy || '',
        resource: filterValues.resource || '',
        resourceID: filterValues.resourceID || '',
        changedOn: filterValues.changedOn || ''
    };

    const [searchParams, setSearchParams] = useState(initialSearchParams);

    const handleChange = (element, value) => {
        let searchParamsCloned = Object.assign({}, searchParams);
        searchParamsCloned[element] = value;
        setSearchParams(searchParamsCloned);
        if (onChange) {
            onChange();
        }
    };

    const resetFilters = () => {
        const resetSearchParams: SearchParamsType = {
            id: '',
            userID: '',
            changedBy: '',
            resource: '',
            resourceID: '',
            changedOn: ''
        };
        setSearchParams(resetSearchParams);
        if (onChange) {
            onChange();
        }
    };

    useEffect(() => {
        setFilters({
            id: searchParams.id,
            userID: searchParams.userID,
            changedBy: searchParams.changedBy,
            resource: searchParams.resource,
            resourceID: searchParams.resourceID,
            changedOn: searchParams.changedOn,
        });
    // eslint-disable-next-line
  }, [searchParams]);

    return (
        <Paper square={true} id="SearchPanelNew" className={classes.root} elevation={2}>
            <Grid container spacing={1}>
                <Grid item xs={5}>
                    <FormControl fullWidth={true} classes={classes.formControl}>
                        <Input
                            value={searchParams.changedBy}
                            onChange={(event) => handleChange('changedBy', event.target.value)}
                            id="changedBy"
                            className={classes.idField}
                            placeholder={translate('changedBy')}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={5}>
                    <FormControl fullWidth={true} classes={classes.formControl}>
                        <Input
                            value={searchParams.resource}
                            onChange={(event) => handleChange('resource', event.target.value)}
                            id="resource"
                            className={classes.idField}
                            placeholder={translate('Resource')}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={5}>
                    <FormControl fullWidth={true} classes={classes.formControl}>
                        <Input
                            value={searchParams.resourceID}
                            onChange={(event) => handleChange('resourceID', event.target.value)}
                            id="resourceID"
                            className={classes.idField}
                            placeholder={translate('ResourceID')}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs id="selectTimeUploaded">
                    <Grid container>
                        <Grid item xs={2}>
                            <div className={classes.toggleContainer}>
                                <Tooltip title={translate('Reset')}>
                                    <IconButton
                                        onClick={resetFilters}
                                        arial-label={translate('Reset')}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
}));

import React from 'react';
import {
    SortableContainer,
    SortableElement,
} from 'react-sortable-hoc';
import {
    Grid,
    IconButton,
    Input,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import CheckBox from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import {
    EditButton,
    translate,
    withDataProvider,
} from 'react-admin';
import arrayMove from 'array-move';
import sortVideos from '../../utils/sortVideos';
import VideoThumbnail from '../videos/VideoThumbnail';

const Pin = ({
    translate,
    update,
    video,
}) => {
    const check = video.isAutofilled
        ? <CheckBoxOutlineBlank />
        : <CheckBox />;

    const autoFilled = (autoFilled: boolean) => {
        video.isAutofilled = !autoFilled;

        if (autoFilled) {
            video.position = Number.MAX_SAFE_INTEGER;
        } else {
            delete video.position;
        }

        update();
    };

    return <Grid item xs={3}>
        <IconButton
            tabIndex={-1}
            title={translate('resources.playlist.label.unpin')}
            aria-label="Delete"
            color="default"
            onClick={() => {
                autoFilled(video.isAutofilled);
            }}
        >
            {check}
        </IconButton>
    </Grid>;
};

const Video = SortableElement(({
    position,
    translate,
    update,
    video,
}) => {
    return (
        <TableRow>
            <TableCell>
                <Pin
                    update={update}
                    translate={translate}
                    video={video}
                />
            </TableCell>
            <TableCell>
                <VideoThumbnail
                    height={48}
                    record={video}
                />
            </TableCell>
            <TableCell>{video.title}</TableCell>
            <TableCell>
                <Input
                    onBlur={(event) => {
                        if (!event  || !event.target  || !event.target.value) {
                            return;
                        }

                        const n = Number(event.target.value) - 1;
                        event.target.value = '';

                        // quatsch input is ignored
                        if (Number.isNaN(n) || n < 0) {
                            return;
                        }

                        if (n !== position) {
                            // the direction indicates if the video is sorted up or
                            // down; so we know what to do with the video formerly on
                            // that position, see sortVideos
                            video.direction = video.isAutofilled ? -1 : n - position;
                            video.position = n;
                            video.isAutofilled = false;
                            update();
                        }
                    }}
                    placeholder={position !== undefined && position + 1}
                />
            </TableCell>
            <TableCell>
                <EditButton
                    basePath="/video"
                    record={video}
                />
            </TableCell>
        </TableRow>
    );
});

const Videos = SortableContainer(({
    translate,
    update,
    videos,
}) => {
    return (
        <TableBody>
            {videos.map((video, index) =>
                <Video
                    update={update}
                    index={index}
                    position={!video.isAutofilled ? index : undefined}
                    translate={translate}
                    video={video}
                />)}
        </TableBody>
    );
});

export default withDataProvider(translate(({
    formData,
    onChange,
    record,
    translate,
    videos,
}) => {
    if (!videos) {
        return null;
    }

    if (!formData.autoFill) {
        videos = videos.filter((video) => !video.isAutofilled);
    }

    if (!videos.length) {
        return null;
    }

    const update = () => onChange(sortVideos(videos));

    videos = sortVideos(videos);

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Pinned</TableCell>
                    <TableCell></TableCell>
                    <TableCell>{translate('resources.video.fields.title')}</TableCell>
                    <TableCell>Position</TableCell>
                    <TableCell>{translate('Edit')}</TableCell>
                </TableRow>
            </TableHead>

            <Videos
                axis="xy"
                update={update}
                onSortEnd={({oldIndex, newIndex}) => {
                    videos = arrayMove(videos, oldIndex, newIndex);

                    // pin all videos up to newIndex
                    videos.some((video: any, position) => {
                        video.isAutofilled = false;
                        video.position = position;
                        return position === newIndex;
                    });

                    update();
                }}
                pressDelay={200}
                translate={translate}
                videos={videos}
            />
        </Table>
    );
}));

import React from 'react';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-admin';
import { CardContent, Grid, Typography, Avatar, Button } from '@material-ui/core';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';

const styles = {
    main: {
        flex: '1',
    },
    root: {
        height: '100%',
        backgroundColor: 'white',
    },
    content: {
        alignItems: 'center',
        display: 'flex'
    },
    avatar: {
        backgroundColor: 'blue',
        height: 56,
        width: 56
    },
    difference: {
        marginTop: 20,
        display: 'flex',
        alignItems: 'center'
    },
    differenceButton: {
        marginLeft: -10,
    },
    differenceIcon: {
        color: 'blue',
        marginRight: 20
    },
    differenceValue: {
        color: 'blue',
    }
};

const StatsCounter = ({ mainText, mainStat, mainImage, secondaryText, secondaryStat, translate, classes }) => (
    <CardContent className={classes.root}>
        <Grid
            container
            justify="space-between"
        >
            <Grid item>
                <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                >
                    {mainText}
                </Typography>
                <Typography variant="h3">{mainStat}</Typography>
            </Grid>
            <Grid item>
                <Avatar className={classes.avatar}>
                    {mainImage}
                </Avatar>
            </Grid>
        </Grid>
        {secondaryText && (
            <div className={classes.difference}>
                <Button
                    color="primary"
                    size="small"
                    variant="text"
                    className={classes.differenceButton}
                >
                    <TrendingUpIcon className={classes.differenceIcon} />
                    <Typography
                        className={classes.differenceValue}
                        variant="body2"
                    >
                        {secondaryStat}
                    </Typography>
                </Button>
                <Typography
                    className={classes.caption}
                    variant="caption"
                >
                    {secondaryText}
                </Typography>
            </div>
        )}
    </CardContent>
);

const enhance = compose(
    withStyles(styles),
    translate
);

export default enhance(StatsCounter);

import { InitialFilters } from './InitialFilters';
import {
    Brightness,
    Contrast, Focus,
    GrayScale,
    InterfaceFilters,
    Noise,
    Quality,
    RoundCorner,
    Saturation
} from '../types';
import { Transformation } from '../components/transformations/types/Transformation';
import transformOptions from './transformOptions';
import { ConvertAssetTransformationsToPrimitives } from './converAssetTransformations';

export const ConvertCreateTransformationResponseToObject = (
    json: Transformation
): void => {
    json.filtersUpdate = Object.assign(
        {},
        mapResponseTransformationsToFiltersObject(json)
    );
};

export const ConvertGetTransformationListResponseToTransformationObject = (
    json: Transformation[]
): void => {
    json.forEach((transformation: Transformation) => {
        transformation.filtersUpdate = Object.assign(
            {},
            mapResponseTransformationsToFiltersObject(transformation)
        );
    });
};

export const ConvertVideo = (video): void => {
    if (video.countries) {
        video.countries = video.countries.map((country) => country.code);
    }

    if (video.episode) {
        video.episode = transformOptions.episodeToOption(video.episode);
    }

    ConvertAssetTransformationsToPrimitives(video);
};

const mapResponseTransformationsToFiltersObject = (
    transformation: Transformation
): InterfaceFilters => {
    const filterValues = (transformation.parameters !== undefined && transformation.parameters.filters !== undefined) ? transformation.parameters.filters : [];
    let initialFiltersClone = { ...InitialFilters };

    Object.assign(initialFiltersClone, {
        Smart: (transformation.parameters !== undefined &&  transformation.parameters.smart !== undefined) ? transformation.parameters.smart : false
    });

    filterValues.forEach(filterValue => {
        let matched = filterValue.match(
            '^(\\w+)\\(([-+]?[0-9]*\\.?[0-9]*\\w*)\\)$'
        );

        //round_corner(30,0,0,0)
        if (!matched) {
            matched = filterValue.match('^(\\w+)\\((\\d+),0,0,0\\)$');
        }
        //focus(10,50)
        if (!matched) {
            matched = filterValue.match('^(\\w+)\\((\\d+),(\\d+)\\)$');
        }

        if (matched) {
            switch (matched[1]) {

            case 'brightness': {
                Object.assign(initialFiltersClone, {
                    Brightness: new Brightness(Number(matched[2]))
                });
                break;
            }

            case 'contrast': {
                Object.assign(initialFiltersClone, {
                    Contrast: new Contrast(Number(matched[2]))
                });
                break;
            }

            case 'saturation': {
                Object.assign(initialFiltersClone, {
                    Saturation: new Saturation(Number(matched[2]))
                });
                break;
            }

            case 'quality': {
                Object.assign(initialFiltersClone, {
                    Quality: new Quality(Number(matched[2]))
                });
                break;
            }

            case 'noise': {
                Object.assign(initialFiltersClone, {
                    Noise: new Noise(Number(matched[2]))
                });
                break;
            }

            case 'round_corner': {
                Object.assign(initialFiltersClone, {
                    Round_Corner: new RoundCorner(Number(matched[2]))
                });
                break;
            }

            case 'grayscale': {
                Object.assign(initialFiltersClone, {
                    Grayscale: new GrayScale(true)
                });
                break;
            }

            case 'redeye': {
                Object.assign(initialFiltersClone, {
                    RedEye: true
                });
                break;
            }

            case 'focus': {
                Object.assign(initialFiltersClone, {
                    Focus: new Focus({active: true, leftPercentage: Number(matched[2]), topPercentage: Number(matched[3])})
                }, {
                    ShowGrid: true
                });
            }
            }
        }
    });

    return initialFiltersClone;
};

import CreatableSelect from 'react-select/async-creatable';
import React from 'react';
import {
    GET_LIST,
    translate,
    withDataProvider
} from 'react-admin';
import transformOptions from '../utils/transformOptions';

export default withDataProvider(translate((props) => {
    const {
        dataProvider,
        episode,
        onChange,
        translate,
    } = props;

    const loadOptions = (text) =>
        dataProvider(GET_LIST, 'episode', {pagination: {}, text})
            .then((response) => transformOptions.episodesToOptions(response.data));

    return (
        <>
            <CreatableSelect
                formatCreateLabel={(value) => translate('mam.create_episode', {value})}
                isMulti={false}
                loadOptions={loadOptions}
                menuPortalTarget={document.body}
                noOptionsMessage={() => translate('mam.no_results')}
                onChange={onChange}
                placeholder={translate('Episode')}
                resettable={true}
                value={episode}
            />
        </>
    );
}));

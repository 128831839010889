import React, { useEffect, useState } from 'react';
import {
    AppBar,
    Dialog,
    DialogContent,
    IconButton,
    Tab,
    Tabs,
    Toolbar,
    Typography,
    Snackbar,
    SnackbarContent
} from '@material-ui/core';
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import CloseIcon from '@material-ui/icons/Close';
import ImageIcon from '@material-ui/icons/Image';
import AssetCreate from './assets/AssetCreate';
import { withStyles } from '@material-ui/core/styles';
import { withDataProvider, translate, getNotification } from 'react-admin';
import { GettyUpload } from './getty/GettyUpload';
import { ImagoUpload } from './imago/ImagoUpload';
import { connect } from 'react-redux';
import ErrorIcon from '@material-ui/icons/Error';
import { withRouter } from 'react-router-dom';
import { GETTY_ACTIVE } from '../utils/config';

const styles = theme => {
    return {
        Toolbar: {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.secondary.main
        },

        SnackbarError: {
            backgroundColor: theme.palette.error.dark
        }
    };
};

const mapStateToProps = state => ({
    notification: getNotification(state)
});

export const UploadDialog = withDataProvider(
    translate(
        withStyles(styles)(
            withRouter(
                connect(
                    mapStateToProps,
                    {}
                )(
                    (props: {
            handleOpen;
            open;
            classes;
            dataProvider;
            dispatch;
            translate;
            notification;
            location;
          }) => {
                        const {
                            handleOpen,
                            open,
                            classes,
                            dataProvider,
                            dispatch,
                            translate,
                            notification,
                            ...rest
                        } = props;
                        const [currentTab, setCurrentTab] = useState(0);
                        const [galleryId, setGalleryId] = useState<string | null>();
                        const [videoId, setVideoId] = useState<string | null>();
                        const [eventstreamId, setEventstreamId] = useState<string | null>(null);
                        const [isFilePath, setIsFilePath] = useState<boolean>();

                        const handleChangeTab = (event, value) => {
                            setCurrentTab(value);
                        };

                        useEffect(() => {
                            const location: { pathname: string } = props.location;
                            const match = location.pathname.match(
                                /^(\/gallery\/)(?<galleryID>[-a-z0-9]*)$/i
                            );
                            const groups = match ? match.groups : null;
                            setGalleryId(groups ? groups.galleryID : null);
                            const videoMatch = location.pathname.match(
                                /^(\/video\/)(?<videoID>[-a-z0-9]*)\/?.*?/i
                            );
                            const videoGroups = videoMatch ? videoMatch.groups : null;
                            setVideoId(videoGroups ? videoGroups.videoID : null);
                            const eventstreamMatch = location.pathname.match(
                                /^(\/eventstream\/)(?<eventstreamID>[-a-z0-9]*)\/?.*?/i
                            );
                            const eventstreamGroups = eventstreamMatch ? eventstreamMatch.groups : null;
                            setEventstreamId(eventstreamGroups ? eventstreamGroups.eventstreamID : null);

                            const fileMatch = location.pathname.match(
                                /^(\/file\/?)([-a-z0-9]*)\/?.*?/i
                            );
                            if (fileMatch !== null) {
                                setIsFilePath(true);
                            } else {
                                setIsFilePath(false);
                            }
                        }, [props.location]);

                        const handleEventstream = (transformationId: string) => {
                            const textInputThumbnailEl = document.getElementById('textInputThumbnail') as HTMLInputElement;
                            const nativeInputValueSetter = Object.getOwnPropertyDescriptor(HTMLInputElement.prototype, 'value')!.set;
              nativeInputValueSetter!.call(textInputThumbnailEl, transformationId);
              const inputEvent = new Event('input', {bubbles: true});
              textInputThumbnailEl.dispatchEvent(inputEvent);
                        };

                        return (
                            <Dialog
                                fullWidth={true}
                                maxWidth={false}
                                classes={classes}
                                open={open || false}
                            >
                                <AppBar
                                    position="static"
                                    color="default"
                                    className={classes.appBar}
                                >
                                    <Toolbar className={classes.Toolbar}>
                                        <IconButton
                                            color="inherit"
                                            onClick={() => {
                                                handleOpen(false);
                                            }}
                                            aria-label={translate('mam.close')}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                        <Typography variant="h6" color="inherit">
                                            {translate('resources.asset.action.upload')}
                                        </Typography>
                                    </Toolbar>
                                    <Tabs
                                        onChange={handleChangeTab}
                                        value={currentTab}
                                        scrollButtons="on"
                                        indicatorColor="primary"
                                        textColor="primary"
                                    >
                                        {!isFilePath && (
                                            <Tab label="Imago" icon={<ImageIcon />} />
                                        )}
                                        <Tab
                                            label={translate(
                                                'resources.asset.action.upload_from_disc'
                                            )}
                                            icon={<DesktopMacIcon />}
                                        />
                                        {GETTY_ACTIVE && !isFilePath && (
                                            <Tab label="Getty Images" icon={<ImageIcon />} />
                                        )}
                                    </Tabs>
                                </AppBar>
                                <DialogContent>
                                    {currentTab === 0 && !isFilePath && (
                                        <>
                                            <ImagoUpload
                                                handleOpen={handleOpen}
                                                galleryId={galleryId}
                                                videoId={videoId}
                                                eventstreamId={eventstreamId}
                                                handleEventstream={handleEventstream}
                                            />
                                        </>
                                    )}
                                    {(currentTab === 1 || isFilePath) && (
                                        <AssetCreate
                                            basePath={isFilePath ? '/file' : '/asset'}
                                            // @ts-ignore
                                            location={{ pathName: '/asset/create' }}
                                            resource="asset"
                                            handleOpen={handleOpen}
                                            galleryId={galleryId}
                                            videoId={videoId}
                                            eventstreamId={eventstreamId}
                                            handleEventstream={handleEventstream}
                                            {...rest}
                                        />
                                    )}
                                    {currentTab === 2 && GETTY_ACTIVE && !isFilePath && (
                                        <>
                                            <GettyUpload
                                                handleOpen={handleOpen}
                                                galleryId={galleryId}
                                                videoId={videoId}
                                            />
                                        </>
                                    )}
                                    <Snackbar open={notification}>
                                        <SnackbarContent
                                            className={classes.SnackbarError}
                                            message={
                                                <span
                                                    style={{ display: 'flex', alignItems: 'center' }}
                                                    id="client-snackbar"
                                                >
                                                    <ErrorIcon />
                                                    {notification ? translate(notification.message) : ' '}
                                                </span>
                                            }
                                        />
                                    </Snackbar>
                                </DialogContent>
                            </Dialog>
                        );
                    }
                )
            )
        )
    )
);

import { httpRequestObject, OptionsInterface } from './httpRequestObjects';

const convertDataToGettyRequest = (
    type,
    params,
    apiUrl,
    options: OptionsInterface
): httpRequestObject => {
    let url;
    const resource = 'getty';

    switch (type) {
    case 'UPLOAD': {
        url = encodeURI(`${apiUrl}/asset/${resource}/upload/${params.id}`);
        options.method = 'POST';
        break;
    }
    default:
        url = encodeURI(
            `${apiUrl}/asset/${resource}/search/${encodeURI(params.keyword)}?page=${params.page}&per=10`
        );
        options.method = 'POST';
    }

    return { url, options };
};

export default convertDataToGettyRequest;

import React, { useEffect, useState } from 'react';
import AssetEdit from './AssetEdit';
import { GET_ONE, withDataProvider } from 'react-admin';
import { ConvertCreateTransformationResponseToObject } from '../../utils/httpResponseObject';
import { Transformation } from '../transformations/types/Transformation';

export const AssetEditWithTransformation = withDataProvider(props => {
    const { dataProvider, match } = props;
    const [assetId, setAssetId] = useState(match.params.id);
    const [transformation, setTransformation] = useState<undefined | Transformation>();
    const [fetched, setFetched] = useState(false);

    useEffect(() => {
        if (!fetched) {
            dataProvider(GET_ONE, 'transformation', {
                id: match.params.transformationId || match.params.id
            })
                .then(response => {
                    let transformation: Transformation = response.data;
                    ConvertCreateTransformationResponseToObject(transformation);
                    setAssetId(transformation.assetID);
                    setTransformation(transformation);
                    setFetched(true);
                })
                .catch(error => {
                    //no transformation found so /transformation/:id is assetId
                    setAssetId(match.params.id);
                    setTransformation(undefined);
                    setFetched(true);
                });
        }
    }, [dataProvider, fetched, match.params.id, match.params.transformationId, props.match.params.id]);

    return (
        <>
            {fetched && (
                <AssetEdit
                    {...props}
                    id={assetId}
                    transformation={transformation}
                    basePath={'/asset'}
                    resource={'asset'}
                />
            )}
        </>
    );
});

export const REACT_APP_API_URI_STAGING = process.env.REACT_APP_API_URI_STAGING || 'https://ng-outgest-edge-mam-deadpool-staging.boom.sport1.it/api/v1';
export const REACT_APP_API_URI_PRODUCTION = process.env.REACT_APP_API_URI_PRODUCTION || 'https://ng-outgest-edge-mam-deadpool.badaboom.sport1.it/api/v1';
export const REACT_APP_DOWNLOAD_URL_STAGING = process.env.REACT_APP_DOWNLOAD_URL_STAGING || 'https://stage-reshape.sport1.de';
export const REACT_APP_DOWNLOAD_URL_PRODUCTION = process.env.REACT_APP_DOWNLOAD_URL_PRODUCTION || 'https://reshape.sport1.de';
export const REACT_APP_KEYCLOAK_URL_STAGING = process.env.REACT_APP_KEYCLOAK_URL_STAGING || 'https://keycloak-stage.sport1dev.de/auth';
export const REACT_APP_KEYCLOAK_URL_PRODUCTION = process.env.REACT_APP_KEYCLOAK_URL_PRODUCTION || 'https://keycloak.sport1dev.de/auth';

export const API_URL = () => /review|local|stage/.test(window.location.hostname) ? REACT_APP_API_URI_STAGING : REACT_APP_API_URI_PRODUCTION;
export const DOWNLOAD_URL = () => /review|local|stage/.test(window.location.hostname) ? REACT_APP_DOWNLOAD_URL_STAGING : REACT_APP_DOWNLOAD_URL_PRODUCTION;
export const KEYCLOAK_URL = () => /review|local|stage/.test(window.location.hostname) ? REACT_APP_KEYCLOAK_URL_STAGING : REACT_APP_KEYCLOAK_URL_PRODUCTION;

export const KEYCLOAK_REALM = 'Azure';
export const KEYCLOAK_CLIENT_ID = 'account';

export const DELAY = 1000;
export const AUTOSAVE_DELAY = 1000;

export const ROLES = {
    admin: { id: 0, name: 'admin' },
    moderator: { id: 1, name: 'moderator' },
    standard: { id: 2, name: 'standard' },
    assetRW: { id: 3, name: 'assetRW' },
    assetRO: { id: 4, name: 'assetRO' },
    ottRW: { id: 5, name: 'ottRW' },
    ottRO: { id: 6, name: 'ottRO' },
    videoAdmin: { id: 7, name: 'videoadmin' },
    videoStandard: { id: 8, name: 'videoStandard' },
    videoEditor: { id: 9, name: 'videoEditor' },
    superEditor: { id: 10, name: 'superEditor'}
};

export const ROLES_BY_ID: Record<string, {id: number, key: string, name: string} | undefined> = Object.entries(ROLES)
    .map(([key, value]) => {
        return {key, ...value};
    })
    .reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
    }, {});

export const GETTY_ACTIVE = false;

const compare = (a, b) => {
    if (Boolean(a.isAutofilled) !== Boolean(b.isAutofilled)) {
        return a.isAutofilled
            ? 1
            : -1;
    }

    // we know now: both videos are either pinned or autofilled

    if (a.isAutofilled) {
        return b.createdAt - a.createdAt;
    }

    // we know now: both videos are pinned

    // if two pinned videos have the same position, check which one
    // has a direction, see PlaylistVideos
    if (a.position === b.position) {
        return a.direction || (-1 * b.direction);
    }

    return a.position - b.position;
};

const prepare = (video, position) => {
    if (undefined === video.position) {
        video.position = position;
    }
    return video;
};

const clean = (video, position) => {
    delete video.direction;
    if (video.isAutofilled) {
        delete video.position;
    } else {
        video.position = position;
    }
    return video;
};

export default (videos) =>
    Array
        .from(videos)
        .map(prepare)
        .sort(compare)
        .map(clean);

import React from 'react';
import {
    Datagrid,
    EditButton,
    List,
    TextField,
} from 'react-admin'; // eslint-disable-line import/no-unresolved

const EpgCategoryList = props =>
    <List bulkActionButtons={false} {...props} >
        <Datagrid>
            <TextField sortable={false} source="id" />
            <TextField sortable={false} source="name"  />
            <TextField sortable={false} source="transformationId" />
            <EditButton />
        </Datagrid>
    </List>;

export default EpgCategoryList;

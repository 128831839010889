import React, { useState } from 'react';
import {
    Button,
    createStyles,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
    WithStyles,
    withStyles,
} from '@material-ui/core';
import {
    BooleanInput,
    DateTimeInput,
    DeleteButton,
    Edit,
    FormDataConsumer,
    FormTab,
    SelectArrayInput,
    SelectInput,
    TabbedForm,
    TextInput,
    Toolbar,
    TopToolbar,
    translate,
    UPDATE,
    useNotify,
    useRefresh,
    withDataProvider,
} from 'react-admin';
import { Field, useFormState } from 'react-final-form';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Tooltip from '@material-ui/core/Tooltip';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Restore from '@material-ui/icons/Restore';
import {
    countries as countriesFromFile,
    competition as competitionFromFile,
    source as sourceFromFile,
    programme as programmeFromFile,
    sportArt as sportArtFromFile,
    content as contentFromFile,
    ageRating as ageRatingFromFile
} from './select_data';
import { TextInputCharactersLimit } from './TextInputCharactersLimit';
import TagWrapper from '../TagWrapper';
import EpisodeWrapper from '../EpisodeWrapper';
import VideoThumbnails from './VideoThumbnails';
import VideoTransformations from './VideoTransformations';
import VideoUpload from './VideoUpload';
import AutoSave from '../../utils/AutoSave';
import Deleted from '../../utils/Deleted';
import { BackButton } from '../buttons/BackButton';
import { ROLES } from '../../utils/config';
import QRCode from 'qrcode.react';
import { VideoTransformationInterface } from '../../types';
import { useEditController } from 'ra-core';
import TextInputMatchId from './TextInputMatchId';
import slugify from '../../utils/slugify';
import VideoCloneButton from './VideoCloneButton';
import ForceUpdate from '../../utils/ForceUpdate';

const styles = createStyles({
});

interface Props extends WithStyles<typeof styles> {
    dataProvider;
    history;
    id: string;
    permissions;
    translate: CallableFunction;
    basePath: string;
    resource: string;
}

const ShowInput = props => {
    const notify = useNotify();
    const { values } = useFormState();

    const competitionShow = {
        Bundesliga: { id: 'Bundesliga pur', name: 'Bundesliga pur' },
    };

    const competition = values.tracking && values.tracking.value && values.tracking.value.competition;
    const matchId = values.tracking && values.tracking.value && values.tracking.value.matchId;
    const show = values.tracking && values.tracking.value && values.tracking.value.show;

    let choices = props.choices;
    let defaultValue = props.defaultValue;

    if (!show && competitionShow[competition] && matchId) {
        choices = [competitionShow[competition]];
        defaultValue = competitionShow[competition].id;
        values.tracking.value.show = defaultValue;
        notify(props.translate('resources.video.notification.tracking_value_show_fallback', { competition, show: competitionShow[competition].name }));
    }

    return <SelectInput
        choices={choices}
        defaultValue={defaultValue}
        {...props}
    />;
};

const VideoEdit: React.FC<Props> = (props: Props) => {
    const {
        dataProvider,
        id,
        permissions,
        translate,
    } = props;

    const [loading, setLoading] = useState(true);
    const [copied, setCopied] = useState(false);
    const [previewVideo, setPreviewVideo] = useState<VideoTransformationInterface | undefined>();

    const notify = useNotify();
    const refresh = useRefresh();
    const controllerProps = useEditController(props);

    const clearState = () => {
        setCopied(false);
        setPreviewVideo(undefined);
    };

    const handleChange = (video, isLoading = true) => {
        if (loading && isLoading) {
            return;
        }
        if (video.approved && !(video.tracking && (video.tracking.value.sport && video.tracking.value.source))) {
            notify('resources.video.notification.tracking_mandatory_fields_missing', 'warning');
            refresh();
            return;
        }

        dataProvider(UPDATE, 'video', { data: video, id: props.id })
            .then(() => {
                notify('resources.video.notification.updated');
            }).catch((...all) => {
                notify('resources.video.notification.update_failed');
                console.error(...all);
            });
    };

    const restore = (id) => {
        dataProvider('RESTORE', 'video', { id })
            .then(() => {
                notify('resources.video.notification.restored');
                refresh();
            })
            .catch((...all) => {
                notify('resources.video.notification.update_failed');
                console.error(...all);
            });
    };

    const CopyTool = record => (
        <span>
            {record.id}
            <Tooltip title={translate('mam.clipboard_copy', { object: 'id' })}>
                <CopyToClipboard text={record.id} onCopy={() => setCopied(true)}>
                    <IconButton style={{ padding: '8px' }}>
                        <FileCopyIcon style={{ fontSize: '17px' }} />
                    </IconButton>
                </CopyToClipboard>
            </Tooltip>
            <span style={{ fontSize: '15px', color: '#ccc' }}>{copied && translate('mam.clipboard_saved', { object: 'id' })}</span>
        </span>
    );

    const Title = props => {
        const record = props.record;
        return <span>Video: {record ? <CopyTool id={record.id} /> : ''}</span>;
    };

    const Removal = (props) => {
        if (![ROLES.admin.id.toString(), ROLES.videoAdmin.id.toString(), ROLES.videoEditor.id.toString(), ROLES.superEditor.id.toString()].includes(permissions)) {
            return null;
        }

        if (!props.record.deletedAt) {
            return <DeleteButton undoable={false} {...props} />;
        }

        return <Button
            onClick={() => restore(props.record.id)}
            color="default"
        >
            <Restore />
            Restore
        </Button>;
    };

    const VideoPreview = () => {
        if (!previewVideo || !previewVideo.streamingURL) {
            return null;
        }

        if (previewVideo.type.startsWith('video/')) {
            return <video
                controls={true}
                height={253}
                src={previewVideo.streamingURL}
                width={450}
            />;
        }

        if (previewVideo.type.startsWith('image/')) {
            return <img
                alt=""
                height={253}
                src={previewVideo.streamingURL}
                width={450}
            />;
        }

        return null;
    };

    const VideoEditActions = (props) => (
        <TopToolbar>
            <VideoCloneButton record={props.data} callbackFunction={clearState} />
        </TopToolbar>
    );

    return (
        <Grid container direction="row">
            <Grid item xs={12}>
                {controllerProps && controllerProps.record && (
                    <ExpansionPanel defaultExpanded={true}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant={'h6'}>Video Preview</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid container direction="row"
                                justify="space-between"
                                alignItems="center">
                                <Grid item><VideoPreview /></Grid>
                                <Grid item xs={12} sm container direction="row"
                                    justify="space-between"
                                    alignItems="center">
                                    <Grid item xs container direction="column" />
                                    <Grid item>
                                        <Typography gutterBottom variant="subtitle1">
                                            {translate('Website URL')}
                                        </Typography>
                                        <Typography variant="subtitle1"><a href={`https://www.sport1.de/tv-video/video/${slugify(controllerProps.record.title)}__${controllerProps.record.id}`} target="_none">SPORT1.de</a></Typography>
                                        <Typography gutterBottom variant="subtitle1">
                                            {translate('Mobile URL')}
                                        </Typography>
                                        <Typography variant="subtitle1"><QRCode value={`sport1://video/${id}`} /></Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                )}
            </Grid>

            <Grid item xs={12}>
                <Edit undoable={false} title={<Title />} actions={<VideoEditActions />} {...props}>
                    <TabbedForm
                        fullWidth
                        redirect={false}
                        toolbar={
                            <Toolbar {...props}>
                                <BackButton
                                    history={props.history}
                                    label={translate('ra.action.back')}
                                />
                                <Removal />
                                <ForceUpdate save={handleChange} />
                            </Toolbar>
                        }
                    >
                        <FormTab label={translate('Metadata')} >
                            <Deleted />

                            <FormDataConsumer>
                                {(data) => {
                                    // don't allow saving of a partially loaded video
                                    setLoading(!data.record.GOT_ONE_COMPLETELY);

                                    if (!previewVideo) {
                                        setPreviewVideo(((data.record && data.record.transformations) || [])
                                            .reduce(
                                                (transformation, current) =>
                                                    current.defaultTransformation
                                                        && current.size > transformation ? transformation.size : -Infinity
                                                            ? current
                                                            : transformation,
                                                undefined
                                            )
                                        );
                                    }
                                }}
                            </FormDataConsumer>

                            <AutoSave save={handleChange} />
                            {[ROLES.admin.id.toString(), ROLES.videoAdmin.id.toString(), ROLES.videoEditor.id.toString(), ROLES.superEditor.id.toString()].includes(permissions) ?
                                (<BooleanInput
                                    source="approved"
                                />) :
                                (<TextInput
                                    source="approved"
                                    disabled
                                />)
                            }

                            <TextInput
                                fullWidth
                                InputLabelProps={{ required: true }}
                                resettable
                                source="headlineEditorial"
                            />

                            <TextInputCharactersLimit
                                fullWidth
                                label={translate('resources.video.fields.title')}
                                maxLength={80}
                                resettable
                                source="title"
                            />

                            <TextInput
                                fullWidth
                                multiline
                                resettable
                                source="description"
                            />

                            <FormDataConsumer>
                                {formDataProps => <>
                                    <Field name="tags" >
                                        {props => (
                                            <TagWrapper
                                                selectedTags={props.input.value && props.input.value.map(tagEl => ({
                                                    ...tagEl,
                                                    label: tagEl.tag,
                                                    value: tagEl.id
                                                }))}
                                                onChange={props.input.onChange}
                                                permissions={permissions}
                                                {...formDataProps}
                                            />
                                        )}
                                    </Field>
                                </>}
                            </FormDataConsumer>

                            <FormDataConsumer>
                                {formDataProps => <>
                                    <Field name="tracking.value.matchId" >
                                        {props => (
                                            <TextInputMatchId
                                                fullWidth
                                                onChange={props.input.onChange}
                                                source="tracking.value.matchId"
                                                {...formDataProps}
                                            />
                                        )}
                                    </Field>
                                </>}
                            </FormDataConsumer>

                            <DateTimeInput
                                fullWidth
                                parse={(d) => Number(new Date(d))}
                                resettable
                                source="publishedAt"
                            />

                            <TextInput
                                fullWidth
                                resettable
                                source="copyright"
                            />

                            <SelectInput
                                choices={sportArtFromFile}
                                fullWidth
                                resettable
                                source="tracking.value.sport"
                                InputLabelProps={{ required: true }}
                            />

                            <SelectInput
                                choices={competitionFromFile}
                                fullWidth
                                resettable
                                source="tracking.value.competition"
                            />

                            <SelectInput
                                choices={contentFromFile}
                                fullWidth
                                resettable
                                source="tracking.value.content"
                            />

                            <SelectInput
                                choices={sourceFromFile}
                                fullWidth
                                resettable
                                source="tracking.value.source"
                                InputLabelProps={{ required: true }}
                            />

                            <FormDataConsumer>
                                {formDataProps => <>
                                    <Field name="tracking.value.show" >
                                        {props => (
                                            <ShowInput
                                                choices={programmeFromFile}
                                                fullWidth
                                                label={translate('resources.video.fields.tracking.value.show')}
                                                resettable
                                                source="tracking.value.show"
                                                translate={translate}
                                            />
                                        )}
                                    </Field>
                                </>}
                            </FormDataConsumer>

                            <TextInput
                                fullWidth
                                resettable
                                source="link.value.title"
                            />

                            <TextInput
                                fullWidth
                                resettable
                                source="link.value.href"
                            />

                            <TextInputCharactersLimit
                                fullWidth
                                label={translate('resources.video.fields.titleEnglish')}
                                maxLength={80}
                                resettable
                                source="titleEnglish"
                            />

                            <TextInput
                                label={translate('resources.video.fields.S1TVTitle')}
                                fullWidth
                                source="s1TVTitle"
                            />
                            <TextInput
                                label={translate('resources.video.fields.S1TVDescription')}
                                fullWidth
                                source="s1TVDescription"
                            />

                        </FormTab>

                        <FormTab label={translate('policies')} >
                            <Deleted />

                            <DateTimeInput
                                fullWidth
                                parse={(d) => Number(new Date(d))}
                                resettable
                                source="availableAt"
                            />

                            <DateTimeInput
                                fullWidth
                                parse={(d) => Number(new Date(d))}
                                resettable
                                source="expiresAt"
                            />

                            <SelectInput
                                choices={[
                                    { id: 'global', name: 'global' },
                                    { id: 'whitelist', name: 'whitelist' },
                                    { id: 'blacklist', name: 'blacklist' },
                                ]}
                                fullWidth
                                source="geoType"
                            />

                            <SelectArrayInput
                                choices={countriesFromFile}
                                fullWidth
                                resettable
                                source="countries"
                                optionValue="code"
                                optionText="nameEn"
                            />

                            <SelectInput
                                choices={ageRatingFromFile}
                                fullWidth
                                resettable
                                source="ageRating"
                            />

                            <SelectInput
                                choices={[
                                    { id: 'yes', name: translate('yes') },
                                    { id: 'no', name: translate('no') },
                                ]}
                                fullWidth
                                initialValue={'yes'}
                                source="vast.value.preroll"
                            />

                            <SelectInput
                                choices={[
                                    { id: 'yes', name: translate('yes') },
                                    { id: 'no', name: translate('no') },
                                ]}
                                fullWidth
                                initialValue={'yes'}
                                source="vast.value.midroll"
                            />

                            <SelectInput
                                choices={[
                                    { id: 'yes', name: translate('yes') },
                                    { id: 'no', name: translate('no') },
                                ]}
                                fullWidth
                                initialValue={'yes'}
                                source="vast.value.postroll"
                            />

                        </FormTab>

                        <FormTab label={translate('EPG')} >
                            <Deleted />

                            <BooleanInput
                                source="complete"
                            />

                            <FormDataConsumer>
                                {formDataProps => <>
                                    <Field name="episode" >
                                        {props => (
                                            <EpisodeWrapper
                                                episode={props.input.value}
                                                onChange={props.input.onChange}
                                                {...formDataProps}
                                            />
                                        )}
                                    </Field>
                                </>}
                            </FormDataConsumer>

                        </FormTab>

                        <FormTab label={translate('files')} >
                            <Deleted />

                            <VideoUpload id={id} />

                            <FormDataConsumer>
                                {formDataProps => <>
                                    <Field name="transformationID" >
                                        {(props) => (
                                            <VideoThumbnails
                                                transformationID={props.input.value}
                                                onChange={props.input.onChange}
                                                {...formDataProps}
                                            />
                                        )}
                                    </Field>

                                    <TextInput
                                        fullWidth
                                        disabled
                                        source="transformationID"
                                    />

                                    <hr />

                                    <Field name="assetTransformations_sport1tv_hero" >
                                        {(props) => (
                                            <VideoThumbnails
                                                transformationID={props.input.value}
                                                onChange={props.input.onChange}
                                                allowTransformationPicking={false}
                                                {...formDataProps}
                                            />
                                        )}
                                    </Field>

                                    <TextInput
                                        fullWidth
                                        disabled
                                        source="assetTransformations_sport1tv_hero"
                                    />

                                    <hr />

                                    <Field name="assetTransformations_sport1tv_landscape" >
                                        {(props) => (
                                            <VideoThumbnails
                                                transformationID={props.input.value}
                                                onChange={props.input.onChange}
                                                allowTransformationPicking={false}
                                                {...formDataProps}
                                            />
                                        )}
                                    </Field>

                                    <TextInput
                                        fullWidth
                                        disabled
                                        source="assetTransformations_sport1tv_landscape"
                                    />
                                </>}
                            </FormDataConsumer>

                            <FormDataConsumer>
                                {formDataProps => <>
                                    <Field name="transformations" >
                                        {props => (
                                            <VideoTransformations
                                                onChange={props.input.onChange}
                                                setPreviewVideo={setPreviewVideo}
                                                transformations={props.input.value}
                                                {...formDataProps}
                                            />
                                        )}
                                    </Field>
                                </>}
                            </FormDataConsumer>

                        </FormTab>

                        <FormTab label={translate('history')} >
                            <Deleted />

                            <TextInput
                                fullWidth
                                resettable
                                source="importID"
                            />

                            <FormDataConsumer>
                                {({ record }) => {
                                    return <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>publishedAt</TableCell>
                                                <TableCell>{record.publishedAt && new Date(record.publishedAt).toLocaleString()}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>createdAt</TableCell>
                                                <TableCell>{record.createdAt && new Date(record.createdAt).toLocaleString()}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>updatedAt</TableCell>
                                                <TableCell>{record.updatedAt && new Date(record.updatedAt).toLocaleString()}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>deletedAt</TableCell>
                                                <TableCell>{record.deletedAt && new Date(record.deletedAt).toLocaleString()}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>availableAt</TableCell>
                                                <TableCell>{record.availableAt && new Date(record.availableAt).toLocaleString()}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>expiresAt</TableCell>
                                                <TableCell>{record.expiresAt && new Date(record.expiresAt).toLocaleString()}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>;
                                }}
                            </FormDataConsumer>

                        </FormTab>
                    </TabbedForm>
                </Edit>
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(withDataProvider(translate(VideoEdit)));

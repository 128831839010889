import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'proxy-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import { Loading } from 'react-admin';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './keycloak';
import getUser from './getUser';

const onTokens = (tokens: any) => {
    if (tokens.token && tokens.token !== localStorage.getItem('token')) {

        localStorage.setItem('token', tokens.token);
        getUser().then(({id, permission}) => {
            localStorage.setItem('permission', permission);
            localStorage.setItem('userID', id);
        })
    }
};

ReactDOM.render(
    <ReactKeycloakProvider
        authClient={keycloak}
        onTokens={onTokens}
        LoadingComponent={Loading}
    >
        <App/>
    </ReactKeycloakProvider>, document.getElementById('root'));

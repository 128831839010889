import React from 'react';
import AssetEdit from './AssetEdit';
import { withDataProvider, useNotify } from 'react-admin';
import { GalleryAssetInterface } from '../../types';

interface Props {
  match: {
    isExact: boolean;
    params: { id: string; galleryId: string };
    path: string;
    url: string;
  };
}

export const AssetEditWithGallery: React.FC<Props> = withDataProvider(props => {
    const { dataProvider } = props;

    const notify = useNotify();

    const handleAddTransformationToGallery = (
        transformationId: string,
        galleryId: string
    ) => {
        dataProvider('GET_ONE_ASSET', 'gallery', {
            galleryId: galleryId,
            assetId: props.match.params.id
        })
            .then(response => {
                let responseAsset: GalleryAssetInterface = response.data;
                dataProvider('UPDATE_ASSETS', 'gallery', {
                    id: galleryId,
                    assets: [
                        {
                            id: props.match.params.id,
                            transformationID: transformationId,
                            holdPosition: responseAsset.position,
                            title: responseAsset.title,
                            caption: responseAsset.caption
                        }
                    ]
                }).then(() => {
                    props.history.push({ pathname: `/gallery/${galleryId}` });
                    notify('resources.gallery.notification.assets_added');
                });
            })
            .catch(error => console.error(error));
    };

    return (
        <AssetEdit
            {...props}
            id={props.match.params.id}
            galleryId={props.match.params.galleryId}
            handleAddTransformationToGallery={handleAddTransformationToGallery}
            basePath="/asset"
            resource="asset"
        />
    );
});

import { SelectOptionInterface } from '../types';
import { TagInterface } from '../types';
import { EpisodeInterface } from '../types';

const episodeToOption = ({id, title}:EpisodeInterface):SelectOptionInterface => ({value: id, label: title});
// @ts-ignore
const optionToEpisode = ({label, value}:SelectOptionInterface):EpisodeInterface => ({id: value, title: label});
// @ts-ignore
const optionToTag = ({label, value, approved, sportID, externalID, source}:SelectOptionInterface):TagInterface => ({id: value, tag: label, approved: approved, sportID: sportID, externalID: externalID, source: source});
const tagToOption = ({id, tag}:TagInterface):SelectOptionInterface => ({value: id, label: tag});

const episodesToOptions = (list?:EpisodeInterface[]):SelectOptionInterface[] =>
    (list || [])
        .filter(({id, title}) => id && title)
        .map(episodeToOption);

const optionsToTags = (list?:SelectOptionInterface[]):TagInterface[] =>
    (list || [])
        .filter(({label, value}) => label && value)
        .map(optionToTag);

const tagsToOptions = (list?:TagInterface[]):SelectOptionInterface[] =>
    (list || [])
        .filter(({id, tag}) => id && tag)
        .map(tagToOption);

export default {
    episodeToOption,
    episodesToOptions,
    optionToEpisode,
    optionToTag,
    optionsToTags,
    tagToOption,
    tagsToOptions,
};

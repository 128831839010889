import React from 'react';
import { Admin, resolveBrowserLocale, Resource } from 'react-admin'; // eslint-disable-line import/no-unresolved
import polyglotI18nProvider from 'ra-i18n-polyglot';

import './App.css';

import authProvider from './authProvider';
import ozDataProvider from './ozDataProvider';
import { Dashboard } from './components/dashboard/Dashboard';
import { API_URL, ROLES } from './utils/config';
import AssetCreate from './components/assets/AssetCreate';
import AssetEdit from './components/assets/AssetEdit';
import CustomLayout from './components/CustomLayout';
import englishMessages from './i18n/en';
import germanMessages from './i18n/de';
import GalleryEdit from './components/galleries/GalleryEdit';
import GalleryList from './components/galleries/GalleryList';
import { ChangeHistory, Collections, DateRange, Event, Filter9Plus, Label, LiveTv, NetworkCheck, PlaylistAdd, Style, SupervisedUserCircle, Tv, VideoLibrary, PictureAsPdf, Assessment } from '@material-ui/icons';

import AssetsList from './components/assets/AssetsList';
import { Route } from 'react-router-dom';
import { AssetEditWithTransformation } from './components/assets/AssetEditWithTransformation';
import AssetEditWithVideo from './components/assets/AssetEditWithVideo';
import eZDataReducer from './utils/eZDataReducer';
import { EzAdminDataHandler } from './utils/EzAdminDataHandler';
import { GalleryCreate } from './components/galleries/GalleryCreate';
import { AssetEditWithGallery } from './components/assets/AssetEditWithGallery';
import VideoList from './components/videos/VideoList';
import VideoEdit from './components/videos/VideoEdit';
import PlaylistEdit from './components/playlists/PlaylistEdit';
import PlaylistList from './components/playlists/PlaylistList';
import UsersList from './components/users/UsersList';
import UsersEdit from './components/users/UsersEdit';
import UserProfile from './components/users/UserProfile';
import UsersCreate from './components/users/UsersCreate';
import TagsList from './components/tags/TagsList';
import TagsEdit from './components/tags/TagsEdit';
import LoginPage from './LoginPage';
import AuditLogList from './components/auditlog/AuditLogList';

import EpgBroadcastCreate from './components/epg/broadcast/EpgBroadcastCreate';
import EpgBroadcastEdit from './components/epg/broadcast/EpgBroadcastEdit';
import EpgBroadcastList from './components/epg/broadcast/EpgBroadcastList';
import EpgCategoryEdit from './components/epg/category/EpgCategoryEdit';
import EpgCategoryList from './components/epg/category/EpgCategoryList';
import EpgChannelCreate from './components/epg/channel/EpgChannelCreate';
import EpgChannelEdit from './components/epg/channel/EpgChannelEdit';
import EpgChannelList from './components/epg/channel/EpgChannelList';
import EpgEpisodeCreate from './components/epg/episode/EpgEpisodeCreate';
import EpgEpisodeEdit from './components/epg/episode/EpgEpisodeEdit';
import EpgEpisodeList from './components/epg/episode/EpgEpisodeList';
import EpgEventStreamCreate from './components/epg/eventstream/EpgEventStreamCreate';
import EpgEventStreamEdit from './components/epg/eventstream/EpgEventStreamEdit';
import EpgEventStreamList from './components/epg/eventstream/EpgEventStreamList';
import EpgProgramCreate from './components/epg/program/EpgProgramCreate';
import EpgProgramEdit from './components/epg/program/EpgProgramEdit';
import EpgProgramList from './components/epg/program/EpgProgramList';
import FileList from './components/files/FileList';
import FileEdit from './components/files/FileEdit';
import ReportList from './components/report/ReportList';

export const Routes = [
    <Route
        path="/transformation/:id/:transformationId?"
        render={props => <AssetEditWithTransformation {...props} />}
    />,
    <Route
        path="/transformation-video/:transformationID/:videoID"
        render={props => <AssetEditWithVideo {...props} />}
    />,
    <Route
        path="/asset/:id/gallery/:galleryId"
        render={props => <AssetEditWithGallery {...props}  />}
    />,
    <Route
        path="/profile"
        render={props => <UserProfile {...props}  />}
    />
];

const App = () => {
    const messages = {
        de: germanMessages,
        en: englishMessages
    };
    const i18nProvider = polyglotI18nProvider(
        locale => messages[locale] ? messages[locale] : messages.en,
        resolveBrowserLocale()
    );

    return (
        <Admin
            customReducers={{ eZDataReducer }}
            customRoutes={Routes}
            layout={CustomLayout}
            dataProvider={ozDataProvider(API_URL())}
            authProvider={authProvider}
            dashboard={Dashboard}
            i18nProvider={i18nProvider}
            loginPage={LoginPage}
        >
            {permissions => {
                return [
                    [ROLES.admin.id.toString(), ROLES.assetRW.id.toString(), ROLES.assetRO.id.toString(), ROLES.videoAdmin.id.toString(), ROLES.videoEditor.id.toString(), ROLES.videoStandard.id.toString(), ROLES.superEditor.id.toString()].includes(permissions) ? (
                        <Resource
                            name="asset"
                            list={AssetsList}
                            edit={AssetEdit}
                            create={AssetCreate}
                            icon={Collections}
                        />
                    ) : null,
                    [ROLES.admin.id.toString(), ROLES.assetRW.id.toString(), ROLES.assetRO.id.toString(), ROLES.superEditor.id.toString()].includes(permissions) ? (
                        <Resource
                            name="gallery"
                            list={GalleryList}
                            create={GalleryCreate}
                            edit={GalleryEdit}
                            icon={Filter9Plus}
                        />
                    ) : null,
                    [ROLES.admin.id.toString()].includes(permissions) ? (
                        <Resource
                            name="tags"
                            list={TagsList}
                            edit={TagsEdit}
                            icon={Label}
                        />
                    ) : null,
                    [ROLES.admin.id.toString(), ROLES.videoAdmin.id.toString(), ROLES.videoEditor.id.toString(), ROLES.videoStandard.id.toString(), ROLES.superEditor.id.toString()].includes(permissions) ? (
                        <Resource
                            name="video"
                            list={VideoList}
                            edit={VideoEdit}
                            icon={VideoLibrary}
                        />
                    ) : null,
                    <Resource
                        name="file"
                        list={FileList}
                        edit={FileEdit}
                        icon={PictureAsPdf}
                    >
                    </Resource>,
                    [ROLES.admin.id.toString(), ROLES.videoAdmin.id.toString(), ROLES.videoEditor.id.toString(), ROLES.superEditor.id.toString()].includes(permissions) ? (
                        <Resource
                            edit={PlaylistEdit}
                            icon={PlaylistAdd}
                            list={PlaylistList}
                            name="playlist"
                        />
                    ) : null,
                    [ROLES.admin.id.toString(), ROLES.videoAdmin.id.toString(), ROLES.videoEditor.id.toString(), ROLES.videoStandard.id.toString(), ROLES.superEditor.id.toString()].includes(permissions) ? (
                        <Resource
                            name="eventstream"
                            list={[ROLES.admin.id.toString(), ROLES.videoAdmin.id.toString(), ROLES.videoEditor.id.toString(), ROLES.videoStandard.id.toString(), ROLES.superEditor.id.toString()].includes(permissions) ? EpgEventStreamList : null}
                            edit={[ROLES.admin.id.toString(), ROLES.videoAdmin.id.toString(), ROLES.videoEditor.id.toString(), ROLES.videoStandard.id.toString(), ROLES.superEditor.id.toString()].includes(permissions) ? EpgEventStreamEdit : null}
                            create={[ROLES.admin.id.toString(), ROLES.videoAdmin.id.toString(), ROLES.videoEditor.id.toString(), ROLES.videoStandard.id.toString(), ROLES.superEditor.id.toString()].includes(permissions) ? EpgEventStreamCreate : null}
                            icon={LiveTv}
                        />
                    ) : null,
                    [ROLES.admin.id.toString(), ROLES.videoAdmin.id.toString(), ROLES.videoEditor.id.toString(), ROLES.videoStandard.id.toString(), ROLES.superEditor.id.toString()].includes(permissions) ? (
                        <Resource
                            name="epg-channel"
                            list={[ROLES.admin.id.toString(), ROLES.videoAdmin.id.toString(), ROLES.videoEditor.id.toString(), ROLES.videoStandard.id.toString(), ROLES.superEditor.id.toString()].includes(permissions) ? EpgChannelList : null}
                            edit={[ROLES.admin.id.toString(), ROLES.videoAdmin.id.toString(), ROLES.videoEditor.id.toString(), ROLES.videoStandard.id.toString(), ROLES.superEditor.id.toString()].includes(permissions) ? EpgChannelEdit : null}
                            create={[ROLES.admin.id.toString(), ROLES.videoAdmin.id.toString(), ROLES.videoEditor.id.toString(), ROLES.videoStandard.id.toString(), ROLES.superEditor.id.toString()].includes(permissions) ? EpgChannelCreate : null}
                            icon={Tv}
                        />
                    ) : null,
                    [ROLES.admin.id.toString(), ROLES.videoAdmin.id.toString(), ROLES.videoEditor.id.toString(), ROLES.videoStandard.id.toString(), ROLES.superEditor.id.toString()].includes(permissions) ? (
                        <Resource
                            name="epg-category"
                            list={[ROLES.admin.id.toString(), ROLES.videoAdmin.id.toString(), ROLES.videoEditor.id.toString(), ROLES.videoStandard.id.toString(), ROLES.superEditor.id.toString()].includes(permissions) ? EpgCategoryList : null}
                            edit={[ROLES.admin.id.toString(), ROLES.videoAdmin.id.toString(), ROLES.videoEditor.id.toString(), ROLES.videoStandard.id.toString(), ROLES.superEditor.id.toString()].includes(permissions) ? EpgCategoryEdit : null}
                            icon={Style}
                        />
                    ) : null,
                    [ROLES.admin.id.toString(), ROLES.videoAdmin.id.toString(), ROLES.videoEditor.id.toString(), ROLES.videoStandard.id.toString(), ROLES.superEditor.id.toString()].includes(permissions) ? (
                        <Resource
                            name="epg-program"
                            list={[ROLES.admin.id.toString(), ROLES.videoAdmin.id.toString(), ROLES.videoEditor.id.toString(), ROLES.videoStandard.id.toString(), ROLES.superEditor.id.toString()].includes(permissions) ? EpgProgramList : null}
                            edit={[ROLES.admin.id.toString(), ROLES.videoAdmin.id.toString(), ROLES.videoEditor.id.toString(), ROLES.videoStandard.id.toString(), ROLES.superEditor.id.toString()].includes(permissions) ? EpgProgramEdit : null}
                            create={[ROLES.admin.id.toString(), ROLES.videoAdmin.id.toString(), ROLES.videoEditor.id.toString(), ROLES.videoStandard.id.toString(), ROLES.superEditor.id.toString()].includes(permissions) ? EpgProgramCreate : null}
                            icon={DateRange}
                        />
                    ) : null,
                    [ROLES.admin.id.toString(), ROLES.videoAdmin.id.toString(), ROLES.videoEditor.id.toString(), ROLES.videoStandard.id.toString(), ROLES.superEditor.id.toString()].includes(permissions) ? (
                        <Resource
                            name="epg-episode"
                            list={[ROLES.admin.id.toString(), ROLES.videoAdmin.id.toString(), ROLES.videoEditor.id.toString(), ROLES.videoStandard.id.toString(), ROLES.superEditor.id.toString()].includes(permissions) ? EpgEpisodeList : null}
                            edit={[ROLES.admin.id.toString(), ROLES.videoAdmin.id.toString(), ROLES.videoEditor.id.toString(), ROLES.videoStandard.id.toString(), ROLES.superEditor.id.toString()].includes(permissions) ? EpgEpisodeEdit : null}
                            create={[ROLES.admin.id.toString(), ROLES.videoAdmin.id.toString(), ROLES.videoEditor.id.toString(), ROLES.videoStandard.id.toString(), ROLES.superEditor.id.toString()].includes(permissions) ? EpgEpisodeCreate : null}
                            icon={Event}
                        />
                    ) : null,
                    [ROLES.admin.id.toString(), ROLES.videoAdmin.id.toString(), ROLES.videoEditor.id.toString(), ROLES.videoStandard.id.toString(), ROLES.superEditor.id.toString()].includes(permissions) ? (
                        <Resource
                            name="epg-broadcast"
                            list={[ROLES.admin.id.toString(), ROLES.videoAdmin.id.toString(), ROLES.videoEditor.id.toString(), ROLES.videoStandard.id.toString(), ROLES.superEditor.id.toString()].includes(permissions) ? EpgBroadcastList : null}
                            edit={[ROLES.admin.id.toString(), ROLES.videoAdmin.id.toString(), ROLES.videoEditor.id.toString(), ROLES.videoStandard.id.toString(), ROLES.superEditor.id.toString()].includes(permissions) ? EpgBroadcastEdit : null}
                            create={[ROLES.admin.id.toString(), ROLES.videoAdmin.id.toString(), ROLES.videoEditor.id.toString(), ROLES.videoStandard.id.toString(), ROLES.superEditor.id.toString()].includes(permissions) ? EpgBroadcastCreate : null}
                            icon={NetworkCheck}
                        />
                    ) : null,
                    <Resource
                        name="users"
                        list={[ROLES.admin.id.toString()].includes(permissions) ? UsersList : null}
                        edit={[ROLES.admin.id.toString()].includes(permissions) ? UsersEdit : null}
                        create={[ROLES.admin.id.toString()].includes(permissions) ? UsersCreate : null}
                        icon={SupervisedUserCircle}
                    />,
                    [ROLES.admin.id.toString(), ROLES.assetRW.id.toString(), ROLES.assetRO.id.toString(), ROLES.videoAdmin.id.toString(), ROLES.videoEditor.id.toString(), ROLES.videoStandard.id.toString(), ROLES.superEditor.id.toString()].includes(permissions) ? (
                        <Resource
                            icon={ChangeHistory}
                            list={AuditLogList}
                            name="auditlog"
                        />
                    ) : null,
                    [ROLES.admin.id.toString(), ROLES.superEditor.id.toString(), ROLES.videoEditor.id.toString(), ROLES.assetRW.id.toString()].includes(permissions) ? (
                        <Resource
                            icon={Assessment}
                            list={ReportList}
                            name="report-imago"
                            options={{label:'Imago Report'}}
                        />
                    ) : null,
                    <EzAdminDataHandler />
                ];
            }}
        </Admin>
    );
};

export default App;
/*
[ROLES.admin.id.toString(), ROLES.ottRO.id.toString(), ROLES.ottRW.id.toString()].includes(permissions) ? (
            <Resource
              name="ott-stream"
              list={OttStreamList}
              edit={PostEdit}
              icon={Tv}
            />
          ) : null,
          [ROLES.admin.id.toString(), ROLES.ottRO.id.toString(), ROLES.ottRW.id.toString()].includes(permissions) ? (
            <Resource
              name="ott-index"
              list={OttIndexList}
              edit={OttIndexEdit}
              icon={Tv}
            />
          ) : null,
          [ROLES.admin.id.toString(), ROLES.ottRO.id.toString(), ROLES.ottRW.id.toString()].includes(permissions) ? (
            <Resource
              name="ott-app-streams"
              list={OttAppStreamsList}
              edit={OttAppStreamsEdit}
              icon={Tv}
            />
          ) : null,
          */

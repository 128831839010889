import { useState } from 'react';
import React from 'react';
import { Document, Page } from 'react-pdf';

const SinglePagePDFViewer = (props) => {
    // eslint-disable-next-line
  const [numPages, setNumPages] = useState<number | null>(null)
    const [pageNumber, setPageNumber] = useState(1);

    const { pdf } = props;

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
        setNumPages(numPages);
        setPageNumber(1);
    };

    return (
        <React.Fragment>
            <Document
                file={pdf}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                <Page pageNumber={pageNumber} height={250} renderAnnotationLayer={false} />
            </Document>
        </React.Fragment>
    );
};

export default SinglePagePDFViewer;

import { withDataProvider } from 'react-admin';
import Fab from '@material-ui/core/Fab';
import { Tooltip } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Add';
import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

export const CreateGalleryButton = withDataProvider(
    ({ translate }) => {
        const dispatch = useDispatch();
        return (
            <Tooltip title={translate('resources.gallery.action.create')}>
                <Fab
                    color="primary"
                    onClick={() => {
                        dispatch(push('/gallery/create'));
                    }}
                >
                    <CreateIcon />
                </Fab>
            </Tooltip>
        );
    }
);

import React from 'react';
import {
    BooleanInput,
    Create,
    FormDataConsumer,
    NumberInput,
    SimpleForm,
    TextInput,
} from 'react-admin';
import TextInputThumbnail from '../TextInputThumbnail';
import { Field } from 'react-final-form';
import TagWrapper from '../../TagWrapper';

export const EpgEpisodeCreate = (props) =>
    <Create {...props} >
        <SimpleForm>
            <NumberInput source="programId" required fullWidth />
            <NumberInput source="season" required fullWidth />
            <NumberInput source="number" required fullWidth />
            <TextInput source="serienId" fullWidth />
            <TextInput source="materialId" fullWidth />
            <TextInput source="description" fullWidth />
            <TextInput source="longDescription" fullWidth />
            <NumberInput source="duration" fullWidth />
            <TextInput source="subcategory" fullWidth />
            <TextInput source="subtitle" fullWidth />
            <TextInput source="fsk" fullWidth />
            <FormDataConsumer>
                {formDataProps => <>
                    <Field name="transformationId">
                        {props => (
                            <TextInputThumbnail
                                fullWidth
                                onChange={props.input.onChange}
                                source="transformationId"
                                {...formDataProps}
                            />
                        )}
                    </Field>
                </>}
            </FormDataConsumer>
            <TextInput source="vodVideoId" fullWidth />
            <FormDataConsumer>
                {formDataProps => <>
                    <Field name="tags">
                        {props => (
                            <TagWrapper
                                maxTags={1}
                                mayCreateNewTags={false}
                                onChange={props.input.onChange}
                                permissions={props.permissions}
                                {...formDataProps}
                            />
                        )}
                    </Field>
                </>}
            </FormDataConsumer>
            <BooleanInput source="isHighlight" required defaultValue={false} />
        </SimpleForm>
    </Create>;

export default EpgEpisodeCreate;

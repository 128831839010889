import React from 'react';
import {
    BooleanInput,
    DeleteButton,
    Edit,
    FormDataConsumer,
    NumberInput,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
} from 'react-admin';
import { Field } from 'react-final-form';
import TextInputThumbnail from '../TextInputThumbnail';
import {Card, CardContent} from '@material-ui/core';
import S1TVThumbnailContainer from '../../playlists/S1TVThumbnailContainer';

const CustomToolbar = props =>
    <Toolbar {...props}>
        <SaveButton />
        {props.record.source === 'oz' ? <DeleteButton undoable={false} /> : null}
    </Toolbar>;

export const EpgProgramEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm redirect="edit" toolbar={<CustomToolbar/>}>
                <NumberInput source="id" disabled fullWidth/>

                <FormDataConsumer>
                    {formDataProps =>
                        <Field name="title">
                            {() => <TextInput source="title" required disabled={'oz' !== formDataProps.record.source} fullWidth/>}
                        </Field>
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {formDataProps =>
                        <Field name="categoryId">
                            {() => <NumberInput source="categoryId" required disabled={'oz' !== formDataProps.record.source}
                                fullWidth/>}
                        </Field>
                    }
                </FormDataConsumer>

                <TextInput source="source" disabled fullWidth/>
                <FormDataConsumer>
                    {formDataProps => <>
                        <Field name="transformationId">
                            {props => (
                                <TextInputThumbnail
                                    fullWidth
                                    onChange={props.input.onChange}
                                    source="transformationId"
                                    {...formDataProps}
                                />
                            )}
                        </Field>
                    </>}
                </FormDataConsumer>
                <NumberInput source="tagId" fullWidth/>
                <BooleanInput source="isHighlight" required/>

                <FormDataConsumer>
                    {formDataProps => <>
                        <Card>
                            <CardContent>SP1+ navigation asset to EPG-Program</CardContent>
                            <Field name="assetTransformations">
                                {props => (
                                    <S1TVThumbnailContainer
                                        targetComponentName={'Program'}
                                        thumbnailTitle={'Add navigation asset to program'}
                                        transformations={props.input.value}
                                        assetTransformations={props.input.value}
                                        onChange={props.input.onChange}
                                        type={'landscape'}
                                        platform={'sport1tv'}
                                        {...formDataProps}
                                    />
                                )}
                            </Field>
                        </Card>
                        <Card>
                            <CardContent>SP1+ cover asset to EPG-Program</CardContent>
                            <Field name="assetTransformations">
                                {props => (
                                    <S1TVThumbnailContainer
                                        targetComponentName={'Program'}
                                        thumbnailTitle={'Add cover asset to program'}
                                        transformations={props.input.value}
                                        assetTransformations={props.input.value}
                                        onChange={props.input.onChange}
                                        type={'hero'}
                                        platform={'sport1tv'}
                                        {...formDataProps}
                                    />
                                )}
                            </Field>
                        </Card>
                    </>}
                </FormDataConsumer>
            </SimpleForm>
        </Edit>
    );
};

export default EpgProgramEdit;

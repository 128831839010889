import React from 'react';
import {
    BooleanField,
    Datagrid,
    EditButton,
    Pagination,
    TextField,
} from 'react-admin';
import {ListController} from 'react-admin';
import {EpgEpisodeSearch} from './EpgEpisodeSearch';

const EpgEpisodeList = props => {
    return (
        <ListController
            {...props}
            resource={'epg-episode'}
        >
            {
                controllerProps => {
                    return (
                        <>
                            <EpgEpisodeSearch
                                setFilters={controllerProps.setFilters}
                                filterValues={controllerProps.filterValues}
                            />
                            <Datagrid {...controllerProps}>
                                <TextField sortable={false} source="id"/>
                                <TextField sortable={false} source="subtitle"/>
                                <TextField sortable={false} source="programId"/>
                                <TextField sortable={false} source="serienId"/>
                                <TextField sortable={false} source="materialId"/>
                                <TextField sortable={false} source="season"/>
                                <TextField sortable={false} source="number"/>
                                <TextField sortable={false} source="subcategory"/>
                                <TextField sortable={false} source="transformationId"/>
                                <TextField sortable={false} source="source"/>
                                <BooleanField sortable={false} source="isHighlight"/>
                                <EditButton/>
                            </Datagrid>
                            <Pagination
                                {...controllerProps}
                                rowsPerPageOptions={[5, 10, 25]}
                            />
                        </>
                    );
                }
            }
        </ListController>
    );
};
export default EpgEpisodeList;

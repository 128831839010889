import {httpRequestObject} from './httpRequestObjects';
import {CREATE, GET_LIST, GET_ONE, UPDATE, DELETE} from 'react-admin';

export default (type, params, apiUrl, options): httpRequestObject => {
    let url;
    const resource = 'users';
    options.headers.append('Content-Type', 'application/json');
    switch (type) {
    case GET_LIST: {
        const {filter = {}} = params;
        const {page = 1, perPage = 12} = params.pagination;

        const text = filter.text
            ? `&text=${filter.text}`
            : '';

        url = encodeURI(`${apiUrl}/${resource}/all?page=${page}&per=${perPage}${text}`);
        options.method = 'POST';
        options.headers.append('cache-control', 'no-cache');
        break;
    }

    case GET_ONE: {
        url = encodeURI(`${apiUrl}/${resource}/${params.id}`);
        options.method = 'GET';
        break;
    }

    case CREATE: {
        url = encodeURI(`${apiUrl}/${resource}`);
        options.method = 'POST';
        options.body = JSON.stringify(params.data);
        break;
    }

    case UPDATE: {
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'PUT';
        options.body = JSON.stringify(params.data);
        break;
    }

    case DELETE: {
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'DELETE';
        options.body = JSON.stringify(params.previousData);
        break;
    }
    }
    return {url, options};
};

import React from 'react';
import { ListController, Pagination } from 'react-admin';
import SearchPanel from '../searchPanel/SearchPanel';
import { CircularProgress, Grid } from '@material-ui/core';
import AssetsGrid from '../assets/AssetsGrid';

const FileList = (props) => {
    return (
        <ListController {...props} resource={'file'} perPage={12} >
            {
                controllerProps => {
                    return (
                        <React.Fragment >
                            <SearchPanel
                                initialOpen={true}
                                setFilters={controllerProps.setFilters}
                                filterValues={{...controllerProps.filterValues, type: 'file'}}
                                type="file"
                            />
                            {controllerProps.loading ? (
                                <Grid container spacing={8}>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{ textAlign: 'center', marginTop: 70 }}
                                    >
                                        <CircularProgress />
                                    </Grid>
                                </Grid>
                            ) : (
                                <React.Fragment>
                                    <AssetsGrid
                                        {...controllerProps}
                                        hasEdit={props.hasEdit !== false}
                                    />
                                    <Pagination
                                        {...controllerProps}
                                        rowsPerPageOptions={[12, 24, 48, 96]}
                                    />
                                </React.Fragment>
                            )}
                        </React.Fragment >
                    );
                }
            }
        </ListController >
    );
};

export default FileList;

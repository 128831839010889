import React from 'react';
import {
    AppBar,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Toolbar,
    Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { translate } from 'react-admin';
import MatchIdList from './MatchIdList';

interface Props {
  open: boolean
  setOpenDialog: CallableFunction
  translate
  onChange: CallableFunction
}

const AddMatchIdDialog = translate((props: Props) => {

    const handleCloseDialog = () => {
        props.setOpenDialog(false);
    };

    return (
        <Dialog fullScreen={true} open={props.open} >
            <AppBar >
                <Toolbar >
                    <IconButton color="inherit" onClick={() => handleCloseDialog()} aria-label="Close" >
                        <CloseIcon />
                    </IconButton >
                    <Typography variant="h6" color="inherit" >
                        {props.translate('resources.video.label.add_matchId_to_video')}
                    </Typography >
                </Toolbar >
            </AppBar >
            <DialogTitle />
            <DialogContent >
                <MatchIdList {...props} />
            </DialogContent >
        </Dialog >
    );
});

export default AddMatchIdDialog;

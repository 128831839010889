import React, { useState } from 'react';
import { SimpleForm, List } from 'react-admin';
import { withDataProvider } from 'react-admin';
import { DateInput } from 'react-admin';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { Button } from '@material-ui/core';

const ReportList = (props) => {
    const [month, setMonth] = useState(new Date().getMonth() + 1);
    const [year, setYear] = useState(new Date().getFullYear());
    const [selectedDate, setSelectedDate] = useState(new Date());

    const handleChange = (event) => {
        const date = new Date(event.target.value);
        setSelectedDate(date);
    };

    const handleClick = () => {
        setMonth(selectedDate.getMonth() + 1);
        setYear(selectedDate.getFullYear());
    };

    return (
        <>
            <SimpleForm toolbar={false}>
                <DateInput
                    fullWidth
                    source="date"
                    label="select a month"
                    onChange={handleChange}
                    parse={d => new Date(d)}
                    initialValue={selectedDate}
                    {...props}
                />
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<CloudDownloadIcon />}
                    onClick={handleClick}
                >
                    Request report
                </Button>
            </SimpleForm>
            <List {...props} pagination={false} fullWidth bulkActionButtons={false} filterDefaultValues={{ month, year }}>
                <>
                    <b>Selected month/year:</b>
                    <p> {month}/{year}</p>
                </>
            </List>

        </>
    );
};

export default withDataProvider(ReportList);
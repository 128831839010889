import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { Grid, IconButton, TextField, Tooltip, createStyles, Theme, Radio } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import { withStyles } from '@material-ui/core/styles';
import { translate, withDataProvider } from 'react-admin';
import { push } from 'connected-react-router';
import TransformIcon from '@material-ui/icons/Transform';
import { DOWNLOAD_URL } from '../../utils/config';
import { useDispatch } from 'react-redux';

const styles = createStyles(({typography}: Theme) => {
    const input = {
        color: 'black',
        backgroundColor: 'white'
    };
    return {
        inputTitle: {
            ...typography.caption,
            fontStyle: 'normal',
            fontSize: 'small',
            borderBottom: '1px solid',
            '&:hover': { ...input }
        },
        inputCaption: {
            ...typography.caption,
            fontStyle: 'normal',
            fontSize: 'medium',
            '&:hover': { ...input },
            minHeight: 200
        },
        transformationIcon: {
            color: 'rgba(255,255,255,0.7)'
        }
    };
});

export const SortableGalleryAssetItem = SortableElement(
    ({
        galleryId,
        element,
        removeAssetFromGallery,
        updateAssetsInGallery,
        classes,
        translate,
        updateIsTeaser
    }) => {
        const { id, title, caption, transformationID } = element;
        let assetPreview = '';

        const dispatch = useDispatch();

        if (transformationID !== undefined) {
            assetPreview = `/c/t/${transformationID}/268x268`;
        } else {
            assetPreview = `/c/t/${id}/268x268`;
        }

        const handleIsTeaserChange = event => {
            updateIsTeaser(event.target.value);
        };

        return (
            <Grid item xs={2} style={{ zIndex: 999 }}>
                <Tooltip
                    title={translate('to change position - press and hold')}
                    enterDelay={500}
                    placement={'top'}
                >
                    <div
                        style={{
                            height: 268,
                            position: 'relative',
                            background: `grey url(${DOWNLOAD_URL()}${assetPreview}) no-repeat center`,
                            backgroundSize: 'cover'
                        }}
                    >
                        {transformationID && (
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: '75%',
                                    fontSize: '4em'
                                }}
                            >
                                <Tooltip title={translate('Transformations', 1)} placement="bottom">
                                    <TransformIcon
                                        color="secondary"
                                        fontSize="inherit"
                                        className={classes.transformationIcon}
                                    />
                                </Tooltip>
                            </div>
                        )}
                        <div
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                width: '100%',
                                backgroundColor: 'rgba(255, 255, 255, 0.7)'
                            }}
                        >
                            <Grid container spacing={1}>
                                <Grid item xs={7}>
                                    <Tooltip
                                        title={translate('resources.gallery.label.set_as_gallery_teaser')}
                                        enterDelay={500}
                                        placement={'top'}
                                    >
                                        <Radio
                                            checked={element.isTeaser === true}
                                            value={id}
                                            onChange={handleIsTeaserChange}
                                            name="isTeaser" />
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={2}>
                                    <IconButton
                                        onClick={() => {
                                            dispatch(push(`/asset/${id}/gallery/${galleryId}`));
                                        }}>
                                        <EditIcon>
                      Edit
                                        </EditIcon>
                                    </IconButton>
                                </Grid>
                                <Grid item xs={3}>
                                    <IconButton
                                        tabIndex={-1}
                                        title={translate(
                                            'resources.gallery.label.remove_asset_from_gallery'
                                        )}
                                        aria-label="Delete"
                                        color="default"
                                        onClick={() => removeAssetFromGallery(id)}
                                    >
                                        <DeleteForeverIcon/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Tooltip>
                <TextField
                    id="title"
                    InputProps={{ disableUnderline: true, className: classes.inputTitle }}
                    placeholder={translate('mam.title')}
                    margin={'dense'}
                    value={title}
                    onChange={e => updateAssetsInGallery(element, 'title', e.target.value)}
                    fullWidth
                />
                <TextField
                    id="caption"
                    InputProps={{
                        disableUnderline: true,
                        className: classes.inputCaption
                    }}
                    placeholder={translate('mam.image_caption')}
                    value={caption}
                    onChange={e => updateAssetsInGallery(element, 'caption', e.target.value)}
                    margin={'dense'}
                    multiline
                    fullWidth
                />
            </Grid>
        );
    }
);

export default translate(
    withStyles(styles)(withDataProvider(SortableGalleryAssetItem))
);

import React from 'react';
import {
    BooleanInput,
    Create,
    FormDataConsumer,
    NumberInput,
    SimpleForm,
    TextInput,
} from 'react-admin';
import TextInputThumbnail from '../TextInputThumbnail';
import { Field } from 'react-final-form';
import TagWrapper from '../../TagWrapper';

export const EpgProgramCreate = (props) =>
    <Create {...props}>
        <SimpleForm>
            <TextInput source="title" required fullWidth />
            <NumberInput source="categoryId" required fullWidth />
            <FormDataConsumer>
                {formDataProps => <>
                    <Field name="transformationId">
                        {props => (
                            <TextInputThumbnail
                                fullWidth
                                onChange={props.input.onChange}
                                source="transformationId"
                                {...formDataProps}
                            />
                        )}
                    </Field>
                </>}
            </FormDataConsumer>
            <FormDataConsumer>
                {formDataProps => <>
                    <Field name="tags">
                        {props => (
                            <TagWrapper
                                maxTags={1}
                                mayCreateNewTags={false}
                                onChange={props.input.onChange}
                                permissions={props.permissions}
                                {...formDataProps}
                            />
                        )}
                    </Field>
                </>}
            </FormDataConsumer>
            <BooleanInput source="isHighlight" required defaultValue={false} />
        </SimpleForm>
    </Create>;

export default EpgProgramCreate;

import React from 'react';
import { GalleryField } from './GalleryField';
import { Grid } from '@material-ui/core';

const GalleryGrid = (props: any): React.ReactElement => {
    return (
        <Grid container spacing={1}>
            {props.ids.map((id, index) => (
                <Grid item lg={2} key={index}>
                    <GalleryField
                        hasEdit={props.hasEdit}
                        onSelect={props.onSelect}
                        onUnselectItems={props.onUnselectItems}
                        selectedIds={props.selectedIds}
                        basePath={props.basePath}
                        record={props.data[id]}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

export default GalleryGrid;

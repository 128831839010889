import { Document, Page } from 'react-pdf';
import React, { useState } from 'react';
import './PDFStyles.css';


const AllPagesPDFViewer = (props) => {
    const [numPages, setNumPages] = useState<number | null>(null);
    const { pdf } = props;


    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
        setNumPages(numPages);
    };
    return (
        <div id="allPages">
            <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
                {Array.from(new Array(numPages), (el, index) => (
                    <Page key={`page_${index + 1}`} pageNumber={index + 1} renderAnnotationLayer={false} />
                ))}
            </Document>
        </div>
    );
};

export default AllPagesPDFViewer;

import React, { useState } from 'react';
import {
    BooleanInput,
    Confirm,
    DateTimeInput,
    DELETE,
    Edit,
    FormDataConsumer,
    NumberInput,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    translate,
    useNotify,
    useRedirect,
    withDataProvider,
} from 'react-admin';
import {
    Card,
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableRow,
} from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Field } from 'react-final-form';
import RouteLeavingGuard from '../../RouteLeavingGuard';
import SelectChannel from '../SelectChannel';
import SelectProgram from '../SelectProgram';
import TagWrapper from '../../TagWrapper';
import TextInputThumbnail from '../TextInputThumbnail';
import isChannelAllowedAsEventstreamSource from './isChannelAllowedAsEventstreamSource';
import S1TVThumbnailContainer from '../../playlists/S1TVThumbnailContainer';
import { isRemoved } from './RemovedEventstreams';

interface Country {
    code: string;
    nameEn: string;
}

const deviceTypeMap = {
    SPORT1_PLUS: 'SmartTV',
};

const Delete = ({dataProvider, record, translate}) => {
    const [confirming, setConfirming] = useState(false);

    return <>
        <DeleteForeverIcon onClick={() => setConfirming(true)} />
        <Confirm
            content={translate('Delete?')}
            isOpen={confirming}
            onClose={() => setConfirming(false)}
            onConfirm={() =>
                dataProvider(DELETE, 'eventstream', record)
                    .then((data) => {
                        // Do a hard reload after deletion. Just start over.
                        // I hate state management.
                        // @ts-ignore
                        window.location.reload(true);
                    })
            }
            title={translate('Delete?')}
        />
    </>;
};

const CustomToolbar = props => {
    return <Toolbar {...props}>
        <SaveButton />

        {props.record.broadcast.source === 'oz' && props.record.episode.source === 'oz'
            ? <Delete {...props} />
            : null}
    </Toolbar>;
};

/**
 * Some Fields are hidden.
 * This is due to the Syntactic Sugar Nature of this Form.
 */
export const EpgEventStreamEdit = withDataProvider(translate((props) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const { dataProvider, permissions, translate } = props;

    return <Edit undoable={false} {...props}>
        <SimpleForm toolbar={<CustomToolbar dataProvider={dataProvider} translate={translate} />}>
            <RouteLeavingGuard navigate={props.history.push} shouldBlockNavigation={(location) => {
                if (isRemoved(props.id)) {
                    return false;
                }
                return props.history.location.pathname !== location.pathname;
            }}/>

            <FormDataConsumer>
                {formDataProps => {
                    if (!formDataProps.record.id || isRemoved(formDataProps.record.id)) {
                        notify(translate('resources.epg.notifications.no_eventstream_found'));
                        redirect('list', '/eventstream');
                    }
                }}
            </FormDataConsumer>

            <FormDataConsumer>
                {formDataProps =>
                    'oz' === formDataProps.record.broadcast.source && formDataProps.record.channel
                        ? <Field name="channel">
                            {props => <SelectChannel
                                {...formDataProps}
                                onChange={props.input.onChange}
                                dataProvider={dataProvider}
                                defaultValue={{label: formDataProps.record.channel.name, value: formDataProps.record.channel.id}}
                                filter={isChannelAllowedAsEventstreamSource}
                            /> }
                        </Field>
                        : <TextInput
                            disabled
                            fullWidth
                            label={translate('resources.epg.channel.name')}
                            source="channel.name"
                        />
                }
            </FormDataConsumer>

            <FormDataConsumer>
                {formDataProps =>
                    'oz' === formDataProps.record.episode.source && formDataProps.record.program
                        ? <Field name="program">
                            {props => <SelectProgram
                                {...formDataProps}
                                onChange={props.input.onChange}
                                dataProvider={dataProvider}
                                defaultValue={{label: formDataProps.record.program.title, value: formDataProps.record.program.id}}
                            /> }
                        </Field>
                        : <TextInput
                            disabled
                            fullWidth
                            label={translate('resources.epg.program.title')}
                            source="program.title"
                        />
                }
            </FormDataConsumer>

            <FormDataConsumer>
                {formDataProps =>
                    <Field name="season">
                        {() => <NumberInput
                            disabled={'oz' !== formDataProps.record.episode.source}
                            fullWidth
                            label={translate('resources.epg.episode.season')}
                            required
                            source="episode.season"
                            style={{display: 'none'}}
                        /> }
                    </Field>
                }
            </FormDataConsumer>

            <FormDataConsumer>
                {formDataProps =>
                    <Field name="number">
                        {() => <NumberInput
                            disabled={'oz' !== formDataProps.record.episode.source}
                            fullWidth
                            label={translate('resources.epg.episode.number')}
                            required
                            source="episode.number"
                            style={{display: 'none'}}
                        /> }
                    </Field>
                }
            </FormDataConsumer>

            <FormDataConsumer>
                {formDataProps =>
                    <Field name="subtitle">
                        {() => <TextInput
                            disabled={'oz' !== formDataProps.record.episode.source}
                            fullWidth
                            label={translate('resources.epg.episode.subtitle')}
                            source="episode.subtitle"
                        /> }
                    </Field>
                }
            </FormDataConsumer>

            <FormDataConsumer>
                {formDataProps =>
                    <Field name="subcategory">
                        {() => <TextInput
                            disabled={'oz' !== formDataProps.record.episode.source}
                            fullWidth
                            label={translate('resources.epg.episode.subcategory')}
                            source="episode.subcategory"
                            style={{display: 'none'}}
                        /> }
                    </Field>
                }
            </FormDataConsumer>

            <FormDataConsumer>
                {formDataProps =>
                    <Field name="serienId">
                        {() => <TextInput
                            disabled={'oz' !== formDataProps.record.episode.source}
                            fullWidth
                            label={translate('resources.epg.episode.serienId')}
                            source="episode.serienId"
                            style={{display: 'none'}}
                        /> }
                    </Field>
                }
            </FormDataConsumer>

            <FormDataConsumer>
                {formDataProps =>
                    <Field name="materialId">
                        {() => <TextInput
                            disabled={'oz' !== formDataProps.record.episode.source}
                            fullWidth
                            label={translate('resources.epg.episode.materialId')}
                            source="episode.materialId"
                            style={{display: 'none'}}
                        /> }
                    </Field>
                }
            </FormDataConsumer>

            <FormDataConsumer>
                {formDataProps =>
                    <Field name="description">
                        {() => <TextInput
                            disabled={'oz' !== formDataProps.record.episode.source}
                            fullWidth
                            label={translate('resources.epg.episode.description')}
                            source="episode.description"
                        /> }
                    </Field>
                }
            </FormDataConsumer>

            <FormDataConsumer>
                {formDataProps =>
                    <Field name="longDescription">
                        {() => <TextInput
                            disabled={'oz' !== formDataProps.record.episode.source}
                            fullWidth
                            label={translate('resources.epg.episode.longDescription')}
                            source="episode.longDescription"
                        /> }
                    </Field>
                }
            </FormDataConsumer>

            <FormDataConsumer>
                {formDataProps =>
                    <Field name="duration">
                        {() => <NumberInput
                            disabled={'oz' !== formDataProps.record.episode.source}
                            fullWidth
                            label={translate('resources.epg.episode.duration')}
                            source="episode.duration"
                            style={{display: 'none'}}
                        /> }
                    </Field>
                }
            </FormDataConsumer>

            <FormDataConsumer>
                {formDataProps =>
                    <Field name="fsk">
                        {() => <TextInput
                            disabled={'oz' !== formDataProps.record.episode.source}
                            fullWidth
                            label={translate('resources.epg.episode.fsk')}
                            source="episode.fsk"
                            style={{display: 'none'}}
                        /> }
                    </Field>
                }
            </FormDataConsumer>

            <FormDataConsumer>
                {formDataProps => <>
                    <Field name="episode.transformationId">
                        {props => (
                            <TextInputThumbnail
                                fullWidth
                                onChange={props.input.onChange}
                                source="episode.transformationId"
                                {...formDataProps}
                            />
                        )}
                    </Field>
                </>}
            </FormDataConsumer>

            <FormDataConsumer>
                {formDataProps => <>
                    <Field name="tags">
                        {props => <TagWrapper
                            maxTags={1}
                            recordWins={true}
                            mayCreateNewTags={false}
                            onChange={props.input.onChange}
                            permissions={permissions}
                            {...formDataProps}
                        /> }
                    </Field>
                </>}
            </FormDataConsumer>

            <TextInput
                fullWidth
                label={translate('resources.epg.episode.vodVideoId')}
                source="episode.vodVideoId"
            />

            <BooleanInput
                label={translate('resources.epg.broadcast.isActive')}
                required
                source="broadcast.isActive"
            />

            <BooleanInput
                label={translate('resources.epg.broadcast.isEventStream')}
                required
                source="broadcast.isEventStream"
                style={{display: 'none'}}
            />

            <BooleanInput
                label={translate('resources.epg.episode.isHighlight')}
                required
                source="episode.isHighlight"
            />

            <BooleanInput
                label={translate('resources.epg.broadcast.isHighlight')}
                source="broadcast.isHighlight"
            />

            <FormDataConsumer>
                {formDataProps =>
                    <Field name="startTime">
                        {() =>
                            <DateTimeInput
                                disabled={'oz' !== formDataProps.record.broadcast.source}
                                fullWidth
                                label={translate('resources.epg.broadcast.startTime')}
                                parse={(d) => new Date(d)}
                                resettable
                                source="broadcast.startTime"
                            />
                        }
                    </Field>
                }
            </FormDataConsumer>

            <FormDataConsumer>
                {formDataProps =>
                    <Field name="endTime">
                        {() =>
                            <DateTimeInput
                                disabled={'oz' !== formDataProps.record.broadcast.source}
                                fullWidth
                                label={translate('resources.epg.broadcast.endTime')}
                                parse={(d) => new Date(d)}
                                resettable
                                source="broadcast.endTime"
                            />
                        }
                    </Field>
                }
            </FormDataConsumer>

            <FormDataConsumer>
                {formDataProps => <>
                    <Field name="episode.assetTransformations">
                        {props => (
                            <>
                                <Card>
                                    <CardContent>SP1+ navigation asset to EPG-Episode</CardContent>
                                    <S1TVThumbnailContainer
                                        targetComponentName={'Eventstreams'}
                                        thumbnailTitle={'Add navigation asset to episode'}
                                        transformations={props.input.value}
                                        assetTransformations={props.input.value}
                                        onChange={props.input.onChange}
                                        type={'landscape'}
                                        platform={'sport1tv'}
                                        {...formDataProps}
                                    />
                                </Card>
                                <Card>
                                    <CardContent>SP1+ cover asset to EPG-Episode</CardContent>
                                    <S1TVThumbnailContainer
                                        targetComponentName={'Eventstreams'}
                                        thumbnailTitle={'Add cover asset to program'}
                                        transformations={props.input.value}
                                        assetTransformations={props.input.value}
                                        onChange={props.input.onChange}
                                        type={'hero'}
                                        platform={'sport1tv'}
                                        {...formDataProps}
                                    />
                                </Card>
                            </>
                        )}
                    </Field>
                </>}
            </FormDataConsumer>

            <hr style={{margin: '2rem 0 1rem', width: '100%'}} />

            <TextInput
                disabled
                fullWidth
                label={translate('resources.epg.episode.geoBlockingType')}
                source="episode.geoBlockingType"
            />

            <FormDataConsumer>
                {({ record }) => {
                    if (!record?.episode?.episodeCountries || !Object.keys(record?.episode?.episodeCountries).length) {
                        return null;
                    }
                    return <Table>
                        <TableBody>
                            {Object.entries(record.episode.episodeCountries as Record<string, Country[]>)
                                .map(([deviceType, countries]) =>
                                    countries.map(({code, nameEn}) =>
                                        <TableRow>
                                            <TableCell>{deviceTypeMap[deviceType] || deviceType}</TableCell>
                                            <TableCell>{code}</TableCell>
                                            <TableCell>{nameEn}</TableCell>
                                        </TableRow>
                                    )
                                )}
                        </TableBody>
                    </Table>;
                }}
            </FormDataConsumer>

        </SimpleForm>
    </Edit>;
}));

export default EpgEventStreamEdit;

import React, { useState, useEffect } from 'react';
import {
    ListController,
    Datagrid,
    withDataProvider,
    TextField,
    Pagination
} from 'react-admin';
import { Grid, Card } from '@material-ui/core';
import SearchPanel from '../searchPanel/SearchPanel';
import {
    createStyles,
    withStyles,
    WithStyles,
    Theme
} from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import CreateButton from '../../utils/CreateButton';
import { ROLES } from '../../utils/config';

const styles = ({ spacing }: Theme) => createStyles({ });

interface Props extends WithStyles<typeof styles> {
    basePath: string;
    location;
    match;
    permissions;
    resource: string;
}

export default withStyles(styles)(withDataProvider((props: Props) => {
    const {
        location,
        permissions,
        resource,
    } = props;
    const [selectedId, setSelectedId] = useState<string | null>();
    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedId) {
            dispatch(push(`/users/${selectedId}`));
        }
        else {
            dispatch(push('/users'));
        }
    }, [dispatch, selectedId]);

    const rowStyle = (record, index: number) => {
        return record && {
            backgroundColor:
            location.pathname.indexOf(record.id) >= 0
                ? '#efe'
                : index % 2
                    ? 'white'
                    : '#f0f0f0f0'
        };
    };
    return(
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <ListController {...props} resource={'users'} perPage={12}>
                    {controllerProps => {
                        return (
                            <>
                                <SearchPanel
                                    initialOpen={true}
                                    setFilters={controllerProps.setFilters}
                                    filterValues={controllerProps.filterValues}
                                    onChange={() => setSelectedId(null)}
                                    type="users"
                                />
                                {[ROLES.admin.id.toString()].includes(permissions) ?
                                    <CreateButton resource={resource} /> : null
                                }
                                <Card>
                                    <Datagrid
                                        {...controllerProps}
                                        rowStyle={rowStyle}
                                        rowClick={(id: string) => {
                                            if([ROLES.admin.id.toString()].includes(permissions)) {
                                                setSelectedId(id);
                                            }
                                        }}
                                    >
                                        <TextField source="firstname" />
                                        <TextField source="lastname" />
                                        <TextField source="email" />
                                        <TextField source="language" />
                                    </Datagrid>
                                    <Pagination
                                        {...controllerProps}
                                        rowsPerPageOptions={[12, 24, 48, 96]}
                                    />
                                </Card>
                            </>
                        );
                    }}
                </ListController>

            </Grid>
        </Grid>
    );
}));

import {
    Button,
    Card, CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    CircularProgress,
    Typography,
    withStyles
} from '@material-ui/core';
import React, { useState } from 'react';
import { InterfaceFilters } from '../../types';
import { DOWNLOAD_URL } from '../../utils/config';
import { GET_ONE, translate, withDataProvider } from 'react-admin';
import { Transformation } from './types/Transformation';
import { connect } from 'react-redux';

const styles = {
    card: {
        display: 'flex'
    },
    details: {
        display: 'flex',
        flexDirection: 'column' as 'column'
    },
    content: {
        flex: '1'
    },
    MuiCardActionArea: {
        display: 'flex'
    }
};

interface Props {
  classes?: any;
  dataProvider;
  ez_data?: any;
  galleryId?: string;
  handleAddTransformationToGallery?: CallableFunction;
  handleAddTransformationToVideo?: CallableFunction;
  handleAddTransformationToEventstream?: CallableFunction;
  history;
  raised: boolean;
  setFiltersCallback: (parameterValues: InterfaceFilters) => void;
  setSelectedTransformationId: CallableFunction;
  transformation: Transformation;
  translate: CallableFunction;
  videoID?: string;
}

export default withDataProvider(withStyles(styles)(translate(connect(
    ({ eZDataReducer }: {eZDataReducer: any}) => eZDataReducer,
    {}
)((props: Props) => {
    const {
        classes,
        dataProvider,
        ez_data,
        galleryId,
        handleAddTransformationToGallery,
        handleAddTransformationToVideo,
        handleAddTransformationToEventstream,
        setFiltersCallback,
        setSelectedTransformationId,
        transformation,
        videoID,
    } = props;

    const [
        handleAddToEzPublishLoader,
        setHandleAddToEzPublishLoader
    ] = useState(false);

    const [image] = useState(
        `${DOWNLOAD_URL()}/api/v1/transformation/${transformation.id}/preview`
    );

    const handleAddToEZPublish = () => {
        setHandleAddToEzPublishLoader(false);
        dataProvider(GET_ONE, 'asset', { id: transformation.assetID })
            .then(response => {
                return response.data;
            })
            .then(asset => {
                setHandleAddToEzPublishLoader(false);
                window.opener.postMessage(
                    {
                        payload: ez_data,
                        asset: asset,
                        transformation: transformation
                    },
                    '*'
                );
            });
    };

    return (
        <Card raised={props.raised}>
            <div className={classes.card}>
                {handleAddTransformationToEventstream ?
                    <CardActionArea onClick={() => handleAddTransformationToEventstream(transformation)} className={classes.MuiCardActionArea}>
                        <CardContent className={classes.content}>
                            <div>
                                <Typography variant="caption">{transformation.id}</Typography>
                            </div>
                            <div>
                                <Typography variant="caption">{new Date(transformation.updatedAt).toLocaleString()}</Typography>
                            </div>
                            <div style={{ fontSize: '0.7em' }}>
                                {String(transformation.parameters && transformation.parameters.filters)}
                                {transformation.parameters && transformation.parameters.smart ? ' smart' : ' nosmart'}
                            </div>
                        </CardContent>

                        <CardMedia
                            image={image}
                            title="Live from space album cover"
                            style={{ width: 80, height: 80 }}
                        />
                    </CardActionArea>
                    :
                    <React.Fragment>
                        <CardContent className={classes.content}>
                            <div>
                                <Typography variant="caption">{transformation.id}</Typography>
                            </div>
                            <div>
                                <Typography variant="caption">{new Date(transformation.updatedAt).toLocaleString()}</Typography>
                            </div>
                            <div style={{ fontSize: '0.7em' }}>
                                {String(transformation.parameters && transformation.parameters.filters)}
                                {transformation.parameters && transformation.parameters.smart ? ' smart' : ' nosmart'}
                            </div>
                        </CardContent>

                        <CardMedia
                            image={image}
                            title="Live from space album cover"
                            style={{ width: 80, height: 80 }}
                        />
                    </React.Fragment>
                }

            </div>
            {!handleAddTransformationToEventstream &&
      <CardActions>
          <Button
              size="medium"
              color="primary"
              variant="contained"
              onClick={() => {
                  setSelectedTransformationId(transformation.id);
                  setFiltersCallback(transformation.filtersUpdate);
              }}
          >
              {props.translate('mam.transformation_edit')}
          </Button>
          {ez_data &&
          <Button
              size="small"
              color="default"
              variant="outlined"
              onClick={handleAddToEZPublish}
          >
              {props.translate('Add to CMS')}{' '}
              {handleAddToEzPublishLoader && (
                  <CircularProgress
                      size={18}
                      thickness={2}
                  />
              )}
          </Button>
          }
          {galleryId && handleAddTransformationToGallery &&
        <Button
            size="small"
            color="default"
            variant="outlined"
            onClick={() => {
                handleAddTransformationToGallery(
                    transformation.id,
                    galleryId
                );
            }}
        >
            {props.translate('resources.asset.action.add_to_gallery')}
        </Button>
          }
          {handleAddTransformationToVideo &&
        <Button
            size="small"
            color="default"
            variant="outlined"
            onClick={() => {
                handleAddTransformationToVideo(transformation, videoID);
            }}
        >
            {props.translate('resources.asset.action.add_to_video')}
        </Button>
          }
      </CardActions>
            }
        </Card>
    );
}))));

import { GET_LIST } from 'react-admin';

const convertDataToReportRequest = (type, params, apiUrl, options) => {
    let url;
    options.headers.append('Content-Type', 'application/json');
    switch (type) {
    case GET_LIST: {
        const { filter } = params;
        const month = filter.month || new Date().getMonth() + 1;
        const year = filter.year || new Date().getFullYear();

        const suffix = year && month ? `?year=${year}&month=${month}` : '';

        url = encodeURI(`${apiUrl}/report/imago${suffix}`);
        break;
    }
    }
    options.method = 'GET';

    return { url, options };
};

export default convertDataToReportRequest;
import React, { useState } from 'react';
import {DOWNLOAD_URL} from '../../utils/config';

interface Props {
  height: number;
  record?;
  transformationID?;
}

export default ({
    height,
    record,
    transformationID,
}: Props) => {
    const [error, setError] = useState(false);

    const width = Math.floor(height * (16 / 9));

    if (error) {
        return <div style={{
            border: '1px solid orange',
            display: 'inline-block',
        }}>
            <span style={{
                display: 'flex',
                flexDirection: 'column',
                height: height - 2,
                justifyContent: 'center',
                textAlign: 'center',
                width: width - 2,
            }}>
        Preview not yet available
            </span>
        </div>;
    }

    transformationID = transformationID || (record && record.transformationID);
    const videoID = record && record.id;
    let src;

    if (transformationID) {
        src = `${DOWNLOAD_URL()}/c/t/${transformationID}/${width}x${height}`;
    } else if (videoID) {
        src = `${DOWNLOAD_URL()}/api/v1/video/${videoID}/preview`;
    } else {
        return null;
    }

    return <img
        alt=""
        height={height}
        onError={() => setError(true)}
        src={src}
        width={width}
    />;
};

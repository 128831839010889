import { ListController, Pagination } from 'react-admin';
import GalleryGrid from './GalleryGrid';
import React from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import SearchPanel from '../searchPanel/SearchPanel';
import { CreateGalleryButton } from './CreateGalleryButton';

const GalleryList = props => {
    return (
        <ListController {...props} resource={'gallery'} perPage={12}>
            {controllerProps => {
                return (
                    <>
                        <SearchPanel
                            initialOpen={true}
                            setFilters={controllerProps.setFilters}
                            filterValues={controllerProps.filterValues}
                            type="gallery"
                        />
                        {controllerProps.isLoading ? (
                            <Grid container spacing={8}>
                                <Grid
                                    item
                                    xs={12}
                                    style={{ textAlign: 'center', marginTop: 70 }}
                                >
                                    <CircularProgress />
                                </Grid>
                            </Grid>
                        ) : (
                            <>
                                <CreateGalleryButton {...controllerProps} />
                                <GalleryGrid
                                    {...controllerProps}
                                    hasEdit={props.hasEdit !== false}
                                />
                                <Pagination
                                    {...controllerProps}
                                    rowsPerPageOptions={[12, 24, 48, 96]}
                                />
                            </>
                        )}
                    </>
                );
            }}
        </ListController>
    );
};

export default GalleryList;

const isValidDate = (date: string | Date):boolean => {
    let d = date;

    if ('string' === typeof date) {
        d = new Date(d);
    }

    if (d instanceof Date) {
        return !Number.isNaN(Number(d));
    }

    return false;
};

export default isValidDate;

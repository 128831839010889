import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, GridList, GridListTile, GridListTileBar, IconButton, Typography, Container, Avatar } from '@material-ui/core';
import { translate, withDataProvider, useAuthenticated, GET_LIST } from 'react-admin';
import StatsTopTag from './StatsTopTag';
import StatsCounter from './StatsCounter';
import abbreviate from 'number-abbreviate';
import { Stats, TagCount, AssetInterface, GalleryInterface, VideoInterface } from '../../types';
import { DOWNLOAD_URL } from '../../utils/config';
//Icons
import { Image, VideoLibrary, Filter9Plus, Forward, TagFaces } from '@material-ui/icons';

const styles = {
    mediaGrid: {
        display: 'flex',
        flexWrap: 'wrap' as 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: 'white',
    },
    mediaGridList: {
        flexWrap: 'nowrap' as 'nowrap',
        transform: 'translateZ(0)',
    },
    mediaTitle: {
        color: 'white',
    },
    mediaTitleBar: {
        background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    avatar: {
        backgroundColor: 'blue',
        height: 56,
        width: 56
    },
    gridLatest: {
        height: '100%',
        marginLeft: 30,
        marginTop: 20,
        marginRight: 65
    },
    innerGrid: {
        backgroundColor: 'white',
    }
};

export const Dashboard = withStyles(styles)(
    withDataProvider(
        translate(({ dataProvider, translate, classes }) => {
            const initialStats: Stats = {
                count: 0,
                countLast30Days: 0,
            };
            const [statsImages, setStatsImages] = useState<Stats>(initialStats);
            const [statsGalleries, setStatsGalleries] = useState<Stats>(initialStats);
            const [statsVideos, setStatsVideos] = useState<Stats>(initialStats);
            const [assetTags, setAssetTags] = useState<[TagCount] | undefined>(undefined);
            const [galleryTags, setGalleryTags] = useState<[TagCount] | undefined>(undefined);
            const [images, setImages] = useState<[AssetInterface] | undefined>(undefined);
            const [galleries, setGalleries] = useState<[GalleryInterface] | undefined>(undefined);
            const [videos, setVideos] = useState<[VideoInterface] | undefined>(undefined);

            useEffect(() => {
                dataProvider('IMAGES', 'statistics', {})
                    .then(response => {
                        setStatsImages(response.data);
                    })
                    .catch(error => console.error(error));
            }, [dataProvider]);

            useEffect(() => {
                dataProvider('GALLERIES', 'statistics', {})
                    .then(response => {
                        setStatsGalleries(response.data);
                    })
                    .catch(error => console.error(error));
            }, [dataProvider]);

            useEffect(() => {
                dataProvider('VIDEOS', 'statistics', {})
                    .then(response => {
                        setStatsVideos(response.data);
                    })
                    .catch(error => console.error(error));
            }, [dataProvider]);

            useEffect(() => {
                dataProvider('ASSET_TAG', 'statistics', {})
                    .then(response => {
                        setAssetTags(response.data);
                    })
                    .catch(error => console.error(error));
            }, [dataProvider]);

            useEffect(() => {
                dataProvider('GALLERY_TAG', 'statistics', {})
                    .then(response => {
                        setGalleryTags(response.data);
                    })
                    .catch(error => console.error(error));
            }, [dataProvider]);

            useEffect(() => {
                dataProvider(GET_LIST, 'asset', { pagination: { page: 1, perPage: 12 } })
                    .then(response => {
                        setImages(response.data);
                    })
                    .catch(error => console.error(error));
            }, [dataProvider]);

            useEffect(() => {
                dataProvider(GET_LIST, 'gallery', { pagination: { page: 1, perPage: 12 } })
                    .then(response => {
                        setGalleries(response.data);
                    })
                    .catch(error => console.error(error));
            }, [dataProvider]);

            useEffect(() => {
                dataProvider(GET_LIST, 'video', { pagination: { page: 1, perPage: 12 } })
                    .then(response => {
                        setVideos(response.data);
                    })
                    .catch(error => console.error(error));
            }, [dataProvider]);

            useAuthenticated();

            const imageIcon = () => {
                return (<Image />);
            };

            const galleryIcon = () => {
                return (<Filter9Plus />);
            };

            const videoIcon = () => {
                return (<VideoLibrary />);
            };

            return (
                <div className={classes.root}>
                    <Grid
                        container
                        spacing={8}
                        className={classes.grid}>
                        <Grid container item xs={12} spacing={4}>
                            <Grid
                                item
                                xs={4}>
                                <StatsCounter mainText={translate('mam.dashboard.images')} mainStat={abbreviate(Number(statsImages.count), 0)} mainImage={imageIcon()} secondaryText={translate('mam.dashboard.last_30_days')} secondaryStat={abbreviate(Number(statsImages.countLast30Days), 2)} />
                            </Grid>
                            <Grid
                                item
                                xs={4}>
                                <StatsCounter mainText={translate('mam.dashboard.gallery')} mainStat={abbreviate(Number(statsGalleries.count), 0)} mainImage={galleryIcon()} secondaryText={translate('mam.dashboard.last_30_days')} secondaryStat={abbreviate(Number(statsGalleries.countLast30Days), 2)} />
                            </Grid>
                            <Grid
                                item
                                xs={4}>
                                <StatsCounter mainText={translate('mam.dashboard.videos')} mainStat={abbreviate(Number(statsVideos.count), 0)} mainImage={videoIcon()} secondaryText={translate('mam.dashboard.last_30_days')} secondaryStat={abbreviate(Number(statsVideos.countLast30Days), 2)} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={4} className={classes.gridLatest}>
                            <Grid item xs={12} className={classes.innerGrid}>
                                <Grid container justify="center" spacing={2}>
                                    <Grid item xs={2}>
                                        <Typography>{translate('Latest Images')}</Typography>
                                        <Avatar className={classes.avatar}>{imageIcon()}</Avatar>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Container>
                                            <GridList className={classes.mediaGridList} cols={6}>
                                                {images && images.map((image) => (
                                                    <GridListTile key={image.id}>
                                                        <img src={DOWNLOAD_URL() + '/c/t/' + image.id + '/620x465'} alt={image.title} />
                                                        <GridListTileBar
                                                            title={image.title}
                                                            classes={{
                                                                root: classes.mediaTitleBar,
                                                                title: classes.mediaTitle,
                                                            }}
                                                            actionIcon={
                                                                <IconButton href={'/admin#/asset/' + image.id}>
                                                                    <Forward className={classes.mediaTitle} />
                                                                </IconButton>
                                                            }
                                                        />
                                                    </GridListTile>
                                                ))}
                                            </GridList>
                                        </Container>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={4} className={classes.gridLatest}>
                            <Grid item xs={12} className={classes.innerGrid}>
                                <Grid container justify="center" spacing={2} >
                                    <Grid item xs={8}>
                                        <Container>
                                            <GridList className={classes.mediaGridList} cols={6}>
                                                {galleries && galleries.map((gallery) => (
                                                    <GridListTile key={gallery.id}>
                                                        <img src={DOWNLOAD_URL() + '/api/v1/gallery/' + gallery.id + '/preview'} alt={gallery.title} />
                                                        <GridListTileBar
                                                            title={gallery.title}
                                                            classes={{
                                                                root: classes.mediaTitleBar,
                                                                title: classes.mediaTitle,
                                                            }}
                                                            actionIcon={
                                                                <IconButton href={'/admin#/gallery/' + gallery.id}>
                                                                    <Forward className={classes.mediaTitle} />
                                                                </IconButton>
                                                            }
                                                        />
                                                    </GridListTile>
                                                ))}
                                            </GridList>
                                        </Container>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography>{translate('Latest Galleries')}</Typography>
                                        <Avatar className={classes.avatar}>{galleryIcon()}</Avatar>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={4} className={classes.gridLatest}>
                            <Grid item xs={12} className={classes.innerGrid}>
                                <Grid container justify="center" spacing={2}>
                                    <Grid item xs={2}>
                                        <Typography>{translate('Latest Videos')}</Typography>
                                        <Avatar className={classes.avatar}>{videoIcon()}</Avatar>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Container>
                                            <GridList className={classes.mediaGridList} cols={6}>
                                                {videos && videos.map((video) => (
                                                    <GridListTile key={video.id} style={{backgroundColor: '#ccc'}}>
                                                        {video.transformationID ? <img src={DOWNLOAD_URL() + '/c/t/' + video.transformationID + '/620x465'} alt={video.title} /> : null}
                                                        <GridListTileBar
                                                            title={video.title}
                                                            classes={{
                                                                root: classes.mediaTitleBar,
                                                                title: classes.mediaTitle,
                                                            }}
                                                            actionIcon={
                                                                <IconButton href={'/admin#/video/' + video.id}>
                                                                    <Forward className={classes.mediaTitle} />
                                                                </IconButton>
                                                            }
                                                        />
                                                    </GridListTile>
                                                ))}
                                            </GridList>
                                        </Container>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} spacing={4} className={classes.bottomContent}>
                            <Grid container item xs={6} >
                                <Grid
                                    item
                                    xs={12}>
                                    <StatsTopTag tags={assetTags} title={translate('Top Asset Tags')} prefix={'asset'} icon={TagFaces}/>
                                </Grid>
                            </Grid>
                            <Grid container item xs={6}  >
                                <Grid container item xs={12} >
                                    <Grid
                                        item
                                        xs={12}>
                                        <StatsTopTag tags={galleryTags} title={translate('Top Tags Galleries')} prefix={'gallery'} icon={Filter9Plus} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            );
        })
    )
);

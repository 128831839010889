import { httpRequestObject, OptionsInterface } from './httpRequestObjects';
import {
    GET_LIST,
    GET_ONE,
    UPDATE
} from 'react-admin';

export const convertDataToOttStreamRequest = (
    type,
    params,
    apiUrl,
    options: OptionsInterface
): httpRequestObject => {
    let url;
    const resource = 'ott_stream';
  
    options.method = 'GET';
    switch (type) {
    case GET_LIST: {
        url = encodeURI(`${apiUrl}/${resource}`);
        break;
    }
    case GET_ONE: {
        url = `${apiUrl}/${resource}/${params.id}`;
        break;
    }

    case UPDATE: {
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'PUT';
        options.headers.append('Content-Type', 'application/json');
        options.body = JSON.stringify(params.data);
        break;
    }
    }
  
    return { url, options };
};

export const convertDataToOttIndexRequest = (
    type,
    params,
    apiUrl,
    options: OptionsInterface
): httpRequestObject => {
    let url;
    const resource = 'ott_index';
  
    options.method = 'GET';
    switch (type) {
    case GET_LIST: {
        url = encodeURI(`${apiUrl}/${resource}`);
        break;
    }
    case GET_ONE: {
        url = `${apiUrl}/${resource}/${params.id}`;
        break;
    }

    case UPDATE: {
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'PUT';
        options.headers.append('Content-Type', 'application/json');
        options.body = JSON.stringify(params.data);
        break;
    }
    }
  
    return { url, options };
};

export const convertDataToOttAppStreamsRequest = (
    type,
    params,
    apiUrl,
    options: OptionsInterface
): httpRequestObject => {
    let url;
    const resource = 'ott_app_streams';
  
    options.method = 'GET';
    switch (type) {
    case GET_LIST: {
        url = encodeURI(`${apiUrl}/${resource}`);
        break;
    }
    case GET_ONE: {
        url = `${apiUrl}/${resource}/${params.id}`;
        break;
    }

    case UPDATE: {
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'PUT';
        options.headers.append('Content-Type', 'application/json');
        options.body = JSON.stringify(params.data);
        break;
    }
    }
  
    return { url, options };
};

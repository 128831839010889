import * as React from 'react';
import { useEffect, useState } from 'react';
import { GET_LIST, Loading, translate, withDataProvider } from 'react-admin';
import { AnyAction, Dispatch } from 'redux';
import { DataProvider } from 'ra-core';
import { Button, Card, Grid } from '@material-ui/core';
import { DELAY } from '../../utils/config';
import { GettyCardMediaWithPreview } from './GettyCardMediaWithPreview';
import { GettyImagesInterface } from '../../types';

interface DispatchProps {
  dataProvider?: DataProvider;
  dispatch?: Dispatch<AnyAction>;
  keyword: string;
  handleResultCount: any;
  handlePickImage: any;
}

const cardStyle = {
    width: '100%',
    cursor: 'pointer'
};

const cardMediaStyle = {
    paddingTop: '100%'
};

export const GettyImagesList: React.FunctionComponent<
  DispatchProps
> = translate(
    withDataProvider(props => {
        const { keyword, dataProvider, translate, handleResultCount } = props;
        const imagesInit: GettyImagesInterface[] = [];
        const [images, setImages] = useState(imagesInit);
        const [resultCount, setResultCount] = useState(0);
        const [page, setPage] = useState(1);
        const [isLoading, setIsLoading] = useState(false);

        useEffect(() => {
            if (keyword.length < 2) {
                setPage(1);
                return;
            }
            setIsLoading(true);
            const timer = setTimeout(
                () =>
                    dataProvider(GET_LIST, 'getty', {
                        keyword: keyword,
                        page: page
                    })
                        .then(
                            (response: { data: GettyImagesInterface[]; total: number }) => {
                                setImages(images.concat(response.data));
                                setResultCount(response.total);
                                setIsLoading(false);
                            }
                        )
                        .catch(error => console.error(error)),
                DELAY
            );

            return () => {
                clearTimeout(timer);
            };
        }, [dataProvider, images, keyword, page]);

        useEffect(() => {
            setImages([]);
            if (keyword.length < 2) {
                setIsLoading(false);
                setPage(1);
                return;
            }
            setIsLoading(true);
            const timer = setTimeout(
                () =>
                    dataProvider(GET_LIST, 'getty', {
                        keyword: keyword,
                        page: page
                    })
                        .then(
                            (response: { data: GettyImagesInterface[]; total: number }) => {
                                setImages(response.data);
                                setResultCount(response.total);
                                handleResultCount(response.total);
                                setIsLoading(false);
                            }
                        )
                        .catch(error => console.error(error)),
                DELAY
            );

            return () => {
                clearTimeout(timer);
            };
        }, [dataProvider, handleResultCount, keyword, page]);

        const handleNextPage = () => {
            setPage(page + 1);
        };

        return (
            <>
                <Grid container spacing={1}>
                    {images.map((image, index) => (
                        <Grid key={`${image.id}-${index}`} item xs={1}>
                            <Card style={cardStyle}>
                                <GettyCardMediaWithPreview handlePickImage={props.handlePickImage} cardMediaStyle={cardMediaStyle} image={image} />
                            </Card>
                        </Grid>
                    ))}
                </Grid>
                <Grid container spacing={1} style={{ padding: 24 }}>
                    <Grid item xs style={{ textAlign: 'center' }}>
                        {isLoading ? (
                            <>
                                <Loading loadingPrimary={null} />
                                <img
                                    alt="Getty Images"
                                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Getty_Images_logo.svg/320px-Getty_Images_logo.svg.png"
                                />
                            </>
                        ) : (
                            keyword.length >= 2 &&
              (resultCount > images.length && (
                  <Button variant={'outlined'} onClick={handleNextPage}>
                      {translate('mam.load_more')}
                  </Button>
              ))
                        )}
                    </Grid>
                </Grid>
            </>
        );
    })
);

import React from 'react';
import {
    ListController,
    Datagrid,
    withDataProvider,
    TextField,
    Pagination,
    DateField
} from 'react-admin';
import { Grid, Card } from '@material-ui/core';
import SearchPanel from './SearchPanel';
import {
    withStyles,
    WithStyles,
    createStyles,
    Theme
} from '@material-ui/core/styles';


const styles = ({ spacing }: Theme) => createStyles({ });

interface Props extends WithStyles<typeof styles> {
    location;
}


export default withStyles(styles)(withDataProvider((props: Props) => {

    const {
        location,
    } = props;

    const rowStyle = (record, index: number) => {
        return record && {
            backgroundColor:
            location.pathname.indexOf(record.id) >= 0
                ? '#efe'
                : index % 2
                    ? 'white'
                    : '#f0f0f0f0'
        };
    };

    return(
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <ListController {...props} resource={'auditlog'} perPage={12}>
                    {controllerProps => {
                        return (
                            <>
                                <SearchPanel
                                    initialOpen={true}
                                    setFilters={controllerProps.setFilters}
                                    filterValues={controllerProps.filterValues}
                                    type="auditlog"
                                />
                                <Card>
                                    <Datagrid
                                        {...controllerProps}
                                        rowStyle={rowStyle}
                                    >
                                        <TextField source="changedBy" />
                                        <DateField source="changedOn" showTime />
                                        <TextField source="resource" />
                                        <TextField label="Resource ID" source="resourceID" />
                                        <TextField source="description" />
                                        <TextField source="what" />
                                    </Datagrid>
                                    <Pagination
                                        {...controllerProps}
                                        rowsPerPageOptions={[12, 24, 48, 96]}
                                    />
                                </Card>
                            </>
                        );
                    }}
                </ListController>

            </Grid>
        </Grid>
    );
}));

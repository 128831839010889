import React from 'react';
import {
    ListController,
    Datagrid,
    withDataProvider,
    TextField,
    Pagination,
    EditButton,
    BooleanField,
} from 'react-admin';
import { Grid, Card } from '@material-ui/core';
import SearchPanel from '../searchPanel/SearchPanel';
import {
    createStyles,
    withStyles,
    WithStyles,
    Theme
} from '@material-ui/core/styles';

const styles = ({ spacing }: Theme) => createStyles({ });

interface Props extends WithStyles<typeof styles> {
    basePath: string;
    location;
    match;
    permissions;
    resource: string;
}

export default withStyles(styles)(withDataProvider((props: Props) => {
    const {
        location,
    } = props;

    const rowStyle = (record, index: number) => {
        return record && {
            backgroundColor:
                location.pathname.indexOf(record.id) >= 0
                    ? '#efe'
                    : index % 2
                        ? 'white'
                        : '#f0f0f0f0'
        };
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <ListController {...props} resource={'tags'} perPage={12}>
                    {controllerProps => {
                        return (
                            <>
                                <SearchPanel
                                    initialOpen={true}
                                    setFilters={controllerProps.setFilters}
                                    filterValues={controllerProps.filterValues}
                                    type="users"
                                />
                                <Card>
                                    <Datagrid
                                        {...controllerProps}
                                        rowStyle={rowStyle}
                                    >
                                        <TextField source="tag" />
                                        <TextField source="externalID" />
                                        <TextField source="source" />
                                        <BooleanField source="approved" />
                                        <TextField source="sportID" />
                                        <EditButton />
                                    </Datagrid>
                                    <Pagination
                                        {...controllerProps}
                                        rowsPerPageOptions={[12, 24, 48, 96]}
                                    />
                                </Card>
                            </>
                        );
                    }}
                </ListController>
            </Grid>
        </Grid>
    );
}));

import {httpRequestObject} from './httpRequestObjects';
import {CREATE, DELETE, GET_LIST, GET_ONE, UPDATE} from 'react-admin';
import { countries } from '../components/videos/select_data';
import transformOptions from './transformOptions';
import { ConvertPrimitivesToAssetTransformations } from './converAssetTransformations';

export default (type, params, apiUrl, options): httpRequestObject => {
    let url;
    const resource = 'video';
    options.headers.append('Content-Type', 'application/json');

    switch (type) {
    case CREATE: {
        url = encodeURI(`${apiUrl}/${resource}`);
        options.method = 'POST';
        const video = JSON.parse(JSON.stringify(params));
        if (video.countries) {
            video.countries = video.countries
                .map((code) => countries.find((country) => country.code === code));
        }

        if (!video.vast) {
            video.vast = {
                videoID: params.id,
                key: 'VASTroll',
                value: {
                    preroll: 'yes',
                    midroll: 'yes',
                    postroll: 'yes',
                },
            };
        }

        options.body = JSON.stringify(video);
        break;
    }

    case DELETE: {
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'DELETE';
        break;
    }

    case GET_LIST: {
        const {filter = {}} = params;
        const {page = 1, perPage = 12} = params.pagination;

        const id = filter.id
            ? `&id=${filter.id}`
            : '';

        const deleted = filter.deleted
            ? '&deleted=true'
            : '';

        const tags = filter.tags && filter.tags.length
            ? '&tags=' + filter.tags.map(tag => tag.value).join(',')
            : '';

        const text = filter.text
            ? `&text=${filter.text}`
            : '';

        const updated = filter.timeWhenValue && filter.timeWhenValue.value !== 'any'
            ? `&updated=${filter.timeWhenValue.value}`
            : '';

        url = encodeURI(`${apiUrl}/${resource}/all?page=${page}&per=${perPage}&withUnavailable=true${deleted}${id}${tags}${text}${updated}`);
        options.method = 'POST';
        break;
    }

    case GET_ONE: {
        url = encodeURI(`${apiUrl}/${resource}/every/${params.id}`);
        options.method = 'POST';
        break;
    }

    case 'GET_TRANSFORMATIONS': {
        url = encodeURI(`${apiUrl}/${resource}/${params.id}/transformations`);
        break;
    }

    case 'RESTORE': {
        url = encodeURI(`${apiUrl}/${resource}/${params.id}/restore`);
        options.method = 'PATCH';
        break;
    }

    case UPDATE: {
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'PUT';
        const video = JSON.parse(JSON.stringify(params.data));
        delete video.GOT_ONE_COMPLETELY; // remove, it's just a frontend state

        const meta = (data, key) => {
            if (data) {
                data.videoID = params.id;
                data.key = key;
            }
        };

        meta(video.link, 'link');
        meta(video.tracking, 'tracking');
        meta(video.vast, 'VASTroll');

        if (!video.vast) {
            video.vast = {
                videoID: params.id,
                key: 'VASTroll',
                value: {
                    preroll: 'yes',
                    midroll: 'yes',
                    postroll: 'yes',
                },
            };
        }

        if (video.countries) {
            video.countries = video.countries
                .map((code) => countries.find((country) => country.code === code));
        }

        if (video.episode) {
            video.episode = transformOptions.optionToEpisode(video.episode);
            if ('string' === typeof video.episode.id) {
                // a new episode
                delete video.episode.id;
            }
        }

        if (video.tags) {
            video.tags.forEach((tag) => {
                if ('string' === typeof tag.id) {
                    // a new tag
                    delete tag.id;
                }
            });
        }

        ConvertPrimitivesToAssetTransformations(video, 'video');

        options.body = JSON.stringify(video);
        break;
    }

    case 'UPDATE_THUMBNAIL': {
        const type = params.assetID ? 'asset' : 'transformation';
        const id = params.assetID || params.transformationID;
        url = encodeURI(`${apiUrl}/${resource}/thumbnail/${params.id}/${type}/${id}`);
        options.method = 'PATCH';
        break;
    }

    case 'GET_COMPETITIONS': {
        url = encodeURI(`${apiUrl}/competitions`);
        options.body = JSON.stringify(params);
        options.method = 'POST';
        break;
    }

    case 'GET_MATCHES': {
        url = encodeURI(`${apiUrl}/matches`);
        options.body = JSON.stringify(params);
        options.method = 'POST';
        break;
    }
    case 'GET_SEASONS_BY_COMPETITION': {
        url = encodeURI(`${apiUrl}/seasons`);
        options.body = JSON.stringify(params);
        options.method = 'POST';
        break;
    }
    }

    return {url, options};
};

import { httpRequestObject, OptionsInterface } from './httpRequestObjects';

export const convertDataToTransformationRequest = (
    type,
    params,
    apiUrl,
    options: OptionsInterface
): httpRequestObject => {
    let url;
    const resource = 'transformation';

    url = encodeURI(`${apiUrl}/${resource}/${params.id}`);
    options.method = 'GET';

    return { url, options };
};

import {httpRequestObject} from './httpRequestObjects';
import {GET_LIST} from 'react-admin';

export default (
    type,
    params,
    apiUrl,
    options
): httpRequestObject => {
    if (type !== GET_LIST) {
        throw new Error('Unsupported type for episode: ' + type);
    }

    options.headers.append('Content-Type', 'application/json');
    options.method = 'POST';

    const { page = 1, perPage = 10 } = params.pagination;
    const { text } = params;
    const url = encodeURI(`${apiUrl}/episode/all?page=${page}&per=${perPage}&title=ct:${text}`);
    return {url, options};
};

import React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    withDataProvider,
    setListSelectedIds as setListSelectedIdsAction
} from 'react-admin';
import GalleryAddAssetDialog from './GalleryAddAssetDialog';
import { connect, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

export const GalleryCreate: React.FC = connect(
    mapStateToProps,
    { setSelectedIds: setListSelectedIdsAction }
)(
    withDataProvider(props => {
        const dispatch = useDispatch();

        const handleClose = () => {
            dispatch(push('/gallery'));
        };

        return (
            <Create {...props}>
                <SimpleForm>
                    <GalleryAddAssetDialog
                        openDialog={true}
                        setOpenDialog={handleClose}
                        {...props}
                    />
                    <TextInput source="title" />
                </SimpleForm>
            </Create>
        );
    })
);

function mapStateToProps(state) {
    const resourceState = state.admin.resources['asset'];
    return {
        selectedIds: resourceState.list.selectedIds,
        isLoading: state.admin.loading > 0
    };
}

import React from 'react';
import {
    CREATE,
    translate,
    withDataProvider,
} from 'react-admin';
import {
    Fab,
    Tooltip,
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Add';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

export default translate(withDataProvider(({
    dataProvider,
    translate,
}) => {
    const dispatch = useDispatch();
    return <Tooltip title={translate('resources.video.action.create')}>
        <Fab
            color="primary"
            onClick={() => {
                dataProvider(
                    CREATE,
                    'video',
                    {
                        approved: false,
                        complete: false,
                        publishedAt: Date.now(),
                    },
                    {
                        onSuccess: {
                            notification: {
                                body: 'resources.video.notification.created',
                                info: 'info',
                            },
                            refresh: true,
                        },
                        onError: {
                            notification: {
                                body: 'resources.playlist.notification.creation_failed',
                                info: 'warning',
                            },
                            refresh: false,
                        },
                    })
                    .then((response) => dispatch(push(`/video/${response.data.id}`)));
            }}
        >
            <CreateIcon />
        </Fab>
    </Tooltip>;
}));

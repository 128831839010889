import React, { useState } from 'react';
import { CardMedia, Tooltip } from '@material-ui/core';
import { GettyImagePreview } from './GettyImagePreview';
import { GettyImagesInterface } from '../../types';
import { translate } from 'react-admin';

interface GettyCardMediaWithPreviewProps {
  cardMediaStyle;
  image: GettyImagesInterface;
  handlePickImage: (imageId: string) => void;
}

export const GettyCardMediaWithPreview: React.FC<
  GettyCardMediaWithPreviewProps
> = translate(({ cardMediaStyle, image, handlePickImage, translate }) => {
    const initAnchorEl: any = null;
    const [anchorEl, setAnchorEl] = useState(initAnchorEl);

    return (
        <div>
            <Tooltip
                title={translate('resources.getty.click_to_upload')}
                placement={'top'}
            >
                <CardMedia
                    aria-haspopup="true"
                    onClick={() => {
                        handlePickImage(image.id);
                    }}
                    onMouseLeave={() => {
                        setAnchorEl(null);
                    }}
                    onMouseEnter={e => {
                        if (e.currentTarget) {
                            setAnchorEl(e.currentTarget);
                        }
                    }}
                    style={cardMediaStyle}
                    image={image.display_sizes[0].uri}
                />
            </Tooltip>
            <GettyImagePreview image={image} anchorEl={anchorEl} />
        </div>
    );
});

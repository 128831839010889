import React from 'react';
import {
    BooleanField,
    Datagrid,
    EditButton,
    List,
    TextField,
} from 'react-admin'; // eslint-disable-line import/no-unresolved

const EpgChannelList = props =>
    <List bulkActionButtons={false} {...props}>
        <Datagrid>
            <TextField sortable={false} source="id" />
            <TextField sortable={false} source="name" />
            <TextField sortable={false} source="xmlTvId"/>
            <TextField sortable={false} source="transformationId"/>
            <TextField sortable={false} source="source" />
            <BooleanField sortable={false} source="isHighlight" />
            <EditButton />
        </Datagrid>
    </List>;

export default EpgChannelList;

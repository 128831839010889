import React, { useEffect, useState } from 'react';
import {
    BooleanField,
    Datagrid,
    DateField,
    ListController,
    Pagination,
    TextField,
    withDataProvider
} from 'react-admin';
import { Grid, Paper } from '@material-ui/core';
import {
    createStyles,
    withStyles,
    WithStyles,
    Theme
} from '@material-ui/core/styles';
//import { VideoListAside } from "./VideoListAside";
import SearchPanel from '../searchPanel/SearchPanel';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import VideoCreateButton from './VideoCreateButton';
import VideoThumbnail from './VideoThumbnail';

const styles = ({ spacing }: Theme) =>
    createStyles({
        VideoListPaper: {
            padding: spacing(1)
        },
    });

interface Props extends WithStyles<typeof styles> {
  id;
  location;
  match;
  resource: string;
}

export default withStyles(styles)(withDataProvider((props: Props) => {
    const [selectedId, setSelectedId] = useState<string | null>();
    const {
        classes,
        id,
        location,
    } = props;

    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedId) {
            dispatch(push(`/video/${selectedId}`));
        }
        else {
            dispatch(push('/video'));
        }
    }, [dispatch, id, selectedId]);

    const rowStyle = (record, index: number) => {
        return record && {
            backgroundColor:
        location.pathname.indexOf(record.id) >= 0
            ? '#efe'
            : index % 2
                ? 'white'
                : '#f0f0f0f0'
        };
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Paper className={classes.VideoListPaper}>
                    <ListController
                        {...props}
                        resource={'video'}
                        perPage={12}
                        exporter={false}
                    >
                        {controllerProps => {
                            return (
                                <>
                                    <SearchPanel
                                        initialOpen={true}
                                        setFilters={controllerProps.setFilters}
                                        filterValues={controllerProps.filterValues}
                                        onChange={() => setSelectedId(null)}
                                        type="video"
                                    />
                                    <VideoCreateButton />
                                    <Datagrid
                                        {...controllerProps}
                                        rowStyle={rowStyle}
                                        rowClick={(id: string) => {
                                            setSelectedId(id);
                                        }}
                                    >
                                        <BooleanField
                                            source="approved"
                                        />

                                        <VideoThumbnail
                                            height={72}
                                        />

                                        <TextField
                                            source="title"
                                        />

                                        <DateField
                                            source="publishedAt"
                                            showTime={true}
                                        />
                                    </Datagrid>
                                    <Pagination
                                        {...controllerProps}
                                        rowsPerPageOptions={[12, 24, 48, 96]}
                                    />
                                </>
                            );
                        }}
                    </ListController>
                </Paper>
            </Grid>
        </Grid>
    );
}));

export const userPermissions = [
    { id: 0, name: 'admin' },
    { id: 1, name: 'moderator' },
    { id: 2, name: 'standard' },
    { id: 3, name: 'assetRW' },
    { id: 4, name: 'assetRO' },
    { id: 5, name: 'ottRW' },
    { id: 6, name: 'ottRO' },
    { id: 7, name: 'videoadmin' },
    { id: 8, name: 'videoStandard' },
    { id: 9, name: 'videoEditor' },
    { id: 10, name:'superEditor'}
];

const areas = [
    {
        color: 'red',
        width: 16,
        height: 9
    },
    {
        color: 'blue',
        width: 3,
        height: 2
    },
    {
        color: 'green',
        width: 1,
        height: 1
    }
];

export interface InterfaceGrid {
  color: string;
  width: number;
  height: number;
  top: number;
  left: number;
}

export const settingPictureBorders = function(
    imageWidth,
    imageHeight,
    position: { top: number; left: number }
): InterfaceGrid[] {
    let img = {
        width: imageWidth,
        height: imageHeight
    };
    let pictureBorders: any[] = [];
    let border_width = 0;
    let replace = {
        top: 13,
        left: 13
    };

    areas.forEach(function(area) {
        let crop = getCropDisplaySize(img, area);
        pictureBorders.push({
            color: area.color,
            width: crop.width - border_width * 2,
            height: crop.height - border_width * 2,
            top:  setPosition(crop.height, position.top + replace.top, img.height),
            left: setPosition(crop.width, position.left + replace.left, img.width)
        });
    });

    return pictureBorders;
};

const getCropDisplaySize = function(image, target) {
    let image_ratio = image.width / image.height,
        target_ratio = target.width / target.height,
        crop = {
            width: image.width,
            height: image.height
        };

    if (image_ratio > target_ratio) {
        crop.width = (image.height / target.height) * target.width;
    } else {
        crop.height = (image.width / target.width) * target.height;
    }
    return crop;
};

const setPosition = function(frameSize, pos, maxValue) {
    let half = Math.floor(frameSize / 2),
        min = pos - half,
        max = pos + half;

    if (min <= 0) {
        return 0;
    }
    if (max >= maxValue) {
        return maxValue - frameSize;
    }
    return min;
};

import React from 'react';
import {
    BooleanInput,
    DeleteButton,
    Edit,
    fetchEnd,
    fetchStart,
    FormDataConsumer,
    SimpleForm,
    TextInput,
    Toolbar,
    translate,
    UPDATE,
    useNotify,
    useRefresh,
    withDataProvider,
} from 'react-admin';
import {
    Paper,
} from '@material-ui/core';
import {
    createStyles,
    withStyles,
    WithStyles,
} from '@material-ui/core/styles';
import {Field} from 'react-final-form';
import {BackButton} from '../buttons/BackButton';
import TagWrapper from '../TagWrapper';
import PlaylistVideos from './PlaylistVideos';
import AutoSave from '../../utils/AutoSave';
import {ROLES} from '../../utils/config';
import {useDispatch} from 'react-redux';
import VideoThumbnails from '../videos/VideoThumbnails';

const styles = () =>
    createStyles({});

interface Props extends WithStyles<typeof styles> {
  basePath;
  dataProvider;
  history;
  id;
  location;
  resource;
  translate;
  permissions;
}

export default withStyles(styles)(translate(withDataProvider((props: Props) => {
    const {
        basePath,
        dataProvider,
        history,
        id,
        location,
        resource,
        translate,
        permissions
    } = props;

    const notify = useNotify();
    const refresh = useRefresh();
    const dispatch = useDispatch();

    const save = (playlist, difference) => {
    // refresh only if there has been a change in the autofill logic
        const shallRefresh = Boolean(
            difference.autoFill === true
      || (playlist.autoFill === true && difference.tags)
        );

        if (shallRefresh) {
            dispatch(fetchStart());
        }

        dataProvider(UPDATE, 'playlist', playlist)
            .then(() => {
                notify('resources.playlist.notification.updated');
                if (shallRefresh) {
                    refresh();
                    dispatch(fetchEnd());
                }
            });
    };

    return <>
        <Paper>
            <Edit
                undoable={false}
                id={id}
                basePath={basePath}
                location={location}
                resource={resource}
            >
                <SimpleForm
                    elevation={0}
                    toolbar={
                        <Toolbar
                            basePath={basePath}
                            location={location}
                            resource={resource}
                        >
                            <BackButton
                                history={history}
                                path="/playlist"
                                label={translate('ra.action.back')}
                            />
                            {[ROLES.admin.id.toString(), ROLES.videoAdmin.id.toString(), ROLES.superEditor.id.toString()].includes(permissions) ?
                                <DeleteButton undoable={false}/> : null
                            }

                        </Toolbar>
                    }
                >
                    <AutoSave save={save}/>
                    <FormDataConsumer>
                        {formDataProps => <>
                            <TextInput
                                fullWidth
                                resettable
                                source="title"
                            />

                            <TextInput
                                fullWidth
                                multiline
                                resettable
                                source="description"
                            />

                            <TextInput
                                fullWidth
                                resettable
                                source="importID"
                            />

                            <BooleanInput
                                source="autoFill"
                            />

                            <Field name="tags">
                                {props => (
                                    <TagWrapper
                                        selectedTags={props.input.value && props.input.value.map(tagEl => ({
                                            ...tagEl,
                                            label: tagEl.tag,
                                            value: tagEl.id
                                        }))}
                                        onChange={props.input.onChange}
                                        permissions={permissions}
                                        {...formDataProps}
                                    />
                                )}
                            </Field>

                            <hr />

                            <Field name="assetTransformations_sport1tv_landscape">
                                {(props) => (
                                    <VideoThumbnails
                                        transformationID={props.input.value}
                                        onChange={props.input.onChange}
                                        allowTransformationPicking={false}
                                        {...formDataProps}
                                    />
                                )}
                            </Field>

                            <TextInput
                                fullWidth
                                disabled
                                source="assetTransformations_sport1tv_landscape"
                            />

                            <hr />

                            <Field name="assetTransformations_sport1tv_hero">
                                {(props) => (
                                    <VideoThumbnails
                                        transformationID={props.input.value}
                                        onChange={props.input.onChange}
                                        allowTransformationPicking={false}
                                        {...formDataProps}
                                    />
                                )}
                            </Field>

                            <TextInput
                                fullWidth
                                disabled
                                source="assetTransformations_sport1tv_hero"
                            />

                            <hr />

                            <Field name="videos">
                                {props => (
                                    <PlaylistVideos
                                        onChange={props.input.onChange}
                                        videos={props.input.value}
                                        {...formDataProps}
                                    />
                                )}
                            </Field>
                        </>}
                    </FormDataConsumer>
                </SimpleForm>
            </Edit>
        </Paper>
    </>;
})));

import { TranslationMessages } from 'ra-core';
import germanMessages from 'ra-language-german';

const customGermanMessages: TranslationMessages = {
    ...germanMessages,
    'Add to CMS': 'Zum CMS hinzufügen' ,
    'Analyse': 'Analysieren',
    'Analysis': 'Analyse',
    'Any': 'Alle',
    'Delete?': 'Löschen?',
    'Deleted': 'Gelöscht',
    'Deleted?': 'Gelöscht?',
    'Edit': 'Bearbeiten',
    'EPG': 'EPG',
    'Episode': 'Episode',
    'files': 'Files',
    'Help': 'Hilfe',
    'history': 'History',
    'Home': 'Home',
    'ID': 'ID',
    'Images': 'Bilder',
    'Landscape': 'Landscape',
    'Last 30 days': 'Letzte 30 Tage',
    'Last Year': 'Letztes Jahr',
    'Latest Galleries': 'Neueste Galerien',
    'Latest Images': 'Neueste Bilder',
    'Latest Videos': 'Neueste Videos',
    'Learn More': 'Mehr...',
    'Main Colors': 'Hauptfarben',
    'Metadata': 'Metadata',
    'no': 'nein',
    'Not Found': 'Nichts gefunden',
    'Panorama': 'Panorama',
    'policies': 'Policies',
    'Portrait': 'Porträt',
    'Recommended tags' : 'Empfohlene Tags',
    'Reset': 'Reset',
    'Search for tags...': 'Nach Tags suchen...',
    'Search keyword...': 'Suchbegriffe...',
    'Search': 'Suche',
    'Show': 'Sendung',
    'Sizes': 'Größen',
    'Square': 'Quadratisch',
    'Summary': 'Überblick',
    'Taken': 'Veröffentlicht',
    'Text': 'Text',
    'to change position - press and hold': 'um die Position zu ändern - drücken und halten Sie die Taste.',
    'Today': 'Heute',
    'Top Asset Tags': 'Top Tags Bilder',
    'Top Tags Galleries': 'Top Tags Galerien',
    'Transformations': 'Transformation |||| Transformationen',
    'Updated since': 'Aktualisiert seit',
    'Upload from disk...': 'Von Festplatte hochladen.....',
    'Upload': 'Hochladen',
    'Uploaded': 'Hochgeladen',
    'Videos': 'Videos',
    'yes': 'ja',
    'Yesterday': 'Gestern',
    'Date': 'Datum',
    warning_readonly: 'Du hast lediglich Leserechte.',
    resources: {
        asset: {
            name: 'Bild |||| Bilder',
            fields: {
                files: 'Dateien',
                description: 'Beschreibung (AltText)',
                copyright: 'Copyright',
                title: 'Titel',
            },
            action: {
                download: 'Download',
                upload: 'Bild hochladen',
                upload_from_disc: 'Datei hochladen',
                add_to_gallery: 'zur Galerie hinzufügen',
                add_to_video: 'zum Video hinzufügen',
            },
            notification: {
                asset_restored: 'Element erfolgreich restored',
                gif_too_big: 'Dieses GIF ist zu groß. Wir haben ein %{limit}MB-Limit.',
                image_uploaded: 'Element erfolgreich hochgeladen',
                image_uploaded_failed: 'Unbekannter Fehler beim Upload.',
                image_uploaded_failed_already_exists: 'Fehler beim Upload. Das Element existiert bereits.',
                service_unavailable: 'Service nicht erreichbar.',
                updated: 'Element aktualisiert',
            },
            filters: {
                filters: 'Filter',
                brightness: 'Helligkeit',
                contrast: 'Kontrast',
                saturation: 'Sättigung',
                focus: 'Fokus',
                grid: 'Fokuslinien',
                additional: 'Erweitert',
                quality: 'Bildqualität',
                noise: 'Bildrauschen',
                roundCorner: 'Abgerundete Ecken',
                grayscale: 'Schwarz/Weiß',
                redeye: 'Rote Augen entfernen',
                reset: 'Zurücksetzten',
                save: 'Speichern',
            },
            transformation: {
                created: 'Transformation wurde erstellt',
            },
            fileInfo: {
                file_type: 'Dateityp',
                file_size: 'Dateigröße',
                resolution: 'Auflösung',
            },
        },
        gallery: {
            name: 'Galerie |||| Galerien',
            action: {
                create: 'Galerie erstellen',
                add_to_gallery: 'Hinzufügen',
                remove_from_gallery: 'Abwählen',
            },
            notification: {
                updated: 'Galerie wurde aktualisiert',
                gallery_created: 'Galerie wurde erstellt',
                gallery_updated: 'Galerie wurde aktualisiert',
                gallery_restored: 'Galerie erfolgreich wiederhergestellt',
                asset_removed: 'Element wurde aus der Galerie entfernt',
                assets_added: 'Elemente wurden zur Galerie hinzugefügt',
                tag_added: 'Tag wurde hinzugefügt',
            },
            label: {
                add_assets_to_gallery: 'Elemente zur Galerie hinzufügen',
                remove_asset_from_gallery: 'Element entfernen',
                for_change_position_press_and_hold: 'Zum verschieben gedrückt halten',
                set_as_gallery_teaser: 'Setze als Teaserbild',
            },
            fields: {
                description: 'Beschreibung',
                title: 'Titel',
            },
        },
        getty: {
            name: 'Getty Bild |||| Getty Bilder',
            notification: {
                image_uploaded: 'Getty Images Bild erfolgreich hochgeladen',
            },
            click_to_upload: 'Click für Hochladen',
        },
        tag: {
            notification: {
                tag_created: 'Tag wurde erstellt',
                tag_added: 'Tag wurde hinzugefügt',
                tag_creation_failed: 'Tag wurde nicht erstellt',
            },
        },
        playlist: {
            action: {
                create: 'Playlist erstellen',
            },
            fields: {
                autoFill: 'auffüllen',
                createdAt: 'Erstellt am',
                description: 'Beschreibung',
                importID: 'Import-ID',
                title: 'Titel',
                updatedAt: 'Aktualisiert am',
            },
            label: {
                unpin: 'Löse Video aus der Playlist',
            },
            notification: {
                created: 'Playlist wurde erstellt',
                creation_failed: 'Playlist konnte nicht erstellt werden',
                updated: 'Playlist wurde aktualisiert',
                playlist_restored: 'Playlist erfolgreich wiederhergestellt',
            },
        },
        epg: {
            label: {
                add_thumbnails: 'Füge eine Bildvorschau zum EPG',
            },
            action: {
                add_to: 'zum EPG hinzufügen',
                go_to_eventstream: 'Editiere als EventStream',
            },
            broadcast: {
                endTime: 'Ende',
                fromDate: 'von',
                isActive: 'Ist Aktiv',
                isEventStream: 'Ist ein Eventstream',
                isHighlight: 'Der Broadcast ist ein Highlight',
                source: 'Quelle der Broadcast',
                startTime: 'Start',
                toDate: 'bis',
            },
            category: {
                name: 'Kategorie',
            },
            channel: {
                id: 'Channel',
                name: 'Channel',
            },
            episode: {
                description: 'Headline-Teaser',
                duration: 'Dauer',
                fsk: 'FSK',
                geoBlockingType: 'Geo-Blocking-Typ',
                isHighlight: 'Die Episode ist ein Highlight',
                longDescription: 'Beschreibung',
                materialId: 'Material-Id',
                noCountriesSet: 'Es wurden keine Länder spezifiziert',
                number: 'Folge',
                season: 'Staffel',
                serienId: 'Serien-Id',
                source: 'Quelle der Episode',
                subcategory: 'Kategorie',
                subtitle: 'Headline',
                transformationId: 'TransformationID des verknüpften Bildes',
                vodVideoId: 'ID des verknüpften Videos',
            },
            notification: {
                warning_list_cached: 'Vorsicht: Aufgrund von Caching zeigt diese Liste nicht die allerletzten Änderungen. Diese findest du hier:',
                warning_leaving_site_title: 'Seite verlassen',
                warning_leaving_site_text: 'Willst du die Seite wirklich verlassen?',
                no_eventstream_found: 'Es gibt diesen EventStream nicht mehr',
            },
            program: {
                title: 'Programm',
            },
        },
        video: {
            action: {
                create: 'Video erstellen',
            },
            label: {
                add_thumbnails_to_video: 'Füge eine Bildvorschau zum Video',
                metadata: 'Metadata',
                add_matchId_to_video: 'Match ID zum Video hinzufügen',
                without_matchday: 'Ohne Spieltag',
                matchday: 'Spieltag',
                hometeam: 'Heimmannschaft',
                awayteam: 'Gastmannschaft',
                season: 'Saison',
            },
            notification: {
                created: 'Video wurde erstellt',
                creation_failed: 'Video konnte nicht erstellt werden',
                restored: 'Video wurde erfolgreich wiederhergestellt',
                tracking_mandatory_fields_missing: 'Um das Video zu veröffentlichen bitte Tracking Sport und Quelle ausfüllen',
                tracking_value_show_fallback: 'Sendung wurde auf "%{show}" gesetzt, da eine matchId gesetzt wurde und "%{competition}" der ausgewählte Wettbewerb ist',
                update_failed: 'Video konnte nicht aktualisiert werden',
                updated: 'Video wurde aktualisiert',
                uploaded: 'Video wurde hochgeladen',
            },
            fields: {
                'link.value.href': 'URL des Links',
                'link.value.title': 'Titel des Links',
                'tracking.value.competition': 'Tracking: Wettbewerb',
                'tracking.value.content': 'Tracking: Content',
                'tracking.value.show': 'Tracking: Sendung',
                'tracking.value.source': 'Tracking: Quelle',
                'tracking.value.sport': 'Tracking: Sport',
                'vast.value.midroll': 'Zeige Midrolls?',
                'vast.value.postroll': 'Zeige Postrolls?',
                'vast.value.preroll': 'Zeige Prerolls?',
                ageRating: 'Altersfreigabe',
                approved: 'Genehmigt',
                availableAt: 'geht live',
                complete: 'Komplette Episode',
                countries: 'Länder, auf der Blacklist oder Whitelist',
                description: 'Beschreibung',
                expiresAt: 'verfällt am',
                geoType: 'Geoblocking-Typ',
                headlineEditorial: 'Journalistischer Titel',
                importID: 'Import-ID',
                publishedAt: 'Publikationsdatum',
                S1TVDescription: 'Sport1 TV Beschreibung (Optional)',
                S1TVTitle: 'Sport1 TV Titel (Optional)',
                title: 'Seo-Titel',
                titleEnglish: 'Englischer Titel (Optional)',
            }
        },
        users: {
            action: {
                create: 'User erstellen',
            },
            form: {
                validate: {
                    password: 'Passwort erforderlich!',
                    language: 'Language erforderlich!',
                    passwordMatch: 'Passwörter stimmen überein nicht!',
                    passwordRepeat: 'Passwort wiederholen'
                }
            },
            profile: {
                fields: {
                    title: 'Profile Fields',
                    subtitle: 'Fields zum Update wählen'
                }
            },
            response: {
                alreadyExist: 'User existiert bereits!'
            }
        },
        file: {
            name: 'Dateien'
        }
    },
    mam: {
        aws: {
            recognition: {
                action_finished: 'AWS Celebritiy Rekognition erfolgreich abgeschlossen',
                error: 'AWS Celebritiy Rekognition - es ist ein Fehler aufgetretten',
                text: 'Amazon Rekognition kann Tausende von Prominenten in einer Vielzahl von Kategorien erkennen, z. B. in den Bereichen Unterhaltung und Medien, Sport, Wirtschaft und Politik. Mit Amazon Rekognition können Sie Prominente in Bildern und gespeicherten Videos erkennen. Sie können auch zusätzliche Informationen für erkannte Prominente erhalten.',
            },
        },
        caption: 'Überschrift',
        clipboard_copy: '%{object} kopieren',
        clipboard_saved: '%{object} wurde auf den clipboard gespeichert',
        close: 'Schließen',
        create_episode: 'Episode erstellen: %{value}',
        create_tag: 'Tag erstellen: %{value}',
        dashboard: {
            average: 'Durchschnitt',
            images: 'Bilder',
            gallery: 'Galerien',
            last_30_days: 'Letzte 30 Tage',
            learn_more: 'Mehr',
            overall: 'Insgesamt',
            sizes: 'Sizes',
            transformations: 'Transformationen',
            videos: 'Videos',
            playlists: 'Playlists',
        },
        delete_thumbnail: 'Möchtest Du dieses Thumbnail vom Video lösen?',
        delete_transformation: 'Möchtest Du %{transformation} wirklich löschen?',
        found: 'Gefunden: %{count}',
        image_caption: 'Bildunterschrift',
        load_more: 'mehr nachladen',
        loading: 'wird geladen',
        navigation_tag_already_exists: 'Nur ein Navigationtag erlaubt',
        no_results: 'keine Treffer',
        search: {
            image: 'Bild',
            landscape: 'Querformat',
            panorama: 'Panoramabild',
            portrait: 'Hochformat',
            square: 'Viereckig',
            video: 'Video',
        },
        title: 'Titel',
        transformation_edit: 'Bearbeiten',
        transformation_preview: 'Vorschau',
    },
};

export default customGermanMessages;

import { httpRequestObject, OptionsInterface } from './httpRequestObjects';

const convertDataToImagoRequest = (
    type,
    params,
    apiUrl,
    options: OptionsInterface
): httpRequestObject => {
    let url;
    const resource = 'imago';

    switch (type) {
    case 'UPLOAD': {
        url = encodeURI(`${apiUrl}/asset/${resource}/upload/${params.id}/${params.db}`);
        options.method = 'POST';
        break;
    }
    default:
        url = encodeURI(
            `${apiUrl}/asset/${resource}/search/${params.keyword.replaceAll(' ', '_')}?page=${params.page}&per=50`
        );
        options.method = 'POST';
    }

    return { url, options };
};

export default convertDataToImagoRequest;

import React from 'react';
import {
    Datagrid,
    EditButton,
    List,
    ListController,
    TextField,
    translate,
    CloneButton
} from 'react-admin';
import {
    Input,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { Button, Paper } from '@material-ui/core';

const SearchPanel = translate(({
    translate,
    setFilters,
    filterValues,
}) => {
    return <Paper>
        <Input
            fullWidth
            onChange={(event) => setFilters({
                fromDate: filterValues.fromDate,
                text: event.target.value,
                toDate: filterValues.toDate,
            })}
            id="text"
            placeholder={translate('Text')}
        />

        <Input
            fullWidth
            onChange={(event) => setFilters({
                fromDate: event.target.value,
                text: filterValues.text,
                toDate: filterValues.toDate,
            })}
            id="fromDate"
            placeholder={translate('resources.epg.broadcast.fromDate')}
            type="datetime-local"
        />

        <Input
            fullWidth
            onChange={(event) => setFilters({
                fromDate: filterValues.fromDate,
                text: filterValues.text,
                toDate: event.target.value,
            })}
            id="toDate"
            placeholder={translate('resources.epg.broadcast.toDate')}
            type="datetime-local"
        />
    </Paper>;
});

export const EpgEventStreamList = translate((props) => {
    const dispatch = useDispatch();
    return <ListController {...props} resource={'eventstream'}>
        {controllerProps => <>
            <SearchPanel
                setFilters={controllerProps.setFilters}
                filterValues={controllerProps.filterValues}
            />

            <div style={{
                color: '#0008',
                padding: '2ch 0 0',
                textAlign: 'center',
            }}>
                {props.translate('resources.epg.notification.warning_list_cached')}
                <Button
                    color="primary"
                    onClick={() => dispatch(push('/epg-broadcast'))}
                >
          Broadcasts
                </Button>
            </div>

            <List bulkActionButtons={false} {...props}>
                <Datagrid {...controllerProps}>
                    <TextField sortable={false} source="id" />
                    <TextField sortable={false} source="episode.subtitle" label={props.translate('resources.epg.episode.subtitle')} />
                    <TextField sortable={false} source="episode.description" label={props.translate('resources.epg.episode.description')} />
                    <TextField sortable={false} source="broadcast.startTime" label={props.translate('resources.epg.broadcast.startTime')} />
                    <EditButton />
                    <CloneButton/>
                </Datagrid>
            </List>
        </>}
    </ListController>;
});

export default EpgEventStreamList;

import {
    CREATE,
    DELETE,
    DELETE_MANY,
    GET_LIST,
    GET_ONE,
    UPDATE
} from 'react-admin';
import { isObject } from 'util';
import { ConvertPrimitivesToAssetTransformations } from './converAssetTransformations';

export interface OptionsInterface {
  method: string;
  headers: Headers;
  body: any;
}

export interface httpRequestObject {
  url: string;
  options: OptionsInterface;
}

export const convertDataToAssetMetasRequest = (
    type,
    params,
    apiUrl,
    options: OptionsInterface
): httpRequestObject => {
    const url = `${apiUrl}/asset/${params.assetID}/metas`;
    if (type === UPDATE) {
        options.method = 'POST';
        options.body = JSON.stringify(params);
    }
    options.headers.append('Content-Type', 'application/json');
    return { url, options };
};

export const convertDataToAssetRequest = (
    type,
    params,
    apiUrl,
    options: OptionsInterface
): httpRequestObject => {
    let url;
    const resource = 'asset';

    switch (type) {
    case GET_LIST: {
        const { page = 1, perPage = 12 } = params.pagination;
        const { filter = {} } = params;

        const id = filter.id
            ? `&id=${filter.id}`
            : '';

        const text = filter.text ? `&text=${filter.text}` : '';

        const time =
        filter.timeWhenValue && filter.timeWhenValue.value !== 'any'
            ? `&uploaded=${filter.timeWhenValue.value}`
            : '';

        const orientationValue = filter.orientation ? filter.orientation : 'any';
        let orientation =
        orientationValue.toString() !== 'any'
            ? `&orientation=${orientationValue}`
            : '';

        const tags = filter.tags
            ? filter.tags.map(tag => tag.value).join(',')
            : '';

        let type = '';
        if (filter.type === undefined || filter.type.length === 2) {
            type = '';
        } else {
            type = `&type=${filter.type}`;
        }

        const tagFilter = filter.tags ? `&tags=${tags}` : '';

        const deleted = filter.deleted ? '&deleted=true' : '';

        url = encodeURI(
            `${apiUrl}/${resource}/all?page=${page}&per=${perPage}${id}${text}${time}${orientation}${type}${tagFilter}${deleted}`
        );
        options.headers.append('cache-control', 'no-cache');
        options.method = 'POST';
        break;
    }

    case GET_ONE: {
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'POST';
        break;
    }

    case CREATE: {
        url = `${apiUrl}/${resource}/upload`;
        options.method = 'POST';
        options.headers.append('Accept', '*/*');
        options.headers.append('cache-control', 'no-cache');

        let data = new FormData();
        data.append('asset', params);
        options.body = data;
        break;
    }

    case UPDATE: {
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'PUT';
        options.headers.append('Content-Type', 'application/json');
        options.body = JSON.stringify(params);
        break;
    }

    case DELETE: {
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'DELETE';
        options.headers.append('Content-Type', 'application/json');
        break;
    }

    case 'GET_TAG_LIST': {
        url = encodeURI(`${apiUrl}/${resource}/${params.id}/tag`);
        break;
    }

    case 'ADD_TAG': {
        url = encodeURI(`${apiUrl}/${resource}/${params.id}/tag`);
        options.headers.append('Content-Type', 'application/json');
        options.method = 'POST';
        options.body = JSON.stringify({
            id: params.tagId,
            tag: 'workaround random'
        });
        break;
    }

    case 'DELETE_TAG': {
        url = encodeURI(`${apiUrl}/${resource}/${params.id}/tag/${params.tagId}`);
        options.method = 'DELETE';
        options.headers.append('Content-Type', 'application/json');
        break;
    }

    case 'GET_TRANSFORMATION_LIST': {
        url = encodeURI(`${apiUrl}/${resource}/${params.id}/transformations`);
        options.method = 'POST';
        options.headers.append('Content-Type', 'application/json');
        break;
    }

    case 'CREATE_TRANSFORMATION': {
        const smart = params.filters['Smart'] ? 'smart' : '';
        const filterOptions: string[] = [];
        for (let filter in params.filters) {
            if (
                filter !== 'Smart' &&
          params.filters[filter] &&
          params.filters[filter].toString() !== ''
            ) {
                let value = params.filters[filter];
                if (typeof value == 'object') {
                    filterOptions.push(value);
                } else {
                    if (filter !== 'ShowGrid') {
                        filterOptions.push(`${filter.toLowerCase()}(${value})`);
                    }
                }
            }
        }
        const filters = filterOptions.length > 0 ? '/filters:' : '';
        url = encodeURI(
            `${apiUrl}/${resource}/${
                params.id
            }/transformation/${smart}${filters}${filterOptions.join(':')}`
        );
        options.method = 'POST';
        break;
    }

    case 'RECOGNITION_CELEBRITY': {
        url = encodeURI(`${apiUrl}/rekognition/celebrity/${params.id}`);
        options.headers.append('Content-Type', 'application/json');
        break;
    }

    case 'RESTORE_ASSET': {
        url = `${apiUrl}/${resource}/${params.id}/restore`;
        options.method = 'PATCH';
        break;
    }

    default:
        break;
    }

    return { url, options };
};

export const convertDataToTagRequest = (
    type,
    params,
    apiUrl,
    options: OptionsInterface
) => {
    let url;
    const resource = 'tag';

    switch (type) {
    case 'GET_ID': {
        url = encodeURI(`${apiUrl}/${resource}/${params.tag}`);
        break;
    }

    case GET_LIST: {
        options.method = 'POST';
        if (isObject(params)) {
            const {sort = {}} = params;
            const { page = 1, perPage = 10 } = params.pagination;
            const sorted = sort.field ? `&sort_field=${sort.field}&sort_order=${sort.order}` : '';
            const text = params.filter.text ? `&tag=ct:${params.filter.text}${sorted}` : '';
            const id = params.filter.id ? `&id=${params.filter.id}` : '';
            url = `${apiUrl}/${resource}/all?page=${page}&per=${perPage}${text}${id}`;
        } else {
            url = `${apiUrl}/${resource}/like/${encodeURIComponent(params)}`;
        }
        break;
    }

    case CREATE: {
        url = encodeURI(`${apiUrl}/${resource}`);
        options.method = 'POST';
        options.headers.append('Content-Type', 'application/json');
        options.body = JSON.stringify({
            tag: params.tag,
            source: params.source,
            externalID: params.externalID,
            category: 'manual'
        });
        break;
    }

    case GET_ONE: {
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'POST';
        break;
    }

    case UPDATE: {
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'PUT';
        options.headers.append('Content-Type', 'application/json');
        options.body = JSON.stringify(params.data);
        break;
    }

    case 'GET_TAGS_USAGE': {
        const ids = JSON.stringify({ id: params.ids });
        url = encodeURI(`${apiUrl}/${resource}/usage?${ids}`);
        options.headers.append('Content-Type', 'application/json');
        break;
    }

    case 'USAGE': {
        const { id, type } = params;
        url = encodeURI(`${apiUrl}/${resource}/usage/${id}/${type}`);
        options.headers.append('Content-Type', 'application/json');
        break;
    }

    default:
        break;
    }
    return { url, options };
};

export const convertDataToGalleryRequest = (
    type,
    params,
    apiUrl,
    options: OptionsInterface
) => {
    let url;
    const resource = 'gallery';

    switch (type) {
    case CREATE: {
        url = encodeURI(`${apiUrl}/${resource}`);
        options.method = 'POST';
        options.headers.append('Content-Type', 'application/json');
        options.body = JSON.stringify({ assetIds: params.assetIds });
        break;
    }

    case UPDATE: {
        url = encodeURI(`${apiUrl}/${resource}/${params.id}`);
        options.method = 'PUT';
        options.headers.append('Content-Type', 'application/json');
        options.body = JSON.stringify(params);
        break;
    }

    case DELETE: {
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'DELETE';
        options.headers.append('Content-Type', 'application/json');
        break;
    }

    case GET_LIST: {
        const { page = 1, perPage = 12 } = params.pagination;
        const { filter = {} } = params;
        const text = filter.text ? `&text=${filter.text}` : '';
        const time =
        filter.timeWhenValue && filter.timeWhenValue.value !== 'any'
            ? `&uploaded=${filter.timeWhenValue.value}`
            : '';

        const tags = filter.tags
            ? filter.tags.map(tag => tag.value).join(',')
            : '';

        const tagFilter = filter.tags ? `&tags=${tags}` : '';
        const deleted = filter.deleted ? '&deleted=true' : '';
        url = encodeURI(
            `${apiUrl}/${resource}/all?page=${page}&per=${perPage}${text}${time}${tagFilter}${deleted}`
        );
        options.headers.append('cache-control', 'no-cache');
        options.method = 'POST';
        break;
    }

    case GET_ONE: {
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'POST';
        break;
    }

    case DELETE_MANY: {
        url = encodeURI(`${apiUrl}/galleries?ids=${params.ids.join()}`);
        options.method = 'DELETE';
        break;
    }

    case 'GET_ASSETS': {
        url = `${apiUrl}/${resource}/${params.id}/asset`;
        options.method = 'POST';
        break;
    }

    case 'GET_ONE_ASSET': {
        url = encodeURI(
            `${apiUrl}/${resource}_assets/${params.galleryId}/${params.assetId}`
        );
        options.method = 'POST';
        options.headers.append('Content-Type', 'application/json');
        break;
    }

    case 'ADD_ASSETS': {
        url = encodeURI(`${apiUrl}/${resource}_assets`);
        options.method = 'POST';
        options.headers.append('Content-Type', 'application/json');
        options.body = JSON.stringify({
            galleryId: params.galleryId,
            assetIds: params.assetIds
        });
        break;
    }

    case 'UPDATE_ASSETS': {
        url = `${apiUrl}/${resource}_assets`;
        options.method = 'PUT';
        options.headers.append('Content-Type', 'application/json');

        const elements: any[] = [];
        params.assets.forEach((element, index) => {
            elements.push({
                galleryID: params.id,
                assetID: element.id,
                transformationID: element.transformationID,
                title: element.title,
                position:
            element.holdPosition !== undefined ? element.holdPosition : index,
                caption: element.caption,
                isTeaser: element.isTeaser
            });
        });
        options.body = JSON.stringify(elements);

        break;
    }

    case 'DELETE_ASSET': {
        url = `${apiUrl}/${resource}_assets/${params.id}/${params.assetId}`;
        options.method = 'DELETE';
        break;
    }

    case 'RESTORE_GALLERY': {
        url = `${apiUrl}/${resource}/${params.id}/restore`;
        options.method = 'PATCH';
        break;
    }

    case 'ADD_TAG': {
        url = encodeURI(`${apiUrl}/${resource}/${params.id}/tag`);
        options.headers.append('Content-Type', 'application/json');
        options.method = 'POST';
        options.body = JSON.stringify({
            id: params.tagId,
            tag: 'workaround random'
        });
        break;
    }

    case 'DELETE_TAG': {
        url = encodeURI(`${apiUrl}/${resource}/${params.id}/tag/${params.tagId}`);
        options.method = 'DELETE';
        options.headers.append('Content-Type', 'application/json');
        break;
    }

    case 'GET_TAG_LIST': {
        url = encodeURI(`${apiUrl}/${resource}/${params.id}/tag`);
        break;
    }

    case 'GET_RECOMMENDED_TAG_LIST': {
        url = encodeURI(`${apiUrl}/${resource}/${params.id}/recommended`);
        options.method = 'POST';
        break;
    }

    default:
        break;
    }

    return { url, options };
};

export const convertDataToPlaylistRequest = (
    type,
    params,
    apiUrl,
    options: OptionsInterface
) => {
    let url;
    const resource = 'playlist';

    switch (type) {
    case CREATE: {
        url = encodeURI(`${apiUrl}/${resource}`);
        options.method = 'POST';
        options.body = JSON.stringify(params);
        options.headers.append('Content-Type', 'application/json');
        break;
    }

    case DELETE: {
        url = encodeURI(`${apiUrl}/${resource}/${params.id}`);
        options.method = 'DELETE';
        options.headers.append('Content-Type', 'application/json');
        break;
    }

    case GET_LIST: {
        const {filter = {}, sort={}} = params;
        const {page = 1, perPage = 12} = params.pagination;

        const id = filter.id
            ? `&id=${filter.id}`
            : '';

        const deleted = filter.deleted
            ? '&deleted=true'
            : '';

        const tags = filter.tags && filter.tags.length
            ? '&tags=' + filter.tags.map(tag => tag.value).join(',')
            : '';

        const text = filter.text
            ? `&text=${filter.text}`
            : '';

        const updated = filter.timeWhenValue && filter.timeWhenValue.value !== 'any'
            ? `&updated=${filter.timeWhenValue.value}`
            : '';

        const sorted = sort.field ? `&sort_field=${sort.field}&sort_order=${sort.order}` : '';

        url = encodeURI(`${apiUrl}/${resource}/all?page=${page}&per=${perPage}${deleted}${id}${tags}${text}${updated}${sorted}`);
        options.method = 'POST';
        break;
    }

    case 'GET_LIST_BY_VIDEO': {
        url = encodeURI(`${apiUrl}/${resource}/video/${params}`);
        options.method = 'POST';
        options.headers.append('Content-Type', 'application/json');
        break;
    }

    case 'GET_LIST_WITH_NAV_TAGS': {
        url = encodeURI(`${apiUrl}/${resource}/navtags`);
        options.method = 'POST';
        options.headers.append('Content-Type', 'application/json');
        break;
    }

    case GET_ONE: {
        url = encodeURI(`${apiUrl}/${resource}/${params.id}`);
        options.method = 'POST';
        options.headers.append('Content-Type', 'application/json');
        break;
    }

    case UPDATE: {
        url = encodeURI(`${apiUrl}/${resource}/${params.id}`);
        options.method = 'PUT';
        options.headers.append('Content-Type', 'application/json');
        const playlist = JSON.parse(JSON.stringify(params));
        if (playlist.tags) {
            playlist.tags.forEach((tag) => {
                if ('string' === typeof tag.id) {
                    // a new tag
                    delete tag.id;
                }
            });
        }

        ConvertPrimitivesToAssetTransformations(playlist, 'playlist');
        options.body = JSON.stringify(playlist);
        break;
    }
    case 'RESTORE_PLAYLIST': {
        url = `${apiUrl}/${resource}/${params.id}/restore`;
        options.method = 'PATCH';
        break;
    }
    }

    return { url, options };
};

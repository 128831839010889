import React from 'react';
import {
    BooleanInput,
    DateTimeInput,
    DeleteButton,
    Edit,
    FormDataConsumer,
    NumberInput,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
} from 'react-admin';
import { Field } from 'react-final-form';
import { useDispatch } from 'react-redux';
import ToEventStream from './ToEventStream';

const CustomToolbar = props =>
    <Toolbar {...props}>
        <SaveButton />
        {props.record.source === 'oz' ? <DeleteButton undoable={false} /> : null}
    </Toolbar>;

export const EpgBroadcastEdit = (props) => {
    const dispatch = useDispatch();
    return <Edit {...props} >
        <SimpleForm redirect="edit" toolbar={<CustomToolbar />}>
            <NumberInput source="id" disabled fullWidth />

            <FormDataConsumer>
                {formDataProps =>
                    <Field name="channelId">
                        {() => <NumberInput source="channelId" disabled={'oz' !== formDataProps.record.source} fullWidth /> }
                    </Field>
                }
            </FormDataConsumer>

            <FormDataConsumer>
                {formDataProps =>
                    <Field name="episodeId">
                        {() => <NumberInput source="episodeId" disabled={'oz' !== formDataProps.record.source} fullWidth /> }
                    </Field>
                }
            </FormDataConsumer>

            <FormDataConsumer>
                {formDataProps =>
                    <Field name="startTime">
                        {() =>
                            <DateTimeInput
                                fullWidth
                                parse={(d) => new Date(d)}
                                resettable
                                source="startTime"
                                disabled={'oz' !== formDataProps.record.source}
                            />
                        }
                    </Field>
                }
            </FormDataConsumer>

            <FormDataConsumer>
                {formDataProps =>
                    <Field name="endTime">
                        {() =>
                            <DateTimeInput
                                fullWidth
                                parse={(d) => new Date(d)}
                                resettable
                                source="endTime"
                                disabled={'oz' !== formDataProps.record.source}
                            />
                        }
                    </Field>
                }
            </FormDataConsumer>

            <TextInput source="source" disabled fullWidth />

            <BooleanInput source="isActive" required defaultValue={false} />
            <BooleanInput source="isEventStream" required defaultValue={false} />
            <BooleanInput source="isHighlight" />

            <ToEventStream dispatch={dispatch} {...props} />
        </SimpleForm>
    </Edit>;
};

export default EpgBroadcastEdit;

import { httpRequestObject } from './httpRequestObjects';
import { GET_LIST } from 'react-admin';

export default (type, params, apiUrl, options): httpRequestObject => {
    let url;
    options.headers.append('Content-Type', 'application/json');
    switch (type) {
    case GET_LIST: {
        const {filter = {}} = params;
        const {page = 1, perPage = 12} = params.pagination;

        const id = filter.id
            ? `&id=${filter.id}`
            : '';

        const userID = filter.userID
            ? `&userID=${filter.userID}`
            : '';

        const changedBy = filter.changedBy
            ? `&changedBy=${filter.changedBy}`
            : '';

        const resource = filter.resource
            ? `&resource=${filter.resource}`
            : '';

        const resourceID = filter.resourceID
            ? `&resourceID=${filter.resourceID}`
            : '';

        const changedOn = filter.changedOn
            ? `&changedOn=${filter.changedOn}`
            : '';

        url = encodeURI(`${apiUrl}/auditlog/all?page=${page}&per=${perPage}${id}${userID}${changedBy}${resource}${resourceID}${changedOn}`);
        options.method = 'POST';
        options.headers.append('cache-control', 'no-cache');
        break;
    }
    }
    return {url, options};
};

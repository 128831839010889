import React, {useEffect, useState} from 'react';
import {Confirm} from 'react-admin';
import {Prompt} from 'react-router';
import {useTranslate} from 'ra-core';

interface Props {
  when?: boolean | undefined;
  navigate: (path: string) => void;
  shouldBlockNavigation: (location) => boolean;
}

const RouteLeavingGuard = ({
    when,
    navigate,
    shouldBlockNavigation,
}: Props) => {
    const translate = useTranslate();
    const [modalVisible, setModalVisible] = useState(false);
    const [lastLocation, setLastLocation] = useState<{ pathname: string }>();
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);
    const closeModal = () => {
        setModalVisible(false);
    };
    const handleBlockedNavigation = (nextLocation): boolean => {
        if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
            setModalVisible(true);
            setLastLocation(nextLocation);
            return false;
        }
        return true;
    };
    const handleConfirmNavigationClick = () => {
        setModalVisible(false);
        setConfirmedNavigation(true);
    };

    useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            navigate(lastLocation.pathname);
        }
    }, [confirmedNavigation, lastLocation, navigate]);
    return (
        <>
            <Prompt when={when} message={handleBlockedNavigation}/>
            <Confirm
                isOpen={modalVisible}
                title={translate('resources.epg.notification.warning_leaving_site_title')}
                content={translate('resources.epg.notification.warning_leaving_site_text')}
                onConfirm={handleConfirmNavigationClick}
                onClose={closeModal}
            />
        </>
    );
};
export default RouteLeavingGuard;

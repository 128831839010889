import {eZDataReceived as ezDataReceivedAction} from './eZDataAction';
import {withDataProvider} from 'react-admin';
import React, {useEffect} from 'react';
import { connect, useDispatch } from 'react-redux';

export const EzAdminDataHandler = connect(
    null,
    {eZDataReceived: ezDataReceivedAction}
)(
    withDataProvider(({eZDataReceived}) => {
        const dispatch = useDispatch();
        const getEventDataFromEZ = event => {
            dispatch(eZDataReceived(event.data));
        };

        useEffect(() => {
            if (window.opener) {
                window.opener.postMessage('I am Ready', '*');
                window.addEventListener('message', getEventDataFromEZ);
            }

            return () => {
                window.removeEventListener('message', getEventDataFromEZ);
            };
        });

        return <></>;
    })
);

import React, { useEffect, useState } from 'react';
import {
    DeleteButton,
    FormDataConsumer,
    SimpleForm,
    TextField,
    TextInput,
    Toolbar,
    translate,
    UPDATE,
    useNotify,
    withDataProvider,
} from 'react-admin';
import { useEditController } from 'ra-core';
import {
    Card,
    Grid,
    Paper,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { AssetThumborFilters } from './AssetThumborFilters';
import { ImagePreview } from './ImagePreview';
import { InterfaceFilters } from '../../types';
import { FileInfo } from './FileInfo';
import AssetTransformation from '../transformations/AssetTransformation';
import { InitialFilters } from '../../utils/InitialFilters';
import { Transformation } from '../transformations/types/Transformation';
import TagWrapper from '../TagWrapper';
import { SelectOptionInterface } from '../../types';
import { BackButton } from '../buttons/BackButton';
import { connect } from 'react-redux';
import AutoSave from '../../utils/AutoSave';
import { Field } from 'react-final-form';
import { Title } from 'react-admin';
import { ROLES } from '../../utils/config';
import Deleted from '../../utils/Deleted';

const styles = theme => ({
    root: {
        flexGrow: 1,
        marginTop: 12
    },
    formInput: {
        width: '100%',
        paddingBottom: 10
    },
    formInputCreatable: {
        width: '100%',
        marginTop: 10
    },
    paper: {
        marginTop: 5,
        backgroundColor: 'black',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    filters: {},
    filter: {
        marginBottom: 40,
        '& p': {
            fontSize: '0.75em',
            color: 'gray',
            marginBottom: 10
        }
    },
    fileInfo: {
        '& p': {
            color: 'grey',
            padding: 5
        }
    },
    fullFilter: {
        width: '100%',
        '& p': {
            fontSize: '0.75em',
            color: 'gray',
            marginBottom: 10
        }
    },
    transformations: {
        marginTop: 8
    },
    wrapper: {
        position: 'relative' as 'relative'
    },
    buttonProgress: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    chip: {
        margin: theme.spacing.unit / 2
    },
    toolbar: {
        display: 'flex'
    }
});

interface Props {
  basePath: string;
  classes: any;
  dataProvider: any;
  galleryId;
  handleAddTransformationToGallery?: CallableFunction;
  handleAddTransformationToVideo?: CallableFunction;
  history;
  id: string;
  permissions;
  resource: string;
  transformation?: Transformation;
  translate;
  videoID?: string;
}

export default withDataProvider(translate(withStyles(styles)(connect(null, {})((props: Props) => {
    const {
        classes,
        dataProvider,
        id,
        permissions,
        translate,
    } = props;

    const notify = useNotify();

    const initialTransformations: Transformation[] = [];
    const [filters, setFilters] = useState(Object.assign({}, InitialFilters));
    const [createTransformation, setCreateTransformation] = useState(false);
    const [transformations, setTransformations] = useState(
        initialTransformations
    );
    const initialBackendTags: SelectOptionInterface[] = [];
    // eslint-disable-next-line
  const [backendTags, setBackendTags] = useState(initialBackendTags);
    const initialTags: SelectOptionInterface[] = [];
    const [tags, setTags] = useState(initialTags);
    const [selectedTransformationId, setSelectedTransformationId] = useState<undefined | string>();
    const [fileType, setFileType] = useState<undefined | string>();

    useEffect(() => {
        if (props.transformation !== undefined) {
            setSelectedTransformationId(props.transformation.id);
            updateFilters(props.transformation.filtersUpdate);
        } else {
            resetFilters();
            setSelectedTransformationId(undefined);
        }
    // eslint-disable-next-line
  }, [props.transformation]);

    useEffect(() => {
        dataProvider('GET_TRANSFORMATION_LIST', 'asset', { id: props.id })
            .then((response) => setTransformations(response.data))
            .catch(error => console.error(error));
    }, [props.id, dataProvider]);

    useEffect(() => {
        dataProvider('GET_TAG_LIST', 'asset', { id: props.id }).then(response => {
            let values: SelectOptionInterface[] = [];

            response.data.forEach((tag: { id: number; tag: string, approved?: boolean, sportID?: string, externalID?: string }) => {
                if (tags.find(stateTag => stateTag.value === tag.id) === undefined) {
                    values.push({ label: tag.tag, value: tag.id, approved: tag.approved, sportID: tag.sportID, externalID: tag.externalID });
                }
            });
            setBackendTags(values);
            setTags([...tags].concat(values));
        });
    // yarn lint wants tags as part of the dependencies -> but this
    // leads to endless tag polling
    // eslint-disable-next-line
  }, [dataProvider, props.id]);

    const updateFilters = (parameterValues: InterfaceFilters) => {
        setFilters(Object.assign(Object.assign({}, filters), parameterValues));
    };

    const resetFilters = () => {
        setFilters(Object.assign(Object.assign({}, filters), InitialFilters));
        setSelectedTransformationId(undefined);
    };

    useEffect(() => {
        if (createTransformation) {
            dataProvider(
                'CREATE_TRANSFORMATION',
                'asset',
                {
                    id: props.id,
                    filters: filters
                },
                {
                    onSuccess: {
                        notification: { body: 'resources.asset.transformation.created' }
                    }
                }
            )
                .then(response => {
                    let clonedTransformations = [...transformations];
                    clonedTransformations.push(response.data);
                    setTransformations(clonedTransformations);
                    setSelectedTransformationId(response.data.id);
                })
                .catch((error: Response) => console.error(error));
        }

        return () => setCreateTransformation(false);
    }, [
        createTransformation,
        dataProvider,
        filters,
        props.id,
        transformations,
    ]);

    const handleChange = (values) =>
        dataProvider(UPDATE, 'asset', { ...values, id: props.id })
            .then(response => notify('resources.asset.notification.updated'))
            .catch(error => console.error(error));

    const handleFileType = (fileType: string) => setFileType(fileType);

    const controllerProps = useEditController(props);

    const Removal = (props) => {
        if (![ROLES.admin.id.toString(), ROLES.superEditor.id.toString()].includes(permissions)) {
            return null;
        } else if (!props.record.deletedAt) {
            return <DeleteButton undoable={false} {...props} />;
        }
        return null;
    };

    return (
        <>
            <Grid container className={classes.root} spacing={1}>
                <Grid item xs={12} md={4} style={{ minWidth: 400 }}>
                    <Paper elevation={2} >
                        {controllerProps && controllerProps.record && (
                            <Card >
                                <Title title={`${translate('resources.asset.name', 1)} #${id}`} />
                                <SimpleForm
                                    {...controllerProps}
                                    toolbar={
                                        <Toolbar {...props}>
                                            <BackButton
                                                history={props.history}
                                                label={translate('ra.action.back')}
                                            />
                                            <Removal />
                                        </Toolbar>
                                    }
                                >
                                    <AutoSave save={handleChange} />
                                    <TextField className={classes.formInput} source="id" variant="standard" />
                                    <FileInfo className={classes.fileInfo} handleFileType={handleFileType} />
                                    <TextInput className={classes.formInput} source="title" variant="standard" />
                                    <Deleted />
                                    <TextInput
                                        className={classes.formInput}
                                        source="copyright"
                                        variant="standard"
                                    />
                                    <TextInput source="description" multiline className={classes.formInput} variant="standard" />
                                    <Typography variant="caption">Tags</Typography>
                                    <FormDataConsumer>
                                        {formDataProps =>
                                            <div>
                                                <Field name="tags">
                                                    {props =>
                                                        <TagWrapper
                                                            selectedTags={props.input.value && props.input.value.map(tagEl => ({...tagEl, label: tagEl.tag, value: tagEl.id }))}
                                                            onChange={props.input.onChange}
                                                            permissions={permissions}
                                                            {...formDataProps}
                                                        />
                                                    }
                                                </Field>
                                            </div>
                                        }
                                    </FormDataConsumer>
                                </SimpleForm>
                            </Card>
                        )}
                    </Paper>
                </Grid>
                <Grid item xs={12} md>
                    <ImagePreview
                        filters={filters}
                        setFiltersCallback={updateFilters}
                        id={props.id}
                    >
                    </ImagePreview>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Grid container>
                        <Grid item xs={12}>
                            <AssetThumborFilters
                                filters={filters}
                                setFiltersCallback={updateFilters}
                                useResetFilters={resetFilters}
                                setCreateTransformation={setCreateTransformation}
                                classes={classes}
                                fileType={fileType}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className={classes.transformations} spacing={1}>
                {transformations.map((transformation, index) => (
                    <Grid item key={index} lg={3}>
                        <AssetTransformation
                            galleryId={props.galleryId}
                            handleAddTransformationToGallery={props.handleAddTransformationToGallery}
                            handleAddTransformationToVideo={props.handleAddTransformationToVideo}
                            history={props.history}
                            raised={transformation.id === selectedTransformationId}
                            setFiltersCallback={updateFilters}
                            setSelectedTransformationId={setSelectedTransformationId}
                            transformation={transformation}
                            videoID={props.videoID}
                        />
                    </Grid>
                ))}
            </Grid>
        </>
    );
}))));

import {
    Brightness,
    Contrast, Focus,
    GrayScale,
    InterfaceFilters,
    Noise,
    Quality,
    RoundCorner,
    Saturation
} from '../types';

export const InitialFilters: InterfaceFilters = {
    Contrast: new Contrast(0),
    Quality: new Quality(100),
    Brightness: new Brightness(0),
    Saturation: new Saturation(1),
    Noise: new Noise(0),
    Fill: '',
    Extract_Focal: false,
    Grayscale: new GrayScale(false),
    Round_Corner: new RoundCorner(0),
    ShowGrid: false,
    Smart: true,
    RedEye: false,
    Focus: new Focus({active: false, leftPercentage: 50, topPercentage: 50})
};

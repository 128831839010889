import React, { useEffect, useState, FunctionComponent } from 'react';
import SortableGalleryAssetList from './SortableGalleryAssetList';
import { GalleryAssetInterface } from '../../types';
import {
    withDataProvider,
    setListSelectedIds as setListSelectedIdsAction,
    GET_ONE,
    useNotify
} from 'react-admin';

import arrayMove from 'array-move';
import { GalleryEditForm } from './GalleryEditForm';
import GalleryAddAssetDialog from './GalleryAddAssetDialog';
import { connect, useDispatch } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

interface Props {
  basePath: string;
  id: string;
  permissions;
  match: Object;
  location: Object;
  history: Object;
  resource: string;
  options: Object;
  hasList: boolean;
  hasEdit: boolean;
  hasShow: boolean;
  hasCreate: boolean;
  dataProvider: any;
  setSelectedIds: string[];
  ez_data;
}

const GalleryEdit: FunctionComponent<Props> = ({
    id,
    dataProvider,
    basePath,
    permissions,
    match,
    location,
    history,
    resource,
    options,
    hasCreate,
    hasEdit,
    hasList,
    hasShow,
    setSelectedIds,
    ez_data
}: Props) => {
    const [openDialog, setOpenDialog] = useState(false);
    const initAssets: GalleryAssetInterface[] = [];
    const [assets, setAssets] = useState(initAssets);
    const [reload, setReload] = useState(false);
    const [olds, setOlds] = useState(initAssets);

    const dispatch = useDispatch();
    const notify = useNotify();

    useEffect(() => {
        dataProvider('GET_ASSETS', 'gallery', { id })
            .then((response) => response.data)
            .then((json) => {
                json.forEach(el => {
                    el.title = el.title || '';
                    el.caption = el.caption || '';
                });
                setAssets(json);
                setOlds(json);
            })
            .catch(error => console.error(error));
    }, [id, dataProvider, reload]);

    const [debouncedCallback] = useDebouncedCallback(
    // function
        () => {
            if (assets !== olds) {
                dataProvider('UPDATE_ASSETS', 'gallery', {
                    id: id,
                    assets: assets
                })
                    .then(response => {
                        notify('resources.gallery.notification.gallery_updated');
                        setOlds(assets);
                        return response;
                    })
                    .catch(error => console.error(error));
            }
        },
        // delay in ms
        500
    );

    useEffect(() => {
        debouncedCallback();
    }, [assets, debouncedCallback]);


    const updateAssetsInGallery = (element, property, value) => {
        element[property] = value;
        let clonedAssets = [...assets];
        setAssets(clonedAssets);
    };

    const updateIsTeaser = (id) => {
        let newAssets = assets.map(element => {
            element.isTeaser = element.id === id;
            return element;
        });
        setAssets(newAssets);
    };

    const removeAssetFromGallery = (assetId: string) => {
        let updatedAssets = assets.filter(element => element.id !== assetId);
        setAssets(updatedAssets);
        dataProvider('DELETE_ASSET', 'gallery', { id: id, assetId: assetId })
            .then(response => {
                notify('resources.gallery.notification.asset_removed');
                return response;
            })
            .catch(error => console.error(error));
    };

    const updatePosition = ({ oldIndex, newIndex }) => {
        let newArray = arrayMove(assets, oldIndex, newIndex);
        setAssets(newArray);
    };

    const handleAddToEZPublish = () => {
        dataProvider(GET_ONE, 'gallery', { id: id })
            .then(response => {
                return response.data;
            })
            .then(gallery => {
                window.opener.postMessage(
                    {
                        payload: ez_data,
                        gallery: gallery,
                        assets: [...assets].shift()
                    },
                    '*'
                );
            });
    };

    return (
        <>
            <GalleryAddAssetDialog
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                basePath={basePath}
                resource={resource}
                location={location}
                id={id}
                dataProvider={dataProvider}
                setSelectedIds={setSelectedIds}
                dispatch={dispatch}
                assets={assets}
                reload={reload}
                setReload={setReload}
            />
            <GalleryEditForm
                basePath={basePath}
                id={id}
                permissions={permissions}
                match={match}
                location={location}
                history={history}
                resource={resource}
                options={options}
                hasList={hasList}
                hasEdit={hasEdit}
                hasShow={hasShow}
                hasCreate={hasCreate}
                handleAddToEZPublish={handleAddToEZPublish}
                ez_data={ez_data}
            />
            <SortableGalleryAssetList
                galleryId={id}
                setOpenDialog={setOpenDialog}
                pressDelay={200}
                items={assets}
                axis="xy"
                onSortEnd={updatePosition}
                removeAssetFromGallery={removeAssetFromGallery}
                updateAssetsInGallery={updateAssetsInGallery}
                updateIsTeaser={updateIsTeaser}
            />
        </>
    );
};

function mapStateToProps(state) {
    const resourceState = state.admin.resources['asset'];
    return {
        selectedIds: resourceState.list.selectedIds,
        isLoading: state.admin.loading > 0,
        ...state.eZDataReducer // inject ez_data
    };
}

export default withDataProvider(
    connect(
        mapStateToProps,
        {
            setSelectedIds: setListSelectedIdsAction,
        }
    )(GalleryEdit)
);

import React, { useEffect, useState } from 'react';
import AssetEdit from './AssetEdit';
import { GET_ONE, withDataProvider, useNotify } from 'react-admin';
import { ConvertCreateTransformationResponseToObject } from '../../utils/httpResponseObject';
import { Transformation } from '../transformations/types/Transformation';

export default withDataProvider(props => {
    const {
        dataProvider,
        history,
        match,
    } = props;
    const [assetID, setAssetID] = useState<string | undefined>();
    const [transformation, setTransformation] = useState<Transformation | undefined>();
    const [fetched, setFetched] = useState(false);

    const notify = useNotify();

    useEffect(() => {
        if (!fetched) {
            dataProvider(GET_ONE, 'transformation', {
                id: match.params.transformationID
            })
                .then((response) => {
                    let transformation: Transformation = response.data;
                    ConvertCreateTransformationResponseToObject(transformation);
                    setAssetID(transformation.assetID);
                    setTransformation(transformation);
                    setFetched(true);
                });
        }
    }, [dataProvider, fetched, match.params.transformationID, match.params.videoID]);

    const handleAddTransformationToVideo = (transformation, id) => {
        if (
            id === match.params.videoID
      && transformation.assetID === assetID
      && transformation.id !== match.params.transformationID
        ) {
            dataProvider('UPDATE_THUMBNAIL', 'video', { id, transformationID: transformation.id })
                .then(() => {
                    notify('resources.video.notification.updated');
                    history.push({ pathname: `/video/${id}/3` });
                })
                .catch(() => notify('resources.video.notification.update_failed'));
        } else {
            history.push({ pathname: `/video/${id}/3` });
        }
    };

    return (
        <>
            {fetched && (
                <AssetEdit
                    {...props}
                    basePath={'/asset'}
                    id={assetID}
                    resource={'asset'}
                    transformation={transformation}
                    handleAddTransformationToVideo={handleAddTransformationToVideo}
                    videoID={match.params.videoID}
                />
            )}
        </>
    );
});

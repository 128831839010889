import React, { useState } from 'react';
import { EditButton, withDataProvider } from 'react-admin';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import { Filter9Plus, Restore } from '@material-ui/icons';
import { DOWNLOAD_URL } from '../../utils/config';
import { Button, Popover, Typography, IconButton } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const cardStyle = {
    width: '100%'
};

const cardMediaStyle = {
    paddingTop: '100%'
};

const styles = theme => {
    return {
        title: {
            minHeight: '3em'
        },
        subheader: {
            minHeight: '3em',
            maxHeight: '3em',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
        }
    };
};

export const GalleryField = withDataProvider(
    withStyles(styles)(
        (props: { dataProvider; record; hasEdit; basePath; classes }) => {
            const { dataProvider, classes } = props;
            const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

            const handleRestore = id => {
                dataProvider(
                    'RESTORE_GALLERY',
                    'gallery',
                    { id: id },
                    {
                        onSuccess: {
                            notification: {
                                body: 'resources.gallery.notification.gallery_restored'
                            },
                            refresh: true
                        }
                    }
                )
                    .then(response => {})
                    .catch(error => console.error(error));
            };

            return (
                <Card style={cardStyle}>
                    <div>
                        <CardHeader
                            avatar={
                                <Avatar aria-label="Gallery">
                                    <Filter9Plus />
                                </Avatar>
                            }
                            title={
                                props.record.title
                                    ? props.record.title.length > 12
                                        ? `${props.record.title.substr(0, 12)}…`
                                        : props.record.title
                                    : ''
                            }
                            subheader={new Date(props.record.createdAt).toLocaleString()}
                            classes={classes}
                        />
                    </div>
                    <Link to={`/gallery/${props.record.id}`}>
                        <CardMedia
                            style={cardMediaStyle}
                            image={DOWNLOAD_URL() + '/api/v1/gallery/' + props.record.id + '/preview'}
                        />
                    </Link>
                    <CardActions disableSpacing>
                        {props.hasEdit && props.record.deletedAt !== undefined && (
                            <Button
                                onClick={() => {
                                    handleRestore(props.record.id);
                                }}
                                color="default"
                            >
                                <Restore />
                Restore
                            </Button>
                        )}
                        {props.hasEdit && props.record.deletedAt === undefined && (
                            <>
                                <IconButton
                                    tabIndex={-1}
                                    onMouseLeave={() => {
                                        setAnchorEl(null);
                                    }}
                                    onMouseEnter={e => {
                                        setAnchorEl(e.currentTarget);
                                    }}
                                >
                                    <InfoIcon />
                                </IconButton>
                                <Popover
                                    style={{ pointerEvents: 'none' }}
                                    open={Boolean(anchorEl)}
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left'
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left'
                                    }}
                                >
                                    <Typography variant={'subtitle1'}>
                                        {props.record.title || 'undefined'}
                                    </Typography>
                                    <Typography variant={'caption'}>
                                        {props.record.description}
                                    </Typography>
                                </Popover>
                                <EditButton basePath={props.basePath} record={props.record} tabIndex="-1" />
                            </>
                        )}
                    </CardActions>
                </Card>
            );
        }
    )
);

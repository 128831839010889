import englishMessages from 'ra-language-english';
import {mergeTranslations, TranslationMessages} from 'ra-core';

const ozCustomEnglishMessages = {
    'Add to CMS': 'Add to CMS',
    'Analyse': 'Analyse',
    'Analysis': 'Analysis',
    'Any': 'Any',
    'Delete?': 'Delete?',
    'Deleted': 'Deleted',
    'Deleted?': 'Deleted?',
    'Edit': 'Edit',
    'EPG': 'EPG',
    'Episode': 'Episode',
    'files': 'Files',
    'Help': 'Help',
    'history': 'History',
    'Home': 'Home',
    'ID': 'ID',
    'Images': 'Images',
    'Landscape': 'Landscape',
    'Last 30 days': 'Last 30 days',
    'Last Year': 'Last Year',
    'Latest Galleries': 'Latest Galleries',
    'Latest Images': 'Latest Images',
    'Latest Videos': 'Latest Videos',
    'Learn More': 'Learn More',
    'Main Colors': 'Main Colors',
    'Metadata': 'Metadata',
    'no': 'no',
    'Not Found': 'Not Found',
    'Panorama': 'Panorama',
    'policies': 'Policies',
    'Portrait': 'Portrait',
    'Recommended tags' : 'Recommended tags',
    'Reset': 'Reset',
    'Search for tags...': 'Search for tags...',
    'Search keyword...': 'Search keyword...',
    'Search': 'Search',
    'Show': 'Sendung',
    'Sizes': 'Sizes',
    'Square': 'Square',
    'Summary': 'Summary',
    'Taken': 'Taken',
    'Text': 'Text',
    'to change position - press and hold': 'to change position - press and hold',
    'Today': 'Today',
    'Top Asset Tags': 'Top Asset Tags',
    'Top Tags Galleries': 'Top Tags Galleries',
    'Transformations': 'Transformation |||| Transformations',
    'Updated since': 'Updated since',
    'Upload from disk...': 'Upload from disk...',
    'Upload': 'Upload',
    'Uploaded': 'Uploaded',
    'Videos': 'Videos',
    'yes': 'yes',
    'Yesterday': 'Yesterday',
    'Date': 'Date',
    warning_readonly: 'You only have read-only access.',
    resources: {
        asset: {
            name: 'Asset |||| Assets',
            fields: {
                files: 'Files',
                description: 'Description (AltText)',
                copyright: 'Copyright',
                title: 'Title',
            },
            action: {
                download: 'Download',
                upload: 'Upload',
                upload_from_disc: 'Upload from disc',
                add_to_gallery: 'Add to gallery',
                add_to_video: 'Add to video',
            },
            notification: {
                asset_restored: 'Asset has been restored',
                gif_too_big: 'This GIF is too big, we have a %{limit}MB limit.',
                image_uploaded: 'Asset uploaded successfully',
                image_uploaded_failed: 'Upload failed for unknown reason.',
                image_uploaded_failed_already_exists: 'Upload failed. Image already exists',
                service_unavailable: 'Service unavailable',
                updated: 'Asset updated',
            },
            filters: {
                filters: 'Filters',
                brightness: 'Brightness',
                contrast: 'Contrast',
                saturation: 'Saturation',
                focus: 'Focus',
                grid: 'Grid',
                additional: 'Additional',
                quality: 'Quality',
                noise: 'Noise',
                roundCorner: 'Round Corner',
                grayscale: 'Greyscale',
                redeye: 'Remove Red-Eye',
                reset: 'Reset',
                save: 'Save',
            },
            transformation: {
                created: 'Transformation created',
            },
            fileInfo: {
                file_type: 'File type',
                file_size: 'File size',
                resolution: 'Resolution',
            },
        },
        gallery: {
            name: 'Gallery |||| Galleries',
            action: {
                create: 'Gallery create',
                add_to_gallery: 'Add',
                remove_from_gallery: 'Remove',
            },
            notification: {
                updated: 'Gallery updated',
                gallery_created: 'Gallery created',
                gallery_updated: 'Gallery updated',
                gallery_restored: 'Gallery restored successfully',
                asset_removed: 'Asset removed',
                assets_added: 'Asset added',
                tag_added: 'Tag added',
            },
            label: {
                add_assets_to_gallery: 'Add assets to gallery',
                remove_asset_from_gallery: 'Remove asset',
                for_change_position_press_and_hold: 'For change position - press and hold',
                set_as_gallery_teaser: 'Set as gallery teaser',
            },
            fields: {
                description: 'Description (AltText)',
                title: 'Title',
            },
        },
        getty: {
            name: 'Getty image, Getty images',
            notification: {
                image_uploaded: 'Getty Images asset uploaded successfully',
            },
            click_to_upload: 'Click for upload',
        },
        tag: {
            notification: {
                tag_created: 'Tag created',
                tag_added: 'Tag added',
                tag_creation_failed: 'Tag creation failed',
            },
        },
        playlist: {
            action: {
                create: 'Create Playlist',
            },
            fields: {
                autoFill: 'Autofill Videos',
                createdAt: 'Created at',
                description: 'Description',
                importID: 'Import ID',
                title: 'Title',
                updatedAt: 'Updated at',
            },
            label: {
                unpin: 'Unpin Video',
            },
            notification: {
                created: 'Playlist created',
                creation_failed: 'Playlist could not be created',
                updated: 'Playlist updated',
                playlist_restored: 'Playlist restored successfully',
            },
        },
        epg: {
            label: {
                add_thumbnails: 'Add thumbnail to EPG',
            },
            action: {
                add_to: 'Add to EPG',
                go_to_eventstream: 'Edit as EventStream',
            },
            broadcast: {
                endTime: 'End',
                fromDate: 'from',
                isActive: 'Is Active',
                isEventStream: 'It is an Eventstream',
                isHighlight: 'Broadcast is a Highlight',
                source: 'Broadcast\'s source',
                startTime: 'Start',
                toDate: 'to',
            },
            category: {
                name: 'Category',
            },
            channel: {
                id: 'Channel',
                name: 'Channel',
            },
            episode: {
                description: 'Headline Teaser',
                duration: 'Duration',
                fsk: 'Age Restriction',
                geoBlockingType: 'Type of Geo Blocking',
                isHighlight: 'Episode is a Highlight',
                longDescription: 'Description',
                materialId: 'Material Id',
                noCountriesSet: 'There are no countries set',
                number: 'Episode',
                season: 'Season',
                serienId: 'Series Id',
                source: 'Episode\'s source',
                subcategory: 'Category',
                subtitle: 'Headline',
                transformationId: 'TransformationID of linked Asset',
                vodVideoId: 'ID of linked Video',
            },
            notification: {
                warning_list_cached: 'Beware: Because of caching this list does not show the most recent changes. You\'ll find those here:',
                warning_leaving_site_title: 'Leaving Site',
                warning_leaving_site_text: 'Do you really want to leave the site?',
            },
            program: {
                title: 'Program',
            },
        },
        video: {
            action: {
                create: 'Create Video',
            },
            label: {
                add_thumbnails_to_video: 'Add thumbnail to video',
                metadata: 'Metadata',
                add_matchId_to_video: 'Add Match ID to video',
                without_matchday: 'Without matchday',
                matchday: 'Matchday',
                hometeam: 'Hometeam',
                awayteam: 'Awayteam',
                season: 'Season',
            },
            notification: {
                created: 'Video created',
                creation_failed: 'Video could not be created',
                restored: 'Video has been restored',
                tracking_mandatory_fields_missing: 'To publish the video please fill in Tracking\'s sport and source',
                tracking_value_show_fallback: 'Switched show to "%{show}" since there is a matchId and the competition is "%{competition}"',
                update_failed: 'Video could not be updated',
                updated: 'Video updated',
                uploaded: 'Video uploaded',
            },
            fields: {
                'link.value.href': 'Link\'s URL',
                'link.value.title': 'Link\'s Title',
                'tracking.value.competition': 'Tracking\'s Competition',
                'tracking.value.content': 'Tracking\'s Content',
                'tracking.value.show': 'Tracking\'s Show',
                'tracking.value.source': 'Tracking\'s Source',
                'tracking.value.sport': 'Tracking\'s Sport',
                'vast.value.midroll': 'Show midrolls?',
                'vast.value.postroll': 'Show postrolls?',
                'vast.value.preroll': 'Show prerolls?',
                ageRating: 'Age rating',
                complete: 'Complete Episode',
                countries: 'Countries that are blacklisted or whitelisted',
                headlineEditorial: 'Editorial Title',
                S1TVDescription: 'Sport1 TV Description (Optional)',
                S1TVTitle: 'Sport1 TV Title (Optional)',
                title: 'Seo Title',
                titleEnglish: 'English Title (Optional)',
            },
        },
        users: {
            action: {
                create: 'User create',
            },
            form: {
                validate: {
                    password: 'Password is required!',
                    language: 'Language is required!',
                    passwordMatch: 'Passwords do not match!',
                    passwordRepeat: 'Confirm password'
                }
            },
            profile: {
                fields: {
                    title: 'Profile Fields',
                    subtitle: 'Choose which fields to update'
                }
            },
            response: {
                alreadyExist: 'User already exists!'
            }
        },
        file: {
            name: 'Files'
        }
    },
    mam: {
        aws: {
            recognition: {
                action_finished: 'AWS Celebritiy Rekognition finished',
                error: 'AWS Celebritiy Rekognition - failed',
                text: 'Amazon Rekognition can recognize thousands of celebrities in a wide range of categories, such as entertainment and media, sports, business, and politics. With Amazon Rekognition, you can recognize celebrities in images and in stored videos. You can also get additional information for recognized celebrities.',
            },
        },
        caption: 'Caption',
        clipboard_copy: 'copy %{object}',
        clipboard_saved: '%{object} saved on clipboard',
        close: 'Close',
        create_episode: 'Create episode: %{value}',
        create_tag: 'Create tag: %{value}',
        dashboard: {
            average: 'Average',
            images: 'Images',
            gallery: 'Galleries',
            last_30_days: 'Last 30 Days',
            learn_more: 'More',
            overall: 'Overall',
            sizes: 'Sizes',
            transformations: 'Transformations',
            videos: 'Videos',
            playlists: 'Playlists',
        },
        delete_thumbnail: 'Do you want to unfasten this thumbnail from the video?',
        delete_transformation: 'Do you really want to delete %{transformation}?',
        found: 'Found: %{count}',
        image_caption: 'Caption',
        load_more: 'load more',
        loading: 'loading',
        navigation_tag_already_exists: 'Only one navigation tag allowed',
        no_results: 'No results',
        search: {
            image: 'Image',
            landscape: 'Landscape',
            panorama: 'Panorama',
            portrait: 'Portrait',
            square: 'Square',
            video: 'Video',
        },
        title: 'Title',
        transformation_edit: 'Edit',
        transformation_preview: 'Preview'
    }
};

const customEnglishMessages: TranslationMessages = mergeTranslations(
    englishMessages,
    ozCustomEnglishMessages
);

export default customEnglishMessages;

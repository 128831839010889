const allowedChannels = {
    106: true,  // SPORT1
    4987: true, // ext1
    4990: true, // ext2
    4993: true, // ext3
    4996: true, // event1
    4999: true, // event2
};

export default function isChannelAllowedAsEventstreamSource(channel) {
    return allowedChannels[channel && channel.id];
}

import React from 'react';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';

import DeleteIcon from '@material-ui/icons/Delete';
import SearchParamsType from './types/SearchParamsType';
import { translate } from 'react-admin';

export const SearchPanelDeleteElement = translate((props: {
  searchParams: SearchParamsType;
  onChange: (event: React.MouseEvent, value: string[]) => void;
  translate: Function;
}) => {
    return (
        <ToggleButtonGroup
            value={props.searchParams.deleted}
            onChange={props.onChange}
            exclusive={true}
        >
            <ToggleButton value={true} title={props.translate('Deleted?')}>
                <DeleteIcon />
            </ToggleButton>
        </ToggleButtonGroup>
    );
});

import { GET_LIST, withDataProvider, translate } from 'react-admin';
import AsyncSelect from 'react-select/async';
import React from 'react';
import { SelectOptionInterface } from '../../types';

export const TagMultiSelectElement = withDataProvider(translate(
    (props: {
    classes: any;
    dataProvider: any;
    onChange: (selectedElements: SelectOptionInterface[]) => void;
    menuPortalTarget: string;
    defaultValues: SelectOptionInterface[];
    translate: Function;
  }) => {
        const loadOptions = inputValue => {
            let options: SelectOptionInterface[] = [];
            const { dataProvider } = props;
            return dataProvider(GET_LIST, 'tag', inputValue)
                .then(response => {
                    response.data.forEach(value => {
                        options.push({ label: value.tag, value: value.id, approved: value.approved, sportID: value.sportID, externalID: value.externalID });
                    });
                    return new Promise(resolve => {
                        resolve(options);
                    });
                })
                .catch(error => console.error(error));
        };

        return (
            <AsyncSelect
                value={props.defaultValues}
                menuPortalTarget={props.menuPortalTarget || 'document.body'}
                placeholder={props.translate('Search for tags...')}
                noOptionsMessage={(inputValue) => props.translate('mam.no_results')}
                loadOptions={loadOptions}
                isMulti
                onChange={props.onChange}
            />
        );
    }
));

import { SelectOptionInterface } from '../../types';
import React, { useEffect, useState } from 'react';
import {
    DeleteButton,
    Edit,
    SimpleForm,
    TextInput,
    Toolbar,
    translate,
    UPDATE,
    withDataProvider,
    FormDataConsumer,
    useNotify
} from 'react-admin';

import { BackButton } from '../buttons/BackButton';
import {
    Button,
    Collapse,
    Grid,
    Typography,
    Chip
} from '@material-ui/core';
import TagWrapper from '../TagWrapper';
import ArrowUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';
import AutoSave from '../../utils/AutoSave';
import { useForm, Field } from 'react-final-form';
import { ROLES } from '../../utils/config';

interface Props {
  basePath: string;
  id: string;
  permissions;
  match: Object;
  location: Object;
  history: Object;
  resource: string;
  options: Object;
  hasList: boolean;
  hasEdit: boolean;
  hasShow: boolean;
  hasCreate: boolean;
  dataProvider: any;
  translate;
  handleAddToEZPublish;
  ez_data;
}

export const GalleryEditForm = withDataProvider(
    translate(
        ({
            basePath,
            dataProvider,
            ez_data,
            handleAddToEZPublish,
            history,
            id,
            location,
            permissions,
            resource,
            translate,
        }: Props) => {
            const initialTags: SelectOptionInterface[] = [];
            const [tags, setTags] = useState(initialTags);
            const initialRecommendedTags: SelectOptionInterface[] = [];
            const [recommendedTags, setRecommendedTags] = useState(
                initialRecommendedTags
            );
            const [open, setOpen] = useState(true);

            const notify = useNotify();

            useEffect(() => {
                dataProvider('GET_RECOMMENDED_TAG_LIST', 'gallery', {
                    id: id
                }).then(response => {
                    let values: any[] = [];
                    response.data.forEach(value => {
                        if (
                            tags.filter(
                                selectedValue => selectedValue.label === value.tag
                            ).length === 0
                        )
                            values.push({ label: value.tag, value: value.id });
                    });
                    setRecommendedTags(values);
                });
                // eslint-disable-next-line
      }, []);

            const handleChange = values => {
                values.tags = values.tags || [];
                dataProvider(UPDATE, 'gallery', { ...values, id: id })
                    .then(response => {
                        notify('resources.gallery.notification.gallery_updated');
                    })
                    .catch(error => console.error(error));

            };

            const Removal = (props) => {
                if (![ROLES.admin.id.toString(), ROLES.videoAdmin.id.toString(), ROLES.assetRW.id.toString(), ROLES.superEditor.id.toString()].includes(permissions)) {
                    return null;
                } else if (!props.record.deletedAt) {
                    return <DeleteButton undoable={false} {...props} />;
                }
                return null;
            };

            return (
                <Edit
                    undoable={false}
                    id={id}
                    basePath={basePath}
                    location={location}
                    resource={resource}
                >
                    <SimpleForm
                        elevation={0}
                        toolbar={
                            <Toolbar
                                basePath={basePath}
                                location={location}
                                resource={resource}
                            >
                                <BackButton
                                    history={history}
                                    path="/gallery"
                                    label={translate('ra.action.back')}
                                />
                                <Button
                                    size="small"
                                    color="default"
                                    onClick={handleAddToEZPublish}
                                    disabled={ez_data ? ez_data.ozType !== 'gallery' : true}
                                >
                                    {translate('Add to CMS')}{' '}
                                </Button>
                                <Removal />
                            </Toolbar>
                        }
                    >

                        <AutoSave save={handleChange} />
                        <FormDataConsumer>
                            {formDataProps => {
                                setTags(formDataProps.record.tags);
                                return (
                                    <Grid container spacing={1} style={{ width: '100%' }}>
                                        <Grid item xs={11}>
                                            <TextInput
                                                source="title"
                                                label={translate('resources.gallery.fields.title')}
                                                style={{ width: '100%', paddingBottom: 15 }}
                                                variant="standard"
                                            />
                                        </Grid>
                                        <Grid item xs={1} style={{ textAlign: 'right' }}>
                                            <Button onClick={() => setOpen(!open)}>
                                                {open ? <ArrowUpIcon /> : <ArrowDownIcon />}
                                            </Button>
                                        </Grid>
                                        <Collapse in={open} style={{ width: '100%' }}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <TextInput
                                                        source="description"
                                                        label={translate('resources.gallery.fields.description')}
                                                        style={{ width: '100%' }}
                                                        multiline={true}
                                                        variant="standard"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography
                                                        style={{ display: 'inline' }}
                                                        variant={'caption'}
                                                    >
                          Tags
                                                    </Typography>
                                                    <Field name="tags">
                                                        {props => (
                                                            <TagWrapper
                                                                selectedTags={props.input.value && props.input.value.map(tagEl => ({...tagEl, label: tagEl.tag, value: tagEl.id }))}
                                                                setSelectedTags={setTags}
                                                                onChange={props.input.onChange}
                                                                permissions={permissions}
                                                                restrictNavTags={true}
                                                                {...formDataProps}
                                                            />
                                                        )}
                                                    </Field>
                                                </Grid>
                                                <Grid item xs={12} style={{ padding: 8 }}>
                                                    {recommendedTags.length > 0 && (
                                                        <Grid container spacing={0}>
                                                            <Grid item xs={12}>
                                                                <Typography
                                                                    style={{ display: 'inline' }}
                                                                    variant={'caption'}
                                                                >
                                                                    {translate('Recommended tags')}:{' '}
                                                                </Typography>
                                                                {
                                                                    recommendedTags.map((tag, index) =>{
                                                                        const form = useForm();
                                                                        return (
                                                                            <Chip
                                                                                key={index}
                                                                                clickable={true}
                                                                                label={tag.label}
                                                                                color={'secondary'}
                                                                                onClick={() => {
                                                                                    form.change('updatedAt', new Date().getTime());
                                                                                    setRecommendedTags(
                                                                                        recommendedTags.filter(
                                                                                            t => t.label !== tag.label
                                                                                        )
                                                                                    );
                                                                                    formDataProps.record.tags = [...formDataProps.record.tags, { tag: tag.label, id: tag.value }];
                                                                                    handleChange(formDataProps.record);
                                                                                }}
                                                                            />
                                                                        );
                                                                    }
                                                                    )
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Collapse>
                                    </Grid>
                                );}}
                        </FormDataConsumer>
                    </SimpleForm>
                </Edit>
            );
        }
    )
);

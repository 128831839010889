import React, { useState, forwardRef } from 'react';
import { AppBar, translate, UserMenu, MenuItemLink } from 'react-admin';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { UploadDialog } from './UploadDialog';
import { Tooltip } from '@material-ui/core';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import { ROLES_BY_ID, ROLES } from '../utils/config';

const styles = {
    root: {
        width: '100%'
    },
    grow: {
        flexGrow: 1
    }
};

const ProfileMenu = forwardRef<any, any>((props, ref) => {
    return (
        <MenuItemLink
            ref={ref}
            to={'/profile'}
            primaryText="Profile"
            leftIcon={<AccountBoxIcon />}
            onClick={props.onClick}
        />
    );});

const OZUserMenu = (props: any) => (
    <UserMenu {...props}>
        <ProfileMenu />
    </UserMenu>
);

const AppBarCustom = (props: any) => {
    const { classes, staticContext, translate, ...rest } = props;
    const [dialogOpen, setDialogOpen] = useState(false);
    let permissions: string = 'none';

    try {
        permissions = String(localStorage.getItem('permission'));
    } catch (error) {
        console.warn(error);
    }

    const role = ROLES_BY_ID[permissions];
    const roleName = (role && role.name) || '';

    const openDialog = () => {
        setDialogOpen(true);
    };

    return (
        <AppBar {...rest} userMenu={<OZUserMenu />} >
            <div style={{marginRight: '1ch'}}>
                {roleName}
                {ROLES.assetRO.id === Number(permissions) ? ': ' + translate('warning_readonly') : ''}
            </div>
            <UploadDialog handleOpen={setDialogOpen} open={dialogOpen} />
            <Typography
                variant="h6"
                color="inherit"
                className={classes.grow}
                id="react-admin-title"
            />
            {ROLES.assetRO.id === Number(permissions) ? null :
                <Tooltip title={translate('resources.asset.action.upload')}>
                    <Button color="inherit" onClick={openDialog}>
                        <CloudUploadIcon />
                    </Button>
                </Tooltip>
            }
        </AppBar>
    );
};

export default withStyles(styles)(withRouter(translate(AppBarCustom)));

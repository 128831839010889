import React from 'react';
import { Button } from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowBack';

interface BackButtonProps {
  label?: string;
  history;
  path?: string;
}

export const BackButton: React.FC<BackButtonProps> = ({
    label = '',
    history,
    path
}) => {
    const handleClick = () => {
        if (path) {
            history.push(path);
        } else {
            history.go(-1);
        }
    };

    return (
        <Button onClick={handleClick}>
            <BackIcon /> {label}
        </Button>
    );
};

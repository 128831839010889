import { SortableContainer } from 'react-sortable-hoc';
import { Grid, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import SortableGalleryAssetItem from './SortableGalleryAssetItem';
import { GalleryAssetInterface } from '../../types';
import { translate } from 'react-admin';
import { Fab } from '@material-ui/core';

const SortableGalleryAssetList = SortableContainer(
    ({ galleryId, setOpenDialog, items, removeAssetFromGallery, updateAssetsInGallery, translate, updateIsTeaser }) => {
        return (
            <Grid container spacing={1}>
                <Tooltip title={translate('resources.gallery.label.add_assets_to_gallery')}>
                    <Fab
                        onClick={() => {
                            setOpenDialog(true);
                        }}
                        color="secondary"
                        style={{ position: 'absolute', zIndex: 999999999 }}
                    >
                        <AddIcon />
                    </Fab>
                </Tooltip>
                {items.map((element: GalleryAssetInterface, index) => (
                    <SortableGalleryAssetItem
                        galleryId={galleryId}
                        removeAssetFromGallery={removeAssetFromGallery}
                        updateAssetsInGallery={updateAssetsInGallery}
                        key={`item-${index}`}
                        index={index}
                        element={element}
                        updateIsTeaser={updateIsTeaser}
                    />
                ))}
            </Grid>
        );
    }
);

export default translate(SortableGalleryAssetList);

import {httpRequestObject} from './httpRequestObjects';
import {
    DELETE,
} from 'react-admin';

export default (type, params, apiUrl, options): httpRequestObject => {
    let url;
    const resource = 'video_transformation';
    options.headers.append('Content-Type', 'application/json');
    switch (type) {
    case DELETE: {
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'DELETE';
        break;
    }
    }

    return {url, options};
};

import React, { useState } from 'react';
import {
    CREATE,
    Create,
    FileField,
    FileInput,
    ImageField,
    ImageInput,
    SimpleForm,
    required,
    translate,
    useNotify,
    withDataProvider,
} from 'react-admin';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

const styles = () => ({
    card: {
        boxShadow: 'none',
        width: '100%',
        height: '100%'
    }
});

const GIF_LIMIT_MB = 4;
const GIF_LIMIT_BYTES = GIF_LIMIT_MB * 1024 * 1024;

export default withDataProvider(translate(withStyles(styles)(connect(({ eZDataReducer }: {eZDataReducer: any}) => {
    return eZDataReducer;
}, {})((props: {
  basePath: string
  dataProvider;
  eventstreamId: string;
  galleryId;
  handleEventstream: CallableFunction
  handleOpen;
  translate,
  videoId;
}) => {
    const {
        dataProvider,
        eventstreamId,
        galleryId,
        handleEventstream,
        handleOpen,
        translate,
        videoId,
    } = props;

    const dispatch = useDispatch();
    const notify = useNotify();
    const [loading, setLoading] = useState(false);

    const save = (file) => {
        if (!file) {
            return;
        }

        if (file.size >= GIF_LIMIT_BYTES && file.type === 'image/gif') {
            notify(translate('resources.asset.notification.gif_too_big', {
                limit: GIF_LIMIT_MB,
            }), 'warning');
            return;
        }

        setLoading(true);
        dataProvider(CREATE, 'asset', file)
            .then((response) => {
                notify('resources.asset.notification.image_uploaded');
                if (galleryId) {
                    dataProvider('ADD_ASSETS', 'gallery', {
                        galleryId,
                        assetIds: [response.data.id]
                    })
                        .then(() => {
                            dispatch(push(`/gallery/${galleryId}`));
                            notify('resources.gallery.notification.assets_added');
                            handleOpen(false);
                            setLoading(false);
                            window.location.reload();
                        })
                        .catch((error) => {
                            setLoading(false);
                            notify(error.message, 'warning');
                        });
                } else if (videoId) {
                    dataProvider('UPDATE_THUMBNAIL', 'video', { id: videoId, assetID: response.data.id })
                        .then(response => {
                            dispatch(push(`/video/${videoId}/3`));
                            notify('resources.gallery.notification.assets_added');
                            handleOpen(false);
                            setLoading(false);
                            window.location.reload();
                        })
                        .catch(error => {
                            setLoading(false);
                            notify(error.message, 'warning');
                        });
                } else if (eventstreamId) {
                    dataProvider('GET_TRANSFORMATION_LIST', 'asset', {id: response.data.id})
                        .then(response => {
                            handleEventstream(response.data[0].id);
                            handleOpen(false);
                        })
                        .catch(error => {
                            notify(error.message, 'warning');
                        });
                } else {
                    dispatch(push(`${props.basePath}/${response.data.id}`));
                    handleOpen(false);
                    setLoading(false);
                }
            })
            .catch((error) => {
                notify(error.message, 'warning');
                console.error(error);
                setLoading(false);
            });
    };

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <Create {...props}>
            <SimpleForm
                resource="asset"
                toolbar={null}
            >
                {props.basePath === '/asset' ? (
                    <ImageInput
                        source="file"
                        accept="image/jpeg,image/gif,image/png,image/svg+xml"
                        onChange={save}
                        validate={required()}
                    >
                        <ImageField source="src" title="title" />
                    </ImageInput>
                ) : (
                    <FileInput
                        source="file"
                        accept="application/pdf"
                        onChange={save}
                        validate={required()}
                    >
                        <FileField source="src" title="title" />
                    </FileInput>
                )}
            </SimpleForm>
        </Create>
    );
}))));

/**
 * Beware: Evenstreams are problematic.
 * We get the list of eventstreams indirectly via Elastic.
 * Deleting an eventstream does not immediately remove it from Elastic.
 * There is no way around this, but a client-side hack.
 * This, of cource, might get the source of ugly bugs in the future.
 */
let removedEventstreams = {};
try {
    removedEventstreams = JSON.parse(localStorage.getItem('removedEventstreams') || '{}');
} catch (ignore) {
    removedEventstreams = {};
}

export function isRemoved(id) {
    return removedEventstreams[id] || false;
}

export function remove(id) {
    removedEventstreams[id] = true;
    try {
        localStorage.setItem('removedEventstreams', JSON.stringify(removedEventstreams));
    } catch (ignore) {
        console.warn(ignore);
    }
}

import React, { useState } from 'react';
import {
    DeleteButton,
    FormDataConsumer,
    SimpleForm,
    TextField,
    TextInput,
    Toolbar,
    translate,
    UPDATE,
    useNotify,
    withDataProvider,
} from 'react-admin';
import { useEditController } from 'ra-core';
import {
    Card,
    Grid,
    Paper,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import TagWrapper from '../TagWrapper';
import { BackButton } from '../buttons/BackButton';
import AutoSave from '../../utils/AutoSave';
import { Field } from 'react-final-form';
import { Title } from 'react-admin';
import { FileInfo } from '../assets/FileInfo';
import {DOWNLOAD_URL} from '../../utils/config';
import AllPagesPDFViewer from '../pdf/AllPagesPDFViewer';


const styles = theme => ({
    root: {
        flexGrow: 1,
        marginTop: 12
    },
    formInput: {
        width: '100%',
        paddingBottom: 10
    },
    fileInfo: {
        '& p': {
            color: 'grey',
            padding: 5
        }
    },
    pdf: {
        height: '100%',
        maxHeight: '600px',
        overflow: 'auto',
    },
});

interface Props {
  basePath: string;
  classes: any;
  dataProvider: any;
  history;
  id: string;
  permissions;
  resource: string;
  translate;
}

const FileEdit: React.FC<Props> = (props: Props) => {
    const {
        classes,
        dataProvider,
        id,
        permissions,
        translate,
    } = props;

    const notify = useNotify();

    // eslint-disable-next-line
  const [fileType, setFileType] = useState<string | undefined>();

    const handleChange = (values) =>
        dataProvider(UPDATE, 'asset', { ...values, id: props.id })
            .then(response => notify('resources.asset.notification.updated'))
            .catch(error => console.error(error));

    const handleFileType = (fileType: string) => setFileType(fileType);

    const controllerProps = useEditController(props);

    return (
        <React.Fragment>
            <Grid container className={classes.root} spacing={1}>
                <Grid item xs={12} md={4} style={{ minWidth: 400 }}>
                    <Paper elevation={2} >
                        {controllerProps && controllerProps.record && (
                            <Card >
                                <Title title={`${translate('resources.asset.name', 1)} #${id}`} />
                                <SimpleForm
                                    {...controllerProps}
                                    toolbar={
                                        <Toolbar {...props}>
                                            <BackButton
                                                history={props.history}
                                                label={translate('ra.action.back')}
                                            />
                                            <DeleteButton undoable={false} {...props} />
                                        </Toolbar>
                                    }
                                >
                                    <AutoSave save={handleChange} />
                                    <TextField className={classes.formInput} source="id" variant="standard" />
                                    <FileInfo className={classes.fileInfo} handleFileType={handleFileType} />
                                    <TextInput className={classes.formInput} source="title" variant="standard" />
                                    <TextInput source="description" multiline className={classes.formInput} variant="standard" />
                                    <Typography variant="caption">Tags</Typography>
                                    <FormDataConsumer>
                                        {formDataProps =>
                                            <div>
                                                <Field name="tags">
                                                    {props =>
                                                        <TagWrapper
                                                            selectedTags={props.input.value && props.input.value.map(tagEl => ({...tagEl, label: tagEl.tag, value: tagEl.id }))}
                                                            onChange={props.input.onChange}
                                                            permissions={permissions}
                                                            {...formDataProps}
                                                        />
                                                    }
                                                </Field>
                                            </div>
                                        }
                                    </FormDataConsumer>
                                </SimpleForm>
                            </Card>
                        )}
                    </Paper>
                </Grid>
                <Grid item xs>
                    <div className={classes.pdf}>
                        <AllPagesPDFViewer pdf={`${DOWNLOAD_URL()}/c/o/${id}.pdf`} />
                    </div>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default withDataProvider(translate(withStyles(styles)(FileEdit)));

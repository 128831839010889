import React, { useEffect, useState } from 'react';
import {
    translate,
    withDataProvider,
} from 'react-admin';
import {
    AppBar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Toolbar,
    Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AssetsList from '../assets/AssetsList';
import { useHistory } from 'react-router-dom';

export default withDataProvider(translate((props) => {
    const {
        addThumbnailsToEpg,
        dataProvider,
        openDialog,
        setOpenDialog,
        translate,
        handleAddTransformationToEventstream,
    } = props;
    const [createPath, setCreatePath] = useState('');
    const [createSearch, setCreateSearch] = useState('');
    const history = useHistory();

    useEffect(() => {
        setCreatePath(history.location.pathname);
        setCreateSearch(history.location.search);
    }, [history.location.pathname, history.location.search]);

    const addAssetsToEpg = (assetIDs) => {
        dataProvider('GET_TRANSFORMATION_LIST', 'asset', { id: assetIDs[0] })
            .then((transformations) => {
                // use the first transformation (if exists), the editor may pick
                // another one later
                addThumbnailsToEpg((transformations.data[0] && transformations.data[0].id) || null);
                props.setSelectedIds('asset', []);
                handleCloseDialog();
            })
            .catch(error => console.error(error));
    };

    const handleCloseDialog = () => {
        history.push({pathname: createPath, search: createSearch});
        setOpenDialog(false);
    };

    return <>
        <Dialog fullScreen={true} open={openDialog}>
            <AppBar>
                <Toolbar>
                    <IconButton color="inherit" onClick={() => handleCloseDialog()} aria-label="Close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" color="inherit">
                        {translate('resources.epg.label.add_thumbnails')}
                    </Typography>
                </Toolbar>
            </AppBar>
            <DialogTitle id="simple-dialog-title">&nbsp;</DialogTitle>
            <DialogContent>
                <AssetsList
                    {...props}
                    addAssetsToEpg={addAssetsToEpg}
                    hasEdit={false}
                    handleAddTransformationToEventstream={handleAddTransformationToEventstream}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => handleCloseDialog()}
                    color="primary"
                >
                    {translate('mam.close')}
                </Button>
            </DialogActions>
        </Dialog>
    </>;
}));

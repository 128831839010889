import React, { FunctionComponent, useState } from 'react';
import { GettyImagesList } from './GettyImagesList';
import { Grid, Input, InputAdornment, LinearProgress } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Cancel';
import { withDataProvider, translate, useNotify } from 'react-admin';
import { DataProvider } from 'ra-core';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { GETTY_ACTIVE } from '../../utils/config';

interface DispatchProps {
  dataProvider?: DataProvider;
  handleOpen;
  galleryId;
  videoId;
}

export const GettyUpload: FunctionComponent<DispatchProps> = translate(
    withDataProvider(
        ({ dataProvider, handleOpen, translate, galleryId, videoId }) => {
            if (!GETTY_ACTIVE) {
                return null;
            }

            const [keyword, setKeyword] = useState('');
            const [resultCount, setResultCount] = useState(0);
            const [isDownloadingImage, setIsDownloadingImage] = useState(false);

            const handleChangeKeyword = (keyword: string) => {
                setResultCount(0);
                setKeyword(keyword);
            };

            const dispatch = useDispatch();
            const notify = useNotify();

            const handlePickImage = (imageId: string) => {
                setIsDownloadingImage(true);
                dataProvider(
                    'UPLOAD',
                    'getty',
                    { id: imageId },
                    {
                        onSuccess: {
                            notification: {
                                body: 'resources.getty.notification.image_uploaded',
                                info: 'info'
                            }
                        }
                    }
                )
                    .then(response => {
                        setIsDownloadingImage(false);
                        if (galleryId) {
                            dataProvider('ADD_ASSETS', 'gallery', {
                                galleryId: galleryId,
                                assetIds: [response.data.id]
                            })
                                .then(response => {
                                    notify('resources.gallery.notification.assets_added');
                                    handleOpen(false);
                                    window.location.reload();
                                })
                                .catch(error => {
                                    notify(error.message, 'warning');
                                });
                        } else if (videoId) {
                            dataProvider('UPDATE_THUMBNAIL', 'video', { id: videoId, assetID: response.data.id })
                                .then(response => {
                                    notify('resources.gallery.notification.assets_added');
                                    handleOpen(false);
                                    window.location.reload();
                                })
                                .catch(error => {
                                    notify(error.message, 'warning');
                                });
                        } else {
                            dispatch(push(`/asset/${response.data.id}`));
                            handleOpen(false);
                        }
                    })
                    .catch(error => {
                        setIsDownloadingImage(false);
                        notify('resources.asset.notification.image_uploaded_failed_already_exists', 'warning');
                    });
            };

            return (
                <>
                    <Grid container spacing={1} style={{ padding: 24 }}>
                        <Grid item style={{ textAlign: 'center' }} xs>
                            <Input
                                autoFocus={true}
                                id="adornment-password"
                                type="text"
                                value={keyword}
                                onChange={e => handleChangeKeyword(e.target.value)}
                                placeholder=""
                                endAdornment={
                                    <InputAdornment position="end">
                                        <ClearIcon
                                            onClick={() => {
                                                handleChangeKeyword('');
                                            }}
                                            aria-label="Getty Search"
                                        >
                                            {' '}
                                        </ClearIcon>
                                    </InputAdornment>
                                }
                            />
              &nbsp;{' '}
                            {resultCount > 0 &&
                translate('mam.found', { count: resultCount.toLocaleString() })}
                        </Grid>
                    </Grid>
                    <GettyImagesList
                        keyword={keyword}
                        handlePickImage={handlePickImage}
                        handleResultCount={setResultCount}
                    />
                    {isDownloadingImage && <LinearProgress color="secondary" />}
                </>
            );
        }
    )
);

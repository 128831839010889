/**
 * make list of asset transformations from primitives and vice versa
 * used for sport1tv
 */

export const ConvertAssetTransformationsToPrimitives = (model) => {
    if (Array.isArray(model.assetTransformations)) {
        model.assetTransformations.forEach((a) => {
            model['assetTransformations_' + a.platform + '_' + a.type] = a.transformation_id;
        });
        delete model.assetTransformations;
    }
};

export const ConvertPrimitivesToAssetTransformations = (model, modelType: 'video' | 'playlist') => {
    Object.entries(model).forEach(([key, value]) => {
        if (key.startsWith('assetTransformations_') && value) {
            const keys = key.split('_');
            const platform = keys[1];
            const type = keys[2];
            model.assetTransformations = model.assetTransformations || [];

            const assetTransformation = {
                platform,
                type,
                transformation_id: value
            };

            if (modelType === 'video') {
                assetTransformation['video_id'] = model.id;
            } else if (modelType === 'playlist') {
                assetTransformation['playlist_id'] = model.id;
            }

            model.assetTransformations.push(assetTransformation);
            model[key] = undefined;
        }
    });
};

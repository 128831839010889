import React, {useEffect, useState} from 'react';
import { EditButton, translate } from 'react-admin';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import {
    Image,
    Movie, PictureAsPdf,
    PlaylistAdd,
    PlaylistAddCheck,
    Restore
} from '@material-ui/icons';
import { DOWNLOAD_URL, ROLES } from '../../utils/config';
import Chip from '@material-ui/core/Chip';
import {Button, CardActionArea, Drawer, Grid, Typography} from '@material-ui/core';
import { withDataProvider } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import {Transformation} from '../transformations/types/Transformation';
import AssetTransformation from '../transformations/AssetTransformation';
import SinglePagePDFViewer from '../pdf/SinglePagePDFViewer';

const cardStyle = {
    width: '100%'
};

const cardMediaStyle = {
    paddingTop: '100%'
};

const styles = theme => {
    return {
        title: {
            minHeight: '3em'
        },
        subheader: {
            minHeight: '3em',
            maxHeight: '3em',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
        },
        transformation: {
            paddingRight: theme.spacing(2),
            paddingLeft: theme.spacing(2)
        }
    };
};

export const AssetsField = translate(
    withDataProvider(
        withStyles(styles)(
            (props: {
        dataProvider;
        classes;
        selectedIds;
        onSelect;
        record;
        hasEdit;
        basePath;
        translate;
        handleAddTransformationToEventstream;
        permissions;
      }) => {
                const [drawerOpen, setDrawerOpen] = useState(false);
                const initialTransformations: Transformation[] = [];
                const [transformations, setTransformations] = useState(initialTransformations);
                const { dataProvider, classes } = props;
                const isPdf: boolean = props.record.type === 'application/pdf';

                useEffect(() => {
                    if (!isPdf) {
                        dataProvider('GET_TRANSFORMATION_LIST', 'asset', {id: props.record.id})
                            .then((response) => setTransformations(response.data))
                            .catch(error => console.error(error));
                    }
                }, [dataProvider, isPdf, props.record.id]);

                const addToSelectedHint = selected => {
                    return selected
                        ? props.translate('resources.gallery.action.remove_from_gallery')
                        : props.translate('resources.gallery.action.add_to_gallery');
                };

                const handleDeselect = id => {
                    let index = props.selectedIds.indexOf(id);
                    const cloned = [...props.selectedIds];
                    cloned.splice(index, 1);
                    props.onSelect(cloned);
                };

                const mediaIcon = type => {
                    if (type !== undefined) {
                        if (type.startsWith('image/')) {
                            return <Image />;
                        } else if (type === 'application/pdf') {
                            return <PictureAsPdf />;
                        } else {
                            return <Movie />;
                        }
                    }
                    return null;
                };

                const handleRestore = id => {
                    dataProvider(
                        'RESTORE_ASSET',
                        'asset',
                        { id: id },
                        {
                            onSuccess: {
                                notification: {
                                    body: 'resources.asset.notification.asset_restored'
                                },
                                refresh: true
                            }
                        }
                    )
                        .then(response => {})
                        .catch(error => console.error(error));
                };

                return (
                    <Card style={cardStyle}>
                        <CardHeader
                            avatar={
                                <Avatar aria-label="Recipe">
                                    {mediaIcon(props.record.type)}
                                </Avatar>
                            }
                            title={
                                props.record.title
                                    ? props.record.title.length > 12
                                        ? `${props.record.title.substr(0, 12)}…`
                                        : props.record.title
                                    : ''
                            }
                            subheader={new Date(props.record.createdAt).toLocaleString()}
                            classes={classes}
                        />
                        {!props.handleAddTransformationToEventstream ?
                            <Link to={`${props.basePath}/${props.record.id}`} tabIndex="0">
                                {isPdf ? (
                                    <CardMedia>
                                        <SinglePagePDFViewer pdf={`${DOWNLOAD_URL()}/c/o/${props.record.id}.pdf`} />
                                    </CardMedia>
                                ) : (
                                    <CardMedia style={cardMediaStyle} image={DOWNLOAD_URL() + '/c/t/' + props.record.id + '/620x465'} />
                                )}
                            </Link>
                            :
                            <React.Fragment>
                                <CardActionArea onClick={() => setDrawerOpen(true)}>
                                    <CardMedia
                                        style={cardMediaStyle}
                                        image={DOWNLOAD_URL() + '/api/v1/asset/' + props.record.id + '/preview'}
                                    />
                                </CardActionArea>
                                <Drawer open={drawerOpen} onClose={() => setDrawerOpen(false)}>
                                    <Typography variant="h6" color="inherit" align="center">
                                        {props.translate('mam.dashboard.transformations')}
                                    </Typography>
                                    <Grid container direction="column" spacing={2}>
                                        {transformations.map((transformation, index) =>
                                            <Grid item key={index} className={classes.transformation}>
                                                <div className={classes.transformation}>
                                                    <AssetTransformation
                                                        transformation={transformation}
                                                        handleAddTransformationToEventstream={props.handleAddTransformationToEventstream}
                                                    />
                                                </div>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Drawer>
                            </React.Fragment>
                        }

                        <CardActions disableSpacing>
                            <Chip
                                label={props.record.type
                                    .replace('image/', '')
                                    .replace('video/', '')
                                    .replace('application/', '')}
                            />
                            {!props.hasEdit && (
                                props.selectedIds.indexOf(props.record.id) !== -1 ? (
                                    <IconButton
                                        tabIndex={-1}
                                        aria-label={addToSelectedHint(
                                            props.selectedIds.indexOf(props.record.id) !== -1
                                        )}
                                        title={addToSelectedHint(
                                            props.selectedIds.indexOf(props.record.id) !== -1
                                        )}
                                        onClick={() => {
                                            handleDeselect(props.record.id);
                                        }}>
                                        <PlaylistAddCheck
                                            style={{ color: 'green' }}
                                        />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        tabIndex={-1}
                                        aria-label={addToSelectedHint(
                                            props.selectedIds.indexOf(props.record.id) !== -1
                                        )}
                                        title={addToSelectedHint(
                                            props.selectedIds.indexOf(props.record.id) !== -1
                                        )}
                                        onClick={() => {
                                            props.onSelect(
                                                props.selectedIds.concat([props.record.id])
                                            );
                                        }}>
                                        <PlaylistAdd />
                                    </IconButton>
                                )
                            )}
                            {[ROLES.admin.id.toString(), ROLES.superEditor.id.toString()].includes(props.permissions) ?
                                props.hasEdit && props.record.deletedAt !== undefined && (
                                    <Button
                                        onClick={() => {
                                            handleRestore(props.record.id);
                                        }}
                                        color="default"
                                    >
                                        <Restore />
                    Restore
                                    </Button>
                                ) : null
                            }
                            {props.hasEdit && props.record.deletedAt === undefined && (
                                <EditButton basePath={props.basePath} record={props.record} tabIndex="-1" />
                            )}
                        </CardActions>
                    </Card>
                );
            }
        )
    )
);

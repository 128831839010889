import { httpRequestObject, OptionsInterface } from './httpRequestObjects';

export const convertDataToStatisticsRequest = (
    type,
    params,
    apiUrl,
    options: OptionsInterface
): httpRequestObject => {
    let url;
    const resource = 'statistics';
  
    options.method = 'GET';
    switch (type) {
    case 'IMAGES': {
        url = encodeURI(`${apiUrl}/${resource}/images`);
        break;
    }
    case 'GALLERIES': {
        url = encodeURI(`${apiUrl}/${resource}/galleries`);
        break;
    }
    case 'VIDEOS': {
        url = encodeURI(`${apiUrl}/${resource}/video`);
        break;
    }
    case 'ASSET_TAG': {
        url = encodeURI(`${apiUrl}/${resource}/assetTags`);
        break;
    }
    case 'GALLERY_TAG': {
        url = encodeURI(`${apiUrl}/${resource}/galleryTags`);
        break;
    }
    default:
        url = encodeURI(`${apiUrl}/${resource}/global`);
    }
  
    return { url, options };
};

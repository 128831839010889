import React from 'react';
import {
    BooleanField,
    Datagrid,
    EditButton,
    List,
    ListController,
    TextField,
    translate,
} from 'react-admin';
import {
    Input,
} from '@material-ui/core';
import { Paper } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import ToEventStream from './ToEventStream';

const SearchPanel = translate(({
    translate,
    setFilters,
    filterValues,
}) => {
    return <Paper>
        <Input
            fullWidth
            onChange={(event) => setFilters({
                endTime: filterValues.endTime,
                episodeID: filterValues.episodeID,
                id: event.target.value,
                startTime: filterValues.startTime,
            })}
            id="id"
            value={filterValues.id}
            placeholder={translate('Broadcast-ID')}
        />

        <Input
            fullWidth
            onChange={(event) => setFilters({
                endTime: filterValues.endTime,
                episodeID: event.target.value,
                id: filterValues.id,
                startTime: filterValues.startTime,
            })}
            id="episodeID"
            placeholder={translate('Episode-ID')}
            value={filterValues.episodeID}
        />

        <Input
            fullWidth
            onChange={event => setFilters({
                startTime: filterValues.startTime,
                endTime: filterValues.endTime,
                id: filterValues.id,
                episodeID: filterValues.episodeID,
                episodeName: event.target.value,
            })}
            id="episodeName"
            placeholder={'Episode name'}
            value = {filterValues.episodeName}
        />

        <Input
            fullWidth
            onChange={(event) => setFilters({
                endTime: filterValues.endTime,
                episodeID: filterValues.episodeID,
                id: filterValues.id,
                startTime: event.target.value,
            })}
            id="startTime"
            placeholder={translate('Start-Time')}
            type="datetime-local"
        />

        <Input
            fullWidth
            onChange={(event) => setFilters({
                endTime: event.target.value,
                episodeID: filterValues.episodeID,
                id: filterValues.id,
                startTime: filterValues.startTime,
            })}
            id="endTime"
            placeholder={translate('End-Time')}
            type="datetime-local"
        />
    </Paper>;
});

export const EpgBroadcastList = (props) => {
    const dispatch = useDispatch();
    return <ListController {...props} resource={'epg-broadcast'}>
        {controllerProps => <>
            <SearchPanel
                setFilters={controllerProps.setFilters}
                filterValues={controllerProps.filterValues}
            />
            <List bulkActionButtons={false} pagination={false} {...props}>
                <Datagrid {...controllerProps}>
                    <TextField sortable={false} source="id" />
                    <TextField sortable={false} source="startTime" />
                    <TextField sortable={false} source="channelId" />
                    <TextField sortable={false} source="episodeId" />
                    <TextField sortable={false} source="source" />
                    <BooleanField sortable={false} source="isHighlight" />
                    <EditButton />
                    <ToEventStream dispatch={dispatch} {...props} />
                </Datagrid>
            </List>
        </>}
    </ListController>;
};

export default EpgBroadcastList;

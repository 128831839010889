import {
    FormControl,
    Grid, InputLabel, MenuItem,
    Paper, Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@material-ui/core';
import { withDataProvider, useLocale } from 'react-admin';
import React, { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { sports } from './selectSportsData';
import { Competition, Match, Season } from '../../types';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            marginTop: 15,
            marginBottom: 8,
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 8,
            paddingRight: 8
        },
        tableContainer: {
            marginTop: 10
        },
        tableRowHead: {
            fontWeight: 'bold'
        },
        tableRow: {
            '&:hover': {
                cursor: 'pointer'
            },
        },
    }),
);

interface Props {
  dataProvider
  translate
  onChange: CallableFunction
  setOpenDialog: CallableFunction
}

const MatchIdList = withDataProvider((props: Props) => {
    const emptyCompetition: Competition = {
        id: '',
        name: '',
        currentSeasonId: '',
        isActive: undefined,
        sportId: '',
        nameDe: ''
    };
    const locale = useLocale();
    const [selectedSport, setSelectedSport] = useState<string>('');
    const [competitionData, setCompetitionData] = useState<{ competitions: Competition[], selectedCompetition: Competition }>({
        competitions: [],
        selectedCompetition: emptyCompetition
    });
    const [seasonData, setSeasonData] = useState<{ seasons: Season[], selectedSeason: Season }>({
        seasons: [],
        selectedSeason: { id: '', name: '' }
    });
    const [matchdayData, setMatchdayData] = useState<{ matchdays: number[], selectedMatchday: number | '' }>({
        matchdays: [],
        selectedMatchday: ''
    });
    const [matches, setMatches] = useState<Match[]>([]);
    const [allMatches, setAllMatches] = useState<Match[]>([]);
    const classes = useStyles();

    const setMatchData = (seasonId: string, competitionId: string) => {
        props.dataProvider('GET_MATCHES', 'video',
            {
                match: `${sports.find(sport => sport.id === selectedSport)!.name}_match`,
                season: seasonId,
                competition: competitionId
            })
            .then((response: { data: Match[], total: number }) => {
                setAllMatches(response.data);
                let tmpMatchdays: number[] = [];
                response.data.forEach(match => {
                    if (!tmpMatchdays.includes(match.matchDay)) {
                        tmpMatchdays = [...tmpMatchdays, match.matchDay];
                    }
                });
                const highestMatchday = Math.max(...tmpMatchdays);
                setMatchdayData({ matchdays: tmpMatchdays, selectedMatchday: highestMatchday });
                setMatches(response.data.filter(match => match.matchDay === highestMatchday));
            });
    };

    const handleSportChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        const newSport = e.target.value as string;
        setSelectedSport(newSport);
        setSeasonData({ seasons: [], selectedSeason: { id: '', name: '' } });
        setMatchdayData({ matchdays: [], selectedMatchday: '' });
        setMatches([]);
        setAllMatches([]);
        props.dataProvider('GET_COMPETITIONS', 'video', { sport: newSport })
            .then((response: { data: Competition[], total: number }) => {
                setCompetitionData({ competitions: response.data, selectedCompetition: emptyCompetition });
            })
            .catch(error => console.log(error));
    };

    const handleCompetitionChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        const newCompetition: string = e.target.value as string;
        const competition = competitionData.competitions.find(comp => comp.id === newCompetition);
        setCompetitionData({
            competitions: competitionData.competitions,
            selectedCompetition: competition ? competition : emptyCompetition
        });
        const currentSeasonId: string = competition!.currentSeasonId!;
        props.dataProvider('GET_SEASONS_BY_COMPETITION', 'video', { competition: newCompetition.replace(/:/gi, '_') })
            .then((response: { data: Season[], total: number }) => {
                const currentSeason: Season | undefined = response.data.find(s => s.id === currentSeasonId);
                setSeasonData({ seasons: response.data, selectedSeason: currentSeason ? currentSeason : { id: '', name: '' } });
            })
            .then(() => {
                setMatchData(currentSeasonId, newCompetition);
            })
            .catch(error => console.log(error));
    };

    const handleSeasonChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        const newSeason = e.target.value as string;
        const season = seasonData.seasons.find(season => season.id === newSeason);
        setSeasonData({ seasons: seasonData.seasons, selectedSeason: season ? season : { id: '', name: '' } });
        setMatchData(newSeason, competitionData.selectedCompetition.id);
    };

    const handleMatchdayChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        const newMatchday = e.target.value as number;
        setMatchdayData({ selectedMatchday: newMatchday, matchdays: matchdayData.matchdays });
        setMatches(allMatches.filter(match => match.matchDay === newMatchday));
    };

    const handleRowClick = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        props.onChange(e.currentTarget.id);
        props.setOpenDialog(false);
    };

    return (
        <div className={classes.root} >
            <Paper >
                <Grid container spacing={1} >
                    <Grid item xs={6} >
                        <FormControl variant={'filled'} fullWidth margin={'dense'} >
                            <InputLabel >Sport</InputLabel >
                            <Select onChange={handleSportChange} value={selectedSport} >
                                {sports.map(({ id, displayName, displayNameDe }) => (
                                    <MenuItem value={id} >{locale === 'de' && displayNameDe ? displayNameDe : displayName}</MenuItem >
                                ))}
                            </Select >
                        </FormControl >
                    </Grid >
                    <Grid item xs={6} >
                        <FormControl variant={'filled'} fullWidth margin={'dense'}
                            disabled={competitionData.competitions.length === 0} >
                            <InputLabel >Competition</InputLabel >
                            <Select onChange={handleCompetitionChange} value={competitionData.selectedCompetition.id} >
                                {competitionData.competitions.map(({ id, name, nameDe }) => (
                                    <MenuItem value={id} >{locale === 'de' && nameDe ? nameDe : name}</MenuItem >
                                ))}
                            </Select >
                        </FormControl >
                    </Grid >
                    <Grid item xs={6} >
                        <FormControl variant={'filled'} fullWidth margin={'dense'} disabled={seasonData.seasons.length === 0} >
                            <InputLabel >{props.translate('resources.video.label.season')}</InputLabel >
                            <Select onChange={handleSeasonChange} value={seasonData.selectedSeason.id} >
                                {seasonData.seasons.map(({ id, name }) => (
                                    <MenuItem value={id} >{name}</MenuItem >
                                ))}
                            </Select >
                        </FormControl >
                    </Grid >
                    <Grid item xs={6} >
                        <FormControl variant={'filled'} fullWidth margin={'dense'} disabled={matchdayData.matchdays.length === 0} >
                            <InputLabel >{props.translate('resources.video.label.matchday')}</InputLabel >
                            <Select onChange={handleMatchdayChange}
                                value={matchdayData.selectedMatchday} >
                                {matchdayData.matchdays.map(matchday => (
                                    <MenuItem
                                        value={matchday} >{matchday === -1 ? props.translate('resources.video.label.without_matchday') : matchday}</MenuItem >
                                ))}
                            </Select >
                        </FormControl >
                    </Grid >
                </Grid >
            </Paper >
            <TableContainer className={classes.tableContainer} component={Paper} >
                <Table size={'small'} >
                    <TableHead >
                        <TableRow >
                            <TableCell
                                className={classes.tableRowHead} >{props.translate('resources.video.label.hometeam')}</TableCell >
                            <TableCell
                                className={classes.tableRowHead} >{props.translate('resources.video.label.awayteam')}</TableCell >
                            <TableCell className={classes.tableRowHead} >MatchId</TableCell >
                            <TableCell className={classes.tableRowHead} >{props.translate('Date')}</TableCell >
                        </TableRow >
                    </TableHead >
                    <TableBody >
                        {matches.map((match) => (
                            <TableRow key={match.id} id={match.id} hover onClick={handleRowClick} className={classes.tableRow} >
                                <TableCell >{locale === 'de' && match.homeTeam[0].nameDe ? match.homeTeam[0].nameDe : match.homeTeam[0].name}</TableCell >
                                <TableCell >{locale === 'de' && match.awayTeam[0].nameDe ? match.awayTeam[0].nameDe : match.awayTeam[0].name}</TableCell >
                                <TableCell >{match.id}</TableCell >
                                <TableCell >{new Date(match.scheduledStartTime).toLocaleString()}</TableCell >
                            </TableRow >
                        ))}
                    </TableBody >
                </Table >
            </TableContainer >
        </div >
    );
});

export default MatchIdList;

import React, { useState } from 'react';
import {
    Confirm,
    setListSelectedIds,
    translate,
} from 'react-admin';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import VideoAddThumbnailDialog from './VideoAddThumbnailDialog';
import { connect, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import VideoThumbnail from './VideoThumbnail';

interface Props {
  onChange;
  record;
  setSelectedIds;
  transformationID;
  translate;
  allowTransformationPicking;
}

const DeleteThumbnail = ({
    onChange,
    translate,
}) => {
    const [open, setOpen] = useState(false);

    return <>
        <DeleteForeverIcon onClick={() => setOpen(true)} />
        <Confirm
            content={translate('mam.delete_thumbnail')}
            isOpen={open}
            onClose={() => setOpen(false)}
            onConfirm={() => onChange(null)}
            title={translate('Delete?')}
        />
    </>;
};

const mapStateToProps = (state) => ({
    selectedIds: state.admin.resources.asset.list.selectedIds,
    isLoading: state.admin.loading > 0,
});

export default translate(connect(mapStateToProps, {
    setSelectedIds: setListSelectedIds,
})((props: Props) => {
    const {
        allowTransformationPicking = true,
        onChange,
        record,
        transformationID,
        translate,
    } = props;

    const [openDialog, setOpenDialog] = useState(false);
    const dispatch = useDispatch();

    const thumb = transformationID && (
        <div>
            <span
                onClick={allowTransformationPicking ? () => dispatch(push(`/transformation-video/${transformationID}/${record.id}`)) : undefined}
                style={allowTransformationPicking ? {cursor: 'pointer'} : undefined}
            >
                <VideoThumbnail
                    height={100}
                    transformationID={transformationID}
                />
            </span>
            <DeleteThumbnail
                onChange={onChange}
                translate={translate}
            />
        </div>
    );

    return <>
        <VideoAddThumbnailDialog
            addThumbnailsToVideo={onChange}
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            {...props}
        />
        {thumb}
    </>;
}));

import React from 'react';
import {
    AppBar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Toolbar,
    Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import AssetsList from '../assets/AssetsList';
import { translate, CREATE, useNotify } from 'react-admin';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

const styles = theme => ({
    dialog: {
        overflow: 'visible'
    },
    appBar: {
        backgroundColor: theme.palette.secondary.main
    }
});

const GalleryAddAssetDialog = (props: any) => {
    const {
        openDialog,
        setOpenDialog,
        classes,
        translate,
    } = props;

    const handleClose = () => {
        setOpenDialog(false);
    };

    const dispatch = useDispatch();
    const notify = useNotify();

    const addAssetsToGallery = (galleryId: string | undefined, selectedIds) => {
        const { dataProvider, setSelectedIds, reload, setReload } = props;

        if (!galleryId) {
            dataProvider(CREATE, 'gallery', { assetIds: selectedIds })
                .then(response => {
                    setSelectedIds('asset', []);
                    notify('resources.gallery.notification.gallery_created');
                    dispatch(push(`/gallery/${response.data.id}`));
                });
        } else {
            dataProvider('ADD_ASSETS', 'gallery', {
                galleryId: galleryId,
                assetIds: selectedIds
            })
                .then(() => {
                    setReload(!reload);
                    setSelectedIds('asset', []);
                    setOpenDialog(false);
                    notify('resources.gallery.notification.assets_added');
                })
                .catch(error => console.error(error));
        }
    };

    return (
        <Dialog className={classes.dialog} fullScreen={true} open={openDialog}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton color="inherit" onClick={handleClose} aria-label="Close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" color="inherit">
                        {translate('resources.gallery.label.add_assets_to_gallery')}
                    </Typography>
                </Toolbar>
            </AppBar>
            <DialogTitle id="simple-dialog-title">&nbsp;</DialogTitle>
            <DialogContent>
                <AssetsList
                    {...props}
                    addAssetsToGallery={addAssetsToGallery}
                    hasEdit={false}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => setOpenDialog(false)}
                    color="primary"
                >
                    {translate('mam.close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default translate(withStyles(styles)(GalleryAddAssetDialog));

import React from 'react';
import {
    DeleteButton,
    Edit,
    FormDataConsumer,
    NumberInput,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
} from 'react-admin';
import { Field } from 'react-final-form';
import TextInputThumbnail from '../TextInputThumbnail';

const CustomToolbar = props =>
    <Toolbar {...props}>
        <SaveButton />
        {props.record.source === 'oz' ? <DeleteButton undoable={false} /> : null}
    </Toolbar>;

export const EpgCategoryEdit = (props) =>
    <Edit {...props} >
        <SimpleForm redirect="edit" toolbar={<CustomToolbar />}>
            <NumberInput source="id" disabled fullWidth />
            <TextInput source="name" disabled fullWidth />
            <TextInput source="source" disabled fullWidth />
            <FormDataConsumer>
                {formDataProps => <>
                    <Field name="transformationId">
                        {props => (
                            <TextInputThumbnail
                                fullWidth
                                onChange={props.input.onChange}
                                source="transformationId"
                                {...formDataProps}
                            />
                        )}
                    </Field>
                </>}
            </FormDataConsumer>
            <NumberInput source="tagId" fullWidth />
        </SimpleForm>
    </Edit>;

export default EpgCategoryEdit;

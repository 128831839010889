import {API_URL} from './utils/config';

export default async function getUser() {
    if (!localStorage.getItem('token')) {
        throw new Error('No token found')
    }

    const response = await fetch(API_URL() + '/users/me', {
        headers: {authorization: 'Bearer ' + localStorage.getItem('token')}
    })

    if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
    }

    return await response.json();
}

import React from 'react';
import {
    translate,
} from 'react-admin';
import { useDispatch } from 'react-redux';
import {
    Fab,
    Tooltip,
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Add';
import { push } from 'connected-react-router';

export default translate(({
    resource,
    translate,
}) => {
    const dispatch = useDispatch();

    return <Tooltip title={translate(`resources.${resource}.action.create`)}>
        <Fab
            color="primary"
            onClick={() => {
                dispatch(push(`/${resource}/create`));
            }}
        >
            <CreateIcon />
        </Fab>
    </Tooltip>;
});

import React from 'react';
import {
    BooleanInput,
    Create,
    DateTimeInput,
    FormDataConsumer,
    NumberInput,
    SimpleForm,
    TextInput,
    translate,
    withDataProvider,
} from 'react-admin';
import RouteLeavingGuard from '../../RouteLeavingGuard';
import SelectChannel from '../SelectChannel';
import SelectProgram from '../SelectProgram';
import TagWrapper from '../../TagWrapper';
import TextInputThumbnail from '../TextInputThumbnail';
import isChannelAllowedAsEventstreamSource from './isChannelAllowedAsEventstreamSource';
import { Field } from 'react-final-form';

/**
 * Some Fields are hidden, but nonetheless relevant.
 * This is due to the Syntactic Sugar Nature of this Form.
 */
export const EpgEventStreamCreate = withDataProvider(translate((props) => {
    const {dataProvider, permissions, translate} = props;
    return <Create {...props} >
        <SimpleForm>
            <RouteLeavingGuard navigate={props.history.push} shouldBlockNavigation={(location) => !location.pathname.match(/\/eventstream\/\d+/) && props.history.location.pathname !== location.pathname} />

            <FormDataConsumer>
                {formDataProps => <>
                    <Field name="channel">
                        {props => <SelectChannel
                            {...formDataProps}
                            onChange={props.input.onChange}
                            dataProvider={dataProvider}
                            defaultValue={formDataProps.record.channel ? {label: formDataProps.record.channel.name, value: formDataProps.record.channel.id} : null}
                            filter={isChannelAllowedAsEventstreamSource}
                        /> }
                    </Field>
                </>}
            </FormDataConsumer>

            <FormDataConsumer>
                {formDataProps => <>
                    <Field name="program">
                        {props => <SelectProgram
                            {...formDataProps}
                            onChange={props.input.onChange}
                            dataProvider={dataProvider}
                            defaultValue={formDataProps.record.program ? {label: formDataProps.record.program.title, value: formDataProps.record.program.id} : null}
                        /> }
                    </Field>
                </>}
            </FormDataConsumer>

            <NumberInput
                defaultValue={0}
                disabled
                fullWidth
                label={translate('resources.epg.episode.season')}
                required
                source="episode.season"
                style={{display: 'none'}}
            />

            <NumberInput
                defaultValue={0}
                disabled
                fullWidth
                label={translate('resources.epg.episode.number')}
                required
                source="episode.number"
                style={{display: 'none'}}
            />

            <TextInput
                fullWidth
                label={translate('resources.epg.episode.subtitle')}
                source="episode.subtitle"
            />

            <TextInput
                fullWidth
                label={translate('resources.epg.episode.subcategory')}
                source="episode.subcategory"
                style={{display: 'none'}}
            />

            <TextInput
                disabled
                fullWidth
                label={translate('resources.epg.episode.serienId')}
                source="episode.serienId"
                style={{display: 'none'}}
            />

            <TextInput
                disabled
                fullWidth
                label={translate('resources.epg.episode.materialId')}
                source="episode.materialId"
                style={{display: 'none'}}
            />

            <TextInput
                fullWidth
                label={translate('resources.epg.episode.description')}
                source="episode.description"
            />

            <TextInput
                fullWidth
                label={translate('resources.epg.episode.longDescription')}
                source="episode.longDescription"
            />

            <NumberInput
                disabled
                fullWidth
                label={translate('resources.epg.episode.duration')}
                source="episode.duration"
                style={{display: 'none'}}
            />

            <TextInput
                disabled
                fullWidth
                label={translate('resources.epg.episode.fsk')}
                source="episode.fsk"
                style={{display: 'none'}}
            />

            <FormDataConsumer>
                {formDataProps => <>
                    <Field name="episode.transformationId">
                        {props => (
                            <TextInputThumbnail
                                fullWidth
                                onChange={props.input.onChange}
                                source="episode.transformationId"
                                {...formDataProps}
                            />
                        )}
                    </Field>
                </>}
            </FormDataConsumer>

            <FormDataConsumer>
                {formDataProps => <>
                    <Field name="tags">
                        {props => (
                            <TagWrapper
                                maxTags={1}
                                mayCreateNewTags={false}
                                onChange={props.input.onChange}
                                permissions={permissions}
                                recordWins={true}
                                {...formDataProps}
                            />
                        )}
                    </Field>
                </>}
            </FormDataConsumer>

            <TextInput
                fullWidth
                label={translate('resources.epg.episode.vodVideoId')}
                source="episode.vodVideoId"
            />

            <BooleanInput
                defaultValue={true}
                label={translate('resources.epg.broadcast.isActive')}
                required
                source="broadcast.isActive"
            />

            <BooleanInput
                defaultValue={true}
                disabled
                label={translate('resources.epg.broadcast.isEventStream')}
                required
                source="broadcast.isEventStream"
                style={{display: 'none'}}
            />

            <BooleanInput
                defaultValue={false}
                label={translate('resources.epg.episode.isHighlight')}
                required
                source="episode.isHighlight"
            />

            <BooleanInput
                defaultValue={true}
                label={translate('resources.epg.broadcast.isHighlight')}
                required
                source="broadcast.isHighlight"
            />

            <DateTimeInput
                defaultValue={new Date(new Date().toISOString().split('T').shift() + 'T00:00:00.000+01:00')}
                fullWidth
                label={translate('resources.epg.broadcast.startTime')}
                parse={(d) => new Date(d)}
                required
                resettable
                source="broadcast.startTime"
            />

            <DateTimeInput
                defaultValue={new Date(new Date().toISOString().split('T').shift() + 'T23:59:59.000+01:00')}
                fullWidth
                label={translate('resources.epg.broadcast.endTime')}
                parse={(d) => new Date(d)}
                required
                resettable
                source="broadcast.endTime"
            />
        </SimpleForm>
    </Create>;
}));

export default EpgEventStreamCreate;

import React, {useEffect, useState} from 'react';
import {withDataProvider, Confirm} from 'react-admin';

import {
    AppBar,
    DialogContent,
    DialogTitle,
    Fab,
    Toolbar,
    Tooltip,
    Typography
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import AssetsList from '../assets/AssetsList';
import {connect} from 'react-redux';
import {setListSelectedIds} from 'ra-core';
import VideoThumbnail from '../videos/VideoThumbnail';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {AssetTransformation} from '../assetTransformation/assetTransformation';

interface Props {
  onChange;
  record;
  setSelectedIds;
  transformationID;
  translate;
  dataProvider;
  basePath;
  id;
  platform;
  type;
  addThumbnailToPlaylist;
  transformations;
  assetTransformations;
  thumbnailTitle;
  targetComponentName;
}

const mapStateToProps = (state) => ({
    selectedIds: state.admin.resources.asset.list.selectedIds,
    isLoading: state.admin.loading > 0,
});

export default withDataProvider(connect(mapStateToProps, {
    setSelectedIds: setListSelectedIds,
})((props: Props) => {
    const {
        onChange,
        setSelectedIds,
        dataProvider,
        platform,
        type,
        thumbnailTitle,
        record,
        targetComponentName,
    } = props;

    const getTransformationFromRecord = (record, type, platform) => {
        if (props.transformationID) {
            return props.transformationID;
        }

        const assetTransformations = targetComponentName === 'Eventstreams' ? props.assetTransformations : record.assetTransformations;

        if (assetTransformations && assetTransformations.length) {
            const asset = assetTransformations.find(at => at.type === type && at.platform === platform);
            if (asset !== undefined) {
                const transformation = (targetComponentName === 'Video' || targetComponentName === 'Playlist') ? asset.transformation_id : asset.transformationId;
                return transformation;
            }
        }
    };

    const [openDialog, setOpenDialog] = useState(false);
    const transformationIDFromRecord = getTransformationFromRecord(props.record, props.type, props.platform);
    const [transformationID, setTransformationID] = useState(transformationIDFromRecord);

    useEffect(() => {
        setTransformationID(transformationIDFromRecord);
    }, [transformationIDFromRecord]);

    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const DeleteThumbnail = () => {
        const [open, setOpen] = useState(false);

        return <>
            <DeleteForeverIcon onClick={() => setOpen(true)}/>
            <Confirm
                content={`do you want to detach this asset from ${targetComponentName}?`}
                title="Delete?"
                isOpen={open}
                onClose={() => setOpen(false)}
                onConfirm={() => onChange(removeAssetFromComponent(transformationID))}
            />
        </>;
    };

    const thumb = transformationID && (
        <div>
            <VideoThumbnail
                height={100}
                transformationID={transformationID}/>
            <DeleteThumbnail/>
        </div>
    );

    const removeAssetFromComponent = (transformationId) => {
        let assetTransformationsList: AssetTransformation[] = targetComponentName === 'Eventstreams' ? [...props.assetTransformations] : [...record.assetTransformations];

        const assetTransformations = targetComponentName === 'Eventstreams' ? props.assetTransformations : record.assetTransformations;

        if (assetTransformations && assetTransformations.length) {
            assetTransformationsList = assetTransformations.filter(function (v) {
                if (targetComponentName === 'Video' || targetComponentName === 'Playlist') {
                    return v.transformation_id !== transformationId && v.type !== type;
                }
                return v.transformationId !== transformationId && v.type !== type;
            });
        }

        const formatted = assetTransformationsList.map(pt => reformatAssetTransformationsRecord(pt));
        setTransformationID('');
        return formatted;
    };

    const addAssetToComponent = (componentId, selectedIds) => {
        dataProvider('GET_TRANSFORMATION_LIST', 'asset', {id: selectedIds[0]})
            .then(transformation => {
                onChange((transformation.data[0] && transformation.data[0].id && createAssetTransformation(transformation.data[0].id)) || null);
                setSelectedIds('asset', []);
                setTransformationID(selectedIds[0]);
                setOpenDialog(false);
            });
    };

    const reformatAssetTransformationsRecord = (assetTransformationsRecord) => {
        let transformation: AssetTransformation = {
            platform: assetTransformationsRecord.platform,
            type: assetTransformationsRecord.type
        };

        if (targetComponentName === 'Video' || targetComponentName === 'Playlist') {
            transformation = {transformation_id: assetTransformationsRecord.transformation_id, ...transformation};
        } else {
            transformation = {transformationId: assetTransformationsRecord.transformationId, ...transformation};
        }

        return transformation;
    };

    const createAssetTransformation = (transformationId) => {
        let transformationsList: AssetTransformation[] = [];

        //record is used to get the value stored in the DB, this is reloaded after auto-save. Props for assetTransformations are wrong after autosave
        const assetTransformations = targetComponentName === 'Eventstreams' ? props.assetTransformations : record.assetTransformations;

        if (assetTransformations && assetTransformations.length) {
            let assetTransformationsRecords = assetTransformations;

            for (let i = 0; i < assetTransformationsRecords.length; i++) {
                if (assetTransformationsRecords[i].type !== type) {
                    transformationsList.push(reformatAssetTransformationsRecord(assetTransformationsRecords[i]));
                }
            }
        }

        let transformation: AssetTransformation = {
            platform: platform,
            type: type
        };

        if (targetComponentName !== 'Video' && targetComponentName !== 'Playlist') {
            transformation = {transformationId: transformationId, ...transformation};
        } else {
            transformation = {transformation_id: transformationId, ...transformation};
        }
        transformationsList.push(transformation);
        return transformationsList;
    };

    return (
        <>
            <Dialog fullScreen={true} open={openDialog}>
                <AppBar>
                    <Toolbar>
                        <IconButton color="inherit" onClick={handleClose} aria-label="Close">
                            <CloseIcon/>
                        </IconButton>
                        <Typography variant="h6" color="inherit">
              Add Asset to {props.targetComponentName}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogTitle id="simple-dialog-title">&nbsp;</DialogTitle>
                <DialogContent>
                    <AssetsList
                        {...props}
                        addAssetToComponent={addAssetToComponent}
                        hasEdit={false}
                    />
                </DialogContent>
            </Dialog>
            {thumb}
            <Tooltip title={thumbnailTitle}>
                <Fab
                    onClick={handleClickOpen}
                    color="secondary"
                >
                    <AddIcon/>
                </Fab>
            </Tooltip>
        </>
    );
}));



import {
    Button,
    Card,
    CardContent,
    CardHeader,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    FormControlLabel,
    Switch,
    Typography,
    Grid
} from '@material-ui/core';
import GraphicEQIcon from '@material-ui/icons/Filter';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useEffect, useState } from 'react';
import {
    Brightness,
    Contrast,
    GrayScale,
    InterfaceFilters,
    Noise,
    Quality,
    RoundCorner,
    Saturation,
    Focus
} from '../../types';
import Slider from '@material-ui/core/Slider';
import { translate } from 'react-admin';

export const AssetThumborFilters = translate(
    (props: {
    classes: any;
    filters: InterfaceFilters;
    setFiltersCallback: CallableFunction;
    useResetFilters: CallableFunction;
    setCreateTransformation: CallableFunction;
    translate: CallableFunction;
    fileType: string
  }) => {
        const { translate } = props;

        const [disableSave, setDisableSave] = useState(true);

        useEffect(() => {
            filtersChanged(props.filters) ? setDisableSave(false) : setDisableSave(true);
        }, [props.filters]);

        const filtersChanged = (filters: InterfaceFilters) => {
            if (filters.Brightness.initialValue !== filters.Brightness.value ||
        filters.Contrast.initialValue !== filters.Contrast.value ||
        filters.Saturation.initialValue !== filters.Saturation.value ||
        filters.Quality.initialValue !== filters.Quality.value ||
        filters.Noise.initialValue !== filters.Noise.value ||
        filters.Round_Corner.initialValue !== filters.Round_Corner.radius ||
        filters.ShowGrid ||
        !filters.Smart ||
        filters.Grayscale.value ||
        filters.RedEye
            ) {
                return true;
            }
            return false;
        };

        return (
            <Card className={props.classes.card}>
                <CardHeader avatar={<GraphicEQIcon />} title={props.translate('resources.asset.filters.filters')} />
                <CardContent>
                    <div className={props.classes.filter}>
                        <Typography id="label">
                            {props.translate('resources.asset.filters.brightness')} {props.filters.Brightness.value}{' '}
                        </Typography>
                        <Slider
                            value={props.filters.Brightness.value}
                            onChange={(e, value) => {
                                // @ts-ignore
                                props.setFiltersCallback({ Brightness: new Brightness(value) });
                            }}
                            step={2}
                            max={100}
                            min={-100}
                            aria-labelledby="Brightness"
                            disabled={props.fileType === 'image/gif'}
                        />
                    </div>

                    <div className={props.classes.filter}>
                        <Typography id="label">
                            {props.translate('resources.asset.filters.contrast')} % {props.filters.Contrast.value}{' '}
                        </Typography>
                        <Slider
                            value={props.filters.Contrast.value}
                            onChange={(e, value) => {
                                // @ts-ignore
                                props.setFiltersCallback({ Contrast: new Contrast(value) });
                            }}
                            step={2}
                            max={100}
                            min={-100}
                            aria-labelledby="Contrast"
                            disabled={props.fileType === 'image/gif'}
                        />
                    </div>

                    <div className={props.classes.filter}>
                        <Typography id="label">
                            {props.translate('resources.asset.filters.saturation')} {props.filters.Saturation.value.toPrecision(2)}{' '}
                        </Typography>
                        <Slider
                            value={props.filters.Saturation.value}
                            onChange={(e, value) => {
                                // @ts-ignore
                                props.setFiltersCallback({ Saturation: new Saturation(value) });
                            }}
                            step={0.1}
                            max={2}
                            min={0}
                            aria-labelledby="label"
                            disabled={props.fileType === 'image/gif'}
                        />
                    </div>
                </CardContent>
                <CardHeader
                    avatar={<GraphicEQIcon />}
                    title={props.translate('resources.asset.filters.focus')}
                />
                <CardContent>
                    <FormControlLabel
                        control={
                            <Switch
                                value="showgrid"
                                checked={props.filters.ShowGrid}
                                onChange={e => {
                                    props.setFiltersCallback({
                                        Focus: new Focus({active: true, leftPercentage: 50, topPercentage: 50}),
                                        ShowGrid: e.target.checked,
                                        Smart: false
                                    });
                                }}
                            />
                        }
                        label={props.translate('resources.asset.filters.grid')}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                value="smart"
                                checked={props.filters.Smart}
                                onChange={e => {
                                    props.setFiltersCallback({
                                        Smart: e.target.checked,
                                        ShowGrid: false,
                                        Focus: new Focus({active: false, leftPercentage: 50, topPercentage: 50})
                                    });

                                }}
                                disabled={props.fileType === 'image/gif'}
                            />
                        }
                        label="Smart"
                    />
                </CardContent>
                <ExpansionPanel disabled={props.fileType === 'image/gif'}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>
                            {props.translate('resources.asset.filters.additional')}{' '}
                        </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div className={props.classes.fullFilter}>
                            <Typography id="label">
                                {props.translate('resources.asset.filters.quality')} % {props.filters.Quality.value}{' '}
                            </Typography>
                            <Slider
                                value={props.filters.Quality.value}
                                onChange={(e, value) => {
                                    // @ts-ignore
                                    props.setFiltersCallback({ Quality: new Quality(value) });
                                }}
                                step={5}
                                aria-labelledby="label"
                            />
                        </div>
                    </ExpansionPanelDetails>
                    <ExpansionPanelDetails>
                        <div className={props.classes.fullFilter}>
                            <Typography id="label">
                                {props.translate('resources.asset.filters.noise')} % {props.filters.Noise.value}{' '}
                            </Typography>
                            <Slider
                                value={props.filters.Noise.value}
                                onChange={(e, value) => {
                                    // @ts-ignore
                                    props.setFiltersCallback({ Noise: new Noise(value) });
                                }}
                                step={5}
                                max={100}
                                min={0}
                                aria-labelledby="label"
                            />
                        </div>
                    </ExpansionPanelDetails>
                    <ExpansionPanelDetails>
                        <div className={props.classes.fullFilter}>
                            <Typography id="label">
                                {props.translate('resources.asset.filters.roundCorner')} {props.filters.Round_Corner.radius}
                            </Typography>
                            <Slider
                                value={Number(props.filters.Round_Corner.radius)}
                                onChange={(e, value) => {
                                    // @ts-ignore
                                    props.setFiltersCallback({Round_Corner: new RoundCorner(value)
                                    });
                                }}
                                step={10}
                                max={100}
                                min={0}
                                aria-labelledby="label"
                            />
                        </div>
                    </ExpansionPanelDetails>
                    <ExpansionPanelDetails>
                        <FormControlLabel
                            control={
                                <Switch
                                    value="grayscale"
                                    checked={props.filters.Grayscale.value}
                                    onChange={e => {
                                        props.setFiltersCallback({
                                            Grayscale: new GrayScale(e.target.checked)
                                        });
                                    }}
                                />
                            }
                            label={props.translate('resources.asset.filters.grayscale')}
                        />
                    </ExpansionPanelDetails>
                    <ExpansionPanelDetails>
                        <FormControlLabel
                            control={
                                <Switch
                                    value="red-eye"
                                    checked={props.filters.RedEye}
                                    onChange={e => {
                                        props.setFiltersCallback({ RedEye: e.target.checked });
                                    }}
                                />
                            }
                            label={props.translate('resources.asset.filters.redeye')}
                        />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <Grid container style={{ padding: 8,  backgroundColor: '#f5f5f5' }}>
                    <Grid item xs={6}>
                        <Button
                            size="small"
                            variant="contained"
                            color="default"
                            onClick={() => {
                                props.useResetFilters();
                            }}
                        >
                            {translate('resources.asset.filters.reset')}
                        </Button>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'right' }}>
                        <Button
                            disabled={disableSave}
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                props.setCreateTransformation(true);
                            }}
                        >
                            {translate('resources.asset.filters.save')}
                        </Button>
                    </Grid>
                </Grid>
            </Card>
        );
    }
);

import React, { useEffect, useState } from 'react';
import {
    CREATE,
    GET_ONE,
    translate,
    useNotify,
    useRefresh,
    withDataProvider,
} from 'react-admin';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import CloudUpload from '@material-ui/icons/CloudUpload';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import AwsS3Multipart from '@uppy/aws-s3-multipart';
import Uppy from '@uppy/core';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import DashboardModal from '@uppy/react/lib/DashboardModal';
import { API_URL } from '../../utils/config';

interface Props {
  dataProvider;
  record?;
  progress;
  translate;
}

const VideoUpload: React.FC<Props> = (props: Props) => {
    const { dataProvider, translate, record } = props;
    const { id } = record;

    const [isTranscoding, setIsTranscoding] = useState(false);
    const[progress, setProgress] = useState(0);
    const[openUpload, setOpenUpload] = useState(false);

    const notify = useNotify();
    const refresh = useRefresh();

    const resetState = () => {
        setIsTranscoding(false);
        setProgress(0);
        setOpenUpload(false);
    };

    //reset uploading status on new id
    useEffect(() => {
        resetState();
    // eslint-disable-next-line
  }, [id])

    const uppy = Uppy({
        debug: false,
        restrictions: {
            maxNumberOfFiles: 1,
            allowedFileTypes: ['video/*', '.mp4', '.mxf']
        },
        autoProceed: true,
        allowMultipleUploads: false,
        onBeforeFileAdded: (currentFile, files) => {
            const modifiedFile = {
                ...currentFile,
                name: id + '.' + currentFile.name.split('.').pop()
            };
            return modifiedFile;
        }
    });
    uppy.use(AwsS3Multipart, {
        limit: 0,
        companionUrl: API_URL() + '/video/',
        companionHeaders: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
    });
    uppy.on('upload-success', (file) => {
        setIsTranscoding(true);
        setProgress(0);
        setOpenUpload(false);

        dataProvider(CREATE, 'video_transcoding_status', { format: file.extension, id })
            .then(() => {
                const timer = setInterval(() => {
                    dataProvider(GET_ONE, 'video_transcoding_status', { id })
                        .then((response) => {
                            if (response && response.error) {
                                setIsTranscoding(false);
                                setProgress(0);
                                return;
                            }
                            setIsTranscoding(true);
                            setProgress((response && response.progress) || progress);
                            if (response && response.progress === 100) {
                                clearInterval(timer);
                                resetState();
                                refresh();
                                notify('resources.video.notification.uploaded');
                            }
                        });
                }, 10000);
            });
    });

    const checkTranscoding = () => {
        dataProvider(GET_ONE, 'video_transcoding_status', { id })
            .then((response) => {
                if (response && response.error) {
                    setIsTranscoding(false);
                    setProgress(0);
                    return;
                }
                setIsTranscoding(true);
                setProgress((response && response.progress) || progress);
            });
    };

    const TranscodingState = (props) =>  {
        let progressState;
        if (!props.isTranscoding) {
            progressState = <p>Last Transcoding unknown</p>;
        } else {
            if (Number(props.progress) === 100) {
                progressState = <CircularProgress  variant="determinate" value={progress} />;
            } else {
                progressState = <CircularProgress  value={progress} />;
            }
        }
        return (
            <Tooltip title={translate('Transcoding:') + progress + '%'}>{progressState}</Tooltip>
        );
    };

    checkTranscoding();

    return (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={4} spacing={0}>
                    <Tooltip title={translate('Upload from disk...')}>
                        <Fab
                            onClick={() => setOpenUpload(true)}
                            color="secondary"
                        >
                            <CloudUpload />
                        </Fab>
                    </Tooltip>
                </Grid>
                <Grid item xs={4} spacing={0}>
                    <TranscodingState progress={progress} isTranscoding={isTranscoding}/>
                </Grid>
            </Grid>
            <hr />
            <DashboardModal
                note="Video only"
                open={openUpload}
                onRequestClose={() => setOpenUpload(false)}
                uppy={uppy}
                disablePageScrollWhenModalOpen={false}
            />
        </div>
    );
};

export default translate(withDataProvider(VideoUpload));

import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { CircularProgress } from '@material-ui/core';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { Focus, InterfaceFilters } from '../../types';
import { DELAY, DOWNLOAD_URL} from '../../utils/config';
import { InterfaceGrid, settingPictureBorders } from '../../utils/imagixGrids';
import { InitialFilters } from '../../utils/InitialFilters';

const styles = createStyles({
    root: {
        position: 'relative'
    },
    paper: {
        padding: 20,
        backgroundColor: 'black',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },

    layerGrid: {
        position: 'absolute' as 'absolute',
        'box-sizing': 'border-box',
        '-moz-box-sizing': 'border-box',
        '-webkit-box-sizing': 'border-box',
        border: '2px solid'
    },
    layerFocal: {
        position: 'absolute' as 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
        'box-sizing': 'border-box',
        '-moz-box-sizing': 'border-box',
        '-webkit-box-sizing': 'border-box',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    layerFocalPoint: {
        position: 'absolute' as 'absolute',
        background: 'rgba(255, 0, 0, 0.5)',
        width: '26px',
        height: '26px',
        borderRadius: '26px',
        marginTop: -13,
        marginLeft: -13
    },
    assetPreview: {
        maxWidth: '100%'
    }
});

interface Props extends WithStyles<typeof styles> {
  id: string;
  filters?: InterfaceFilters;
  setFiltersCallback?: CallableFunction;
  children?: any;
}

const ImagePreviewFC: React.FC<Props> = ({
    id,
    classes,
    filters = InitialFilters,
    setFiltersCallback = () => {},
    children
}: Props) => {
    const [imageSrc, setImageSrc] = useState<string | undefined>();

    const getFocalPoint = (
        clickedPosition: number[]
    ): { top: number; left: number } => {
        const shift = {
            left: -13,
            top: -13
        };
        let left = clickedPosition[0] + shift.left;
        let top = clickedPosition[1] + shift.top;
        return { top: top, left: left };
    };

    const [loading, setLoading] = useState(true);

    const [imageDimensions, setImageDimensions] = useState({
        width: 0,
        height: 0
    });
    const initialGrids: InterfaceGrid[] = settingPictureBorders(
        imageDimensions.width,
        imageDimensions.height,
        getFocalPoint([imageDimensions.width / 2, imageDimensions.height / 2])
    );
    const [grids, setGrids] = useState(initialGrids);

    useEffect(() => {
        let timeout = setTimeout(() => {
            const smart = filters['Smart'] ? '/smart' : '';
            const filterOptions: string[] = [];

            for (let filter in filters) {
                if (
                    filter !== 'Smart' &&
          filters[filter] &&
          filters[filter].toString() !== ''
                ) {
                    let value = filters[filter];
                    if (typeof value == 'object') {
                        filterOptions.push(value);
                    } else {
                        if (filter !== 'ShowGrid') {
                            filterOptions.push(`${filter.toLowerCase()}(${value})`);
                        }
                    }
                }
            }
            const filtersComputed = filterOptions.length > 0 ? '/filters:' : '';
            setImageSrc(
                `${DOWNLOAD_URL()}/api/v1/asset/${id}/preview${smart}${filtersComputed}${filterOptions.join(
                    ':'
                )}`
            );
            setLoading(false);
        }, DELAY);

        return () => {
            clearTimeout(timeout);
        };
    }, [id, filters]);

    useEffect(() => {
        settingGrids(
            settingPictureBorders(
                imageDimensions.width,
                imageDimensions.height,
                getFocalPoint([
                    (imageDimensions.width * filters.Focus.value.leftPercentage) / 100,
                    (imageDimensions.height * filters.Focus.value.topPercentage) / 100
                ])
            )
        );
    }, [filters.Focus, filters.ShowGrid, imageDimensions]);

    const getClickedPosition = (
        target: HTMLElement,
        mouseClientX: number,
        mouseClientY: number
    ): number[] => {
        let fromLeft = Math.floor(target.getBoundingClientRect().left);
        let fromTop = Math.floor(target.getBoundingClientRect().top || 0);

        return [mouseClientX - fromLeft + 13, mouseClientY - fromTop + 13];
    };

    const setFocalPointBox = (
        target: HTMLElement,
        mouseClientX: number,
        mouseClientY: number
    ) => {
        let focalPointXY = getFocalPoint(
            getClickedPosition(target, mouseClientX, mouseClientY)
        );
        setFiltersCallback({
            Focus: new Focus({
                active: true,
                leftPercentage: Math.round(
                    (focalPointXY.left / imageDimensions.width) * 100
                ),
                topPercentage: Math.round(
                    (focalPointXY.top / imageDimensions.height) * 100
                )
            })
        });
    };

    const settingGrids = (grids: InterfaceGrid[]) => {
        setGrids(grids);
    };

    const handleImageLoad = e => {
        setImageDimensions({
            width: e.target.offsetWidth,
            height: e.target.offsetHeight
        });
    };

    return (
        <Paper className={classes.paper}>
            {loading ? (
                <CircularProgress color="secondary" thickness={10} />
            ) : (
                <div className={classes.root}>
                    {children &&
            children.map(element => {
                return React.cloneElement(element, {
                    style: {
                        border: '1px solid red',
                        backgroundColor: 'transparent',
                        position: 'absolute',
                        cursor: 'pointer',
                        left: element.props.style.left * imageDimensions.width,
                        top: element.props.style.top * imageDimensions.height,
                        width: element.props.style.width * imageDimensions.width,
                        height: element.props.style.height * imageDimensions.height
                    }
                });
            })}
                    <img
                        onLoad={handleImageLoad}
                        id="preview"
                        alt="asset-preview"
                        className={classes.assetPreview}
                        src={imageSrc}
                    />
                    {filters['ShowGrid'] && (
                        <>
                            {grids.map((grid, index) => {
                                return (
                                    <div key={index} className={classes.layerGrid} style={grid}>
                                        {' '}
                                    </div>
                                );
                            })}
                            <div
                                className={classes.layerFocal}
                                onClick={e => {
                                    setFocalPointBox(e.currentTarget, e.clientX, e.clientY);
                                    settingGrids(
                                        settingPictureBorders(
                                            imageDimensions.width,
                                            imageDimensions.height,
                                            getFocalPoint(
                                                getClickedPosition(
                                                    e.currentTarget,
                                                    e.clientX,
                                                    e.clientY
                                                )
                                            )
                                        )
                                    );
                                }}
                            >
                &nbsp;
                            </div>
                            <div
                                className={classes.layerFocalPoint}
                                style={{
                                    left:
                    (imageDimensions.width *
                      filters.Focus.value.leftPercentage) /
                    100,
                                    top:
                    (imageDimensions.height *
                      filters.Focus.value.topPercentage) /
                    100
                                }}
                            >
                &nbsp;
                            </div>
                        </>
                    )}
                </div>
            )}
        </Paper>
    );
};

export const ImagePreview = withStyles(styles)(ImagePreviewFC);

import React from 'react';
import {
    withDataProvider,
} from 'react-admin';
import S1TVThumbnail from './S1TVThumbnail';
import {AssetTransformation} from '../assetTransformation/assetTransformation';

export default withDataProvider(props => {
    const {
        type,
        record,
        targetComponentName
    } = props;

    let item: AssetTransformation = {
        id: '',
        platform: '',
        type: '',
        transformation_id: '',
        transformationId: ''
    };

    const assetTransformations = targetComponentName === 'Eventstreams' ? props.assetTransformations : record.assetTransformations;

    if (assetTransformations && assetTransformations.length) {
        if (assetTransformations.find(at => at.type === type)) {
            item = assetTransformations.find(at => at.type === type);
        }
    }

    return (
        <>
            <S1TVThumbnail
                transformationID={(props.targetComponentName === 'Video' || props.targetComponentName === 'Playlist') ? item.transformation_id : item.transformationId}
                {...props}
            />
        </>
    );
}
);

import { createStyles, makeStyles } from '@material-ui/core/styles';
import {TextInput, translate} from 'react-admin';
import React, { useState } from 'react';
import { IconButton} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import AddMatchIdDialog from './AddMatchIdDialog';

const useStyles = makeStyles(() =>
    createStyles({
        button: {
            position: 'absolute',
            right: 0,
        },
        root: {
            position: 'relative',
        },
    }),
);

interface Props {
  fullWidth: boolean
  source
  translate
  onChange: CallableFunction
}

const TextInputMatchId = translate((props: Props) => {
    const [openDialog, setOpenDialog] = useState(false);
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <TextInput
                fullWidth={props.fullWidth}
                source={props.source}
                label="Match ID"
            />
            <IconButton onClick={() => setOpenDialog(true)} className={classes.button}>
                <SearchIcon />
            </IconButton>

            <AddMatchIdDialog open={openDialog} setOpenDialog={setOpenDialog} {...props} />
        </div>
    );
});

export default TextInputMatchId;

import React from 'react';
import { Labeled } from 'react-admin';
import AsyncSelect from 'react-select/async';

const SelectChannel = (props) => {
    const loadOptions = (input, f) =>
        props.dataProvider('SEARCH', 'epg-channel', input)
            .then((response) => response.data.map((x) => Object.assign({label: x.name}, x)))
            .then((options) => props.filter ? options.filter(props.filter) : options)
            .then(f);

    return <Labeled label="Channel *" fullWidth>
        <AsyncSelect
            menuPortalTarget={document.body}
            cacheOptions
            defaultValue={props.defaultValue}
            loadOptions={loadOptions}
            onChange={props.onChange}
        />
    </Labeled>;
};
export default SelectChannel;
